import React, { useState, useEffect } from "react";

import TransactionContext from "./context";
import { withFirebase } from "../Firebase";

const getTransactions = Component => {

    const GetTransactions = (props) => {

        const [existingTransactions, setExistingTransactions] = useState([]);

        //useeffect sync with user applications and Transactions page changes
        useEffect(() => {
            if (props.authUser) {
               let arrayOfTransactions = []
                //  Bring all Transactions for current order
                props.firebase.db.collection("transactions").where("transactionOrderId", "==", props.orderId)
                    .onSnapshot(function (querySnapshot) {
                        querySnapshot.forEach(function (transaction_doc) {
                            arrayOfTransactions.push({transactionData: transaction_doc.data(), orderId: props.orderId, transactionId: transaction_doc.id, amount: transaction_doc.data().transactionDetails.payload.payment.entity.amount})
                        });
                        setExistingTransactions(arrayOfTransactions)
                    })
            }
        }, []);
        return (
            <TransactionContext.Provider value={existingTransactions}>
                {
                    (existingTransactions && existingTransactions.length > 0) &&
                    <Component {...props} existingTransactions={existingTransactions} />
                }
            </TransactionContext.Provider>
        );
    }
    return withFirebase(GetTransactions)
}

export default getTransactions;