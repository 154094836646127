import React, { useState, useEffect } from "react";
import { Item, ItemSeperator, ShrinkedItem } from "./SharedComponents";
import * as COLORS from "../../constants/colors";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Zoom from '@material-ui/core/Zoom';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        boxShadow: 'none'
    },
    applicationCard: {
        padding: 15,
        margin: "auto",
        maxWidth: "350px",
        minHeight: "350px",
        cursor: "pointer",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.2), 0px 1px 3px 0px rgba(0,0,0,0.2)",
    },
    card_one: {
        "&:hover": {
            backgroundColor: COLORS.FRESH_APPLICATION,
            color: "white"
        }
    },
    card_two: {
        "&:hover": {
            backgroundColor: COLORS.CORRECTION_APPLICATION,
            color: COLORS.FRESH_APPLICATION
        }
    },
    card_three: {
        "&:hover": {
            backgroundColor: COLORS.CORRECTION_APPLICATION,
            color: COLORS.FRESH_APPLICATION
        }
    },
    img: {
        margin: '5%',
        display: 'block'
    },
    image: {
        width: 100,
        height: 100,
        margin: 'auto',
    },
    formTypes: {
        display: "flex",
        justifyContent: 'center'
    },
    eachForm: {
        marginTop: theme.spacing(3)
    },
    formTitle: {
        display: "flex",
        justifyContent: 'center'
    },
    formDetails: {
        margin: "5%"
    },
    formWarning: {
        color: "red",
        margin: "5%"
    }
}));

const ApplicationType = props => {
    const classes = useStyles();

    const [isFullView, setIsFullView] = useState(true);
    const [applicationType, setApplicationType] = useState();
    const [zoomItem, setZoomItem] = useState(true);

    const onSelectShrinkAndSetValue = formTypeValue => {
        props.onSelect(formTypeValue);
        if (zoomItem) {
            setZoomItem(false)
            setTimeout(() => {
                setIsFullView(false);
            }, 1000)
            return
        }
        else
            setIsFullView(false);
    };

    const data = [
        {
            name: "panForm",
            caption: "PAN Form",
            details:
                "Select this type if you are applying PAN card for the first time.",
            warning:
                "The government will impose a Penalty for possession of More than ONE PAN",
            bgColor: COLORS.FRESH_APPLICATION,
            imgsrc: "assets/img/applywizard/apply-fresh.svg",
        },
        {
            name: "tanForm",
            caption: "TAN Form",
            details:
                "Select this type if you are applying TAN card",
            bgColor: COLORS.CORRECTION_APPLICATION,
            imgsrc: "assets/img/applywizard/correction/minortoMajor.svg",
        }
    ];

    const chooseView = () => {
        console.log(props.activeColor)
        if (isFullView)
            return data.map((i, index) => {
                var cardsArray = ["card_one", "card_two", "card_three"]

                return (
                    <Grid item xs={12} sm={4} className={classes.eachForm} >
                        <Zoom in={zoomItem} style={{ transitionDelay: 500 }} timeout={1000} mountOnEnter unmountOnExit>
                            <Grid containter className={classes.applicationCard + " " + classes[cardsArray[index]]} onClick={() => onSelectShrinkAndSetValue(i.name)}>
                                <Grid item className={classes.formTitle}>
                                    <Typography variant="h5" component="h5">
                                        {i.caption}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.image}>
                                    <img className={classes.img} alt="complex" src={i.imgsrc} />
                                </Grid>
                                <Typography variant="subtitle1" className={classes.formDetails} component="h5">
                                    {i.details}
                                </Typography>
                                <Typography variant="subtitle1" className={classes.formWarning} component="h5">
                                    {i.warning}
                                </Typography>
                            </Grid>
                        </Zoom>
                    </Grid>
                );
            });
        else
            return data.map(i => {
                return (
                    <ShrinkedItem
                        name={i.name}
                        active={i.name == props.formType}
                        onSelect={onSelectShrinkAndSetValue}
                        caption={i.caption}
                        details={i.details}
                        warning={i.warning}
                        imgsrc={i.imgsrc}
                        colSize={4}
                        bgColor={i.bgColor}
                        activeColor={props.activeColor}
                        disableAnimation={true}
                    />
                );
            });
    };

    const Questionare = () => (
        <Grid container >
            <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                    First let's understand your Form type
                </Typography><hr />
            </Grid>
            <Grid item xs={12} className={classes.allCss}>
                <Typography variant="h6" component="h6">
                    What do you want to apply for ?
                </Typography>
            </Grid>
        </Grid>
    );

    const Heading = () =>
        <Typography variant="h6" component="h6">
            Choose Form Type
        </Typography>

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {isFullView ? <Questionare /> : <Heading />}
                <Grid container className={classes.formTypes}>
                    {chooseView()}
                </Grid>
            </Paper>
            {!isFullView && <hr />}
        </div>
    );
};

export default ApplicationType;