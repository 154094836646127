import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp/material-signup";
import UserEntryPage from "../UserEntry";
import SignInPage from "../SignIn/material-signin";
import PasswordForgetPage from "../PasswordForget";
import PasswordChangePage from "../PasswordChange";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import FormPage from "../Products";
import Application from "../Application";
import ApplyForm from "../ApplyForm";
import AplicationEditPage from "../FillingForm-Material";
import AppliedForms from "../AppliedForms";
import OrderPage from "../OrderPage";
import TransactionStatus from "../TransactionStatus";
import CurrentOrders from "../CurrentOrders";
import CurrentTransactions from "../CurrentTransactions";
import ProfilePage from "../Profile";
import FaqList from "../FaqList";
import FeesCalculator from "../FeesCalculator";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import { AuthUserContext, withAuthorization } from "../Session";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import ApplicationSteps from '../BreadCrumbs';
import BreadCrumbs from '../BreadCrumbs/prevPage';

const AppLayout = props => (
  <AuthUserContext.Consumer>
    {authUser => <AppBase authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const App = (props) => {
  return (
    <Router>
      <Navigation {...props} />
      <BreadCrumbs />
      <ApplicationSteps />
      <div>
        <Route exact path={ROUTES.LANDING} component={HomePage} />
        <Route path={ROUTES.USER_ENTRY} component={UserEntryPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.PASSWORD_CHANGE} component={PasswordChangePage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.PRODUCTS} component={FormPage} />
        <Route path={ROUTES.APPLY_FORM} component={ApplyForm} />
        <Route path={ROUTES.FORM_FILLING} component={AplicationEditPage} />
        <Route path={ROUTES.APPLIED_FORMS} component={AppliedForms} />
        <Route path={ROUTES.ORDER_PAGE} component={OrderPage} />
        <Route path={ROUTES.TRANSACTION_STATUS} component={TransactionStatus} />
        <Route path={ROUTES.CURRENT_ORDERS} component={CurrentOrders} />
        <Route path={ROUTES.CURRENT_TRANSACTIONS} component={CurrentTransactions} />
        <Route path={ROUTES.PROFILE_PAGE} component={ProfilePage} />
        <Route path={ROUTES.FAQ_SECTION} component={FaqList} />
        <Route path={ROUTES.FEES_CALCULATOR} component={FeesCalculator} />
      </div>
    </Router>
  )
}

const AppBase = compose(withFirebase)(App)
const AppPage = compose(withAuthentication)(AppLayout);

export default AppPage;

export { AppPage, AppBase };