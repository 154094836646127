import 'date-fns';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const DateComponent = props => {

    const [selectedDate, setSelectedDate] = useState();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    const handleDateChange = (date) => {
        setSelectedDate(date);
        var date = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        props.formik.touched[props.id] = true
        props.onChange({
            target: {
                name: props.id,
                value: [day, mnth, date.getFullYear()].join("/")
            }
        })
    };

    const extractDate = (dateString) => {

        // assuming date string format is dd/MM/yyyy

        const dateArr = dateString.split('/');

        return { day: dateArr[0], month: dateArr[1], year: dateArr[2] }

    }

    useEffect(() => {
        if (props.value) {
            const { year, month, day } = extractDate(props.value);
            console.log(year, month, day);
            setSelectedDate(new Date(year, month - 1, day));
            return;
        }

        var minorArray = ["freshMinor", "correctionMinor", "correctionReprintMinor"]

        if (minorArray.includes(props.formik.values.formType)) {
            var currDate = new Date();
            currDate.setFullYear(currDate.getFullYear() - 18)
            setMinDate(currDate)
            setSelectedDate(currDate);
        }
        else if (props.formik.values.applicationCategory === "Individual") {
            var currDate = new Date();
            currDate.setFullYear(currDate.getFullYear() - 18)
            setMaxDate(currDate)
            setMinDate(new Date(1900, 0, 1))
            setSelectedDate(new Date(2000, 0, 1));
        }
        else{
            //Non - Individual forms
            setSelectedDate(new Date(2000, 0, 1));
        }

    }, [])

    return (
        <div className="textbox-div">

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                    <KeyboardDatePicker
                        autoOk={true}
                        maxDate={maxDate || new Date()}
                        minDate={minDate || new Date(1800, 0, 1)}
                        format="dd/MM/yyyy"
                        defaultValue="01/01/2000"
                        id={props.id}
                        name={props.id}
                        label={props.details.label}
                        value={selectedDate || minDate || new Date(1800, 0, 1)}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        style={{ width: "100%", marginTop: '6px' }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    )

}

export default DateComponent;