import React from "react";
import * as ROUTES from "../../constants/routes";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const TransactionSuccess = (props) => {
    return (
        <div>
            TRANSACTION SUCCESS. YOU CAN DOWNLOAD YOUR APPLICATION <Link
                href={`https://us-central1-applypan-landing.cloudfunctions.net/downloadFile?fileName=${props.applicationMetaData.applicationPDFFileURL}`}
                target={`https://us-central1-applypan-landing.cloudfunctions.net/downloadFile?fileName=${props.applicationMetaData.applicationPDFFileURL}`}
            >
                HERE
            </Link>. AND MAKE SURE THAT
            YOUR SIGNATURE IS THERE WHERE IT NECESSARY
        </div>
    )
};

export default TransactionSuccess;