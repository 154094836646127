import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import * as COLORS from '../constants/colors';

const useStyles = makeStyles((theme) => ({
    radioButton: {
        width: "100%"
    },
    radioLabel: {
        fontSize: 11,
    },
    radioTitle: {
        fontSize: 14
    },
    inforIconDiv: {
        position: "absolute",
        right: -5,
        top: 25
    },
    inforIconLabelDiv: {
        position: "absolute",
        right: 10,
        top: -3
    },
    inforIcon: {
        fontSize: 18,
        cursor: "pointer"
    }
}));
const CustomToolTip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12,
        backgroundColor: COLORS.FRESH_APPLICATION,
        color: "white"
    },
}))(Tooltip);

const getRepresentativeOptions = (whichRepresentative) => {
    if (whichRepresentative) {
        if (whichRepresentative.toLowerCase() === 'father') {
            return [{ value: 'shri', label: 'Shri', checked: true }]
        }
        else if (whichRepresentative.toLowerCase() === 'mother') {
            return [{ value: 'srimathi', label: 'Smt.', checked: true }]
        }
        else {
            return [{ value: 'shri', label: 'Shri' }, { value: 'srimathi', label: 'Smt.' }, { value: 'kumari', label: 'Kumari' }]
        }
    }
    return [{ value: 'shri', label: 'Shri' }, { value: 'srimathi', label: 'Smt.' }, { value: 'kumari', label: 'Kumari' }]
}

const Radiobutton = props => {
    const classes = useStyles();

    const getSalutationOptions = (marital, gender) => {

        var minorArray = ["freshMinor", "correctionMinor", "correctionReprintMinor"]
    
        if (minorArray.includes(props.formik.values.formType)) {
            if (gender.toLowerCase() === 'male') {
                return [{ value: 'shri', label: 'Shri', checked: true }]
            }
            else if (gender.toLowerCase() === 'female') {
                return [{ value: 'kumari', label: 'Kumari', checked: true }]
            }
            else {
                return [{ value: 'shri', label: 'Shri' }, { value: 'kumari', label: 'Kumari' }]
            }
        }
    
        if (gender != null && marital != null) {
            if (gender.toLowerCase() === 'male') {
                switch (marital) {
                    case "single":
                        return [{ value: 'shri', label: 'Shri', checked: true }]
                    default:
                        return [{ value: 'shri', label: 'Shri', checked: true }]
    
                }
            }
            else if (gender.toLowerCase() === 'female') {
    
                switch (marital) {
                    case "single":
                        return [{ value: 'kumari', label: 'Kumari', checked: true }]
                    case "married":
                        return [{ value: 'srimathi', label: 'Smt.', checked: true }]
                    default:
                        return [{ value: 'kumari', label: 'Kumari' }, { value: 'srimathi', label: 'Smt.' }]
                }
            }
            else {
                switch (marital) {
                    case "single":
                        return [{ value: 'kumari', label: 'Kumari' }, { value: 'shri', label: 'Shri' }]
                    case "married":
                        return [{ value: 'srimathi', label: 'Smt.' }, { value: 'shri', label: 'Shri' }]
                    default:
                        return [{ value: 'shri', label: 'Shri' }, { value: 'kumari', label: 'Kumari' }, { value: 'srimathi', label: 'Smt.' }]
                }
                return [{ value: 'shri', label: 'Shri' }, { value: 'kumari', label: 'Kumari' }, { value: 'srimathi', label: 'Smt.' }]
            }
        }
    }
    

    const printRadioBtns = () => {
        let arrayOfRadioBtns = []
        let formValues = props.formik.values

        if (props.id === 'titleOnName') {
            let options = getSalutationOptions(formValues.maritalStatus, formValues.gender);
            for (let fields in options) {
                const field = options[fields];
                if (field.checked) {
                    props.formik.values.titleOnName = field.value
                }
                arrayOfRadioBtns.push(
                    <FormControlLabel value={field.value} id={props.id} control={<Radio color="primary" checked={field.checked} />} label={field.label}
                        classes={{
                            label: classes.radioLabel
                        }}
                        disabled={props.details.disabled}
                    />
                )
            }
            return arrayOfRadioBtns
        }

        if (props.id === 'representativeTitleOnName') {
            let options = getRepresentativeOptions(formValues.whichRepresentative);
            for (let fields in options) {
                const field = options[fields];
                if (field.checked) {
                    props.formik.values.representativeTitleOnName = field.value
                }
                arrayOfRadioBtns.push(
                    <FormControlLabel value={field.value} id={props.id} control={<Radio color="primary" checked={field.checked} />} label={field.label}
                        classes={{
                            label: classes.radioLabel
                        }}
                        disabled={props.details.disabled}
                    />
                )
            }
            return arrayOfRadioBtns
        }

        //sort options by position
        props.details.options = Object.values(props.details.options).sort((a, b) => a.position - b.position);

        for (let fields in props.details.options) {
            const field = props.details.options[fields];
            arrayOfRadioBtns.push(
                <>
                    <FormControlLabel value={field.value} id={props.id} control={<Radio color="primary" />} label={field.label}
                        classes={{
                            label: classes.radioLabel
                        }}
                        disabled={props.details.disabled}
                    />
                    {
                        field.info &&
                        <div className={classes.inforIconDiv}>
                            <CustomToolTip title={field.info} placement="right">
                                <InfoIcon className={classes.inforIcon} />
                            </CustomToolTip>
                        </div>
                    }
                </>
            )
        }
        return arrayOfRadioBtns
    }

    const titleTooltip = () => {
        if(props.id === 'parentsName' && props.formik.values.formType == "freshMinor") return

        if (props.id === 'parentsName' && props.formik.values.gender === 'male') return
        
        if (props.id === 'parentsName' && props.formik.values.gender === 'transgender'){
            if(props.formik.values.maritalStatus == "single") return
            if(props.formik.values.maritalStatus == "married" && props.formik.values.titleOnName == "shri") return
        }

        if (props.details.info) {
            return (
                <div className={classes.inforIconLabelDiv}>
                    <CustomToolTip title={props.details.info} placement="right">
                        <InfoIcon className={classes.inforIcon} />
                    </CustomToolTip>
                </div>
            )
        }
    }
    return (
        <FormControl component="fieldset">
            <div>
                <FormLabel component="legend" className={classes.radioTitle}>{props.details.label}</FormLabel>
                {titleTooltip()}
                <RadioGroup row error={(props.formik.errors[props.id] && props.formik.touched[props.id])} aria-label={props.details.label} name={props.id} value={props.value || ""} onChange={(event) => { props.onChange(event); }} onBlur={(event) => props.onBlur(event)}>
                    {printRadioBtns()}
                </RadioGroup>
            </div>
        </FormControl>
    )
};

export default Radiobutton;