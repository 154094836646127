import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    mainStepper: {
        padding: "0px"
    }
}));

const StatusStepper = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        console.log(props.currentStep)
        switch (props.currentStep) {
            case 'New':
                setActiveStep(1)
                break;
            case 'Submitted':
                setActiveStep(2)
                break;
            case 'Order Created':
                setActiveStep(3)
                break;
            default:
                setActiveStep(0)
                break;
        }
        setSteps(props.steps)
        console.log(activeStep)

    }, [])

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel className={classes.mainStepper}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
}

export default StatusStepper;