import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../../Session";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import { useFormik } from 'formik';

import TextBox from '../../../ui-elements/textbox';
import Dropdown from '../../../ui-elements/dropdown';
import Radiobutton from '../../../ui-elements/radiobutton';
import DateComponent from '../../../ui-elements/datecomponent';
import Checkbox from '../../../ui-elements/checkbox';
import { StepTitle, SubHeading } from '../../../ui-elements/headers';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '../Stepper';
import Schema from '../../../lib/Validation/freshincomeschema';
import SwitchComponent from '../../../ui-elements/switch';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    kycForm: {
        paddingLeft: 20
    },
    separateSection: {
        marginTop: 1
    },
    longSection: {
        marginTop: 20
    },
    formikErrors: {
        color: '#B80000 ',
        border: '1px solid red',
        marginRight: 10,
        marginBottom: 5,
        opacity: 0.9
    },
    errorIcon: {
        color: '#A00000',
        opacity: 0.8
    },
    sectionHeading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        backgroundColor: "#d3d3d3",
        paddingLeft: theme.spacing(1),
        borderRadius: 5
    },
}));

const KycDetails = props => {

    const classes = useStyles();

    const formik = props.formik;

    const getElementToBeRendered = (fieldItemName, colSize) => {
        if (props.applicantKycDetails[fieldItemName]) {
            if (props.applicantKycDetails[fieldItemName].type == 'text' || props.applicantKycDetails[fieldItemName].type == 'number' || props.applicantKycDetails[fieldItemName].type == 'email' || props.applicantKycDetails[fieldItemName].type == 'date')
                return <Grid item xs={12} md={colSize || 4}> <TextBox details={props.applicantKycDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantKycDetails[fieldItemName].type == 'switch')
                return <Grid item xs={12} md={colSize || 4}> <SwitchComponent details={props.applicantKycDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantKycDetails[fieldItemName].type == 'radio')
                return <Grid item xs={12} md={colSize || 4}> <Radiobutton details={props.applicantKycDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantKycDetails[fieldItemName].type == 'dropdown')
                return <Grid item xs={12} md={colSize || 4}> <Dropdown details={props.applicantKycDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else
                return <Grid item xs={12} md={colSize || 4}> empty </Grid>
        }
    }

    const printCurrentFormikErrors = () => {
        let arrayOfFormikErrors = []
        let currentStep;
        for (var key in formik.errors) {
            if (formik.errors.hasOwnProperty(key)) {
                if (props.applicationFieldDetails.basicDetails.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(0)
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.addressDetails.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(1)
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.incomeProof.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(2)
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.kyc.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(3)
                            }}
                        />
                    )
                }
                else { }
            }
        }
        return arrayOfFormikErrors
    }


    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.kycForm}>
                <Grid container spacing={1} className={classes.longSection}>
                    {getElementToBeRendered("overseasCategory", 12)}
                </Grid>
                {
                    formik.values["overseasCategory"] == "Individual" && (
                        <>
                            <Grid container className={classes.separateSection}>
                                {getElementToBeRendered("kycMaritalStatus", 6)}
                            </Grid>
                            <Grid container className={classes.separateSection}>
                                {getElementToBeRendered("citizenshipStatus", 6)}
                                {
                                    formik.values["citizenshipStatus"] === 'foreigner' &&
                                    getElementToBeRendered("citizenshipCountry", 6)
                                }
                            </Grid>
                            <Grid container className={classes.separateSection}>
                                {getElementToBeRendered("occupationDetails", 12)}
                            </Grid>
                        </>
                    )
                }

                {
                    formik.values["overseasCategory"] == "Non Individual" && (
                        <>
                            <Grid container className={classes.separateSection}>
                                {getElementToBeRendered("typeOfIncome", 12)}
                            </Grid>

                            <Grid container className={classes.separateSection}>
                                {
                                    formik.values["typeOfIncome"] === "public" &&
                                    getElementToBeRendered("isStockExchange", 6)
                                }

                                {
                                    formik.values["isStockExchange"] === true &&
                                    getElementToBeRendered("nameStockExchange", 6)
                                }
                            </Grid>

                            <Grid container className={classes.separateSection}>
                                {getElementToBeRendered("isBeneficialOwnership", 12)}
                            </Grid>
                        </>
                    )
                }
                <Grid container className={classes.separateSection}>
                    {getElementToBeRendered("isForeignExchange", 12)}
                    {getElementToBeRendered("isGambling", 8)}
                    {getElementToBeRendered("isMoneyLending")}
                </Grid>

                <Grid container className={classes.separateSection}>
                    {getElementToBeRendered("isPoliticallyExposed", 6)}
                    {getElementToBeRendered("isPoliticallyRelated", 6)}
                </Grid>

                <Grid container className={classes.longSection}>
                    {getElementToBeRendered("taxpayerIdentification", 4)}
                    {getElementToBeRendered("kycGrossAnnual", 4)}
                    {getElementToBeRendered("kycNetworth", 4)}
                </Grid>

                {
                    (formik.errors && Object.keys(formik.errors).length > 0) && (
                        <>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6" className={classes.sectionHeading}>Please fill the below required fields</Typography>
                                </Grid>
                            </Grid>
                            {printCurrentFormikErrors()}
                        </>
                    )
                }

            </Grid>
        </React.Fragment>
    )
};


export default KycDetails;