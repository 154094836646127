import React from "react";
import { withRouter } from 'react-router-dom';
import TextBox from '../../../ui-elements/textbox';
import TextMask from '../../../ui-elements/textmask';
import Dropdown from '../../../ui-elements/dropdown';
import Radiobutton from '../../../ui-elements/radiobutton';
import DateComponent from '../../../ui-elements/datecomponent';
import NameComponent from '../../../ui-elements/namebox';
import SwitchComponent from '../../../ui-elements/switch';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    basicForm: {
        paddingLeft: 20
    },
    inputGrids: {
        padding: "2px"
    },
    separateSection: {
        marginTop: 1
    }
}));

const BasicDetails = props => {

    const formik = props.formik;

    const classes = useStyles();

    const orderedFieldsForApplicantName = ["lastName", "firstName", "middleName"]
    const orderedFieldsForFatherName = ["fathersLastName", "fathersFirstName", "fathersMiddleName"]
    const orderedFieldsForMotherName = ["mothersLastName", "mothersFirstName", "mothersMiddleName"]
    const orderedFieldsForOtherName = ["nickLastName", "nickFirstName", "nickMiddleName"]

    const getElementToBeRendered = (fieldItemName, colSize) => {
        if (props.applicantBasicDetails[fieldItemName]) {
            if (props.applicantBasicDetails[fieldItemName].type == 'text' || props.applicantBasicDetails[fieldItemName].type == 'email')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <TextBox details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'name')
                return <Grid item xs={12} md={colSize || 4} className={classes.inputGrids}> <NameComponent details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'number')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <TextMask details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'switch')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <SwitchComponent details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'radio')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <Radiobutton details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'dropdown')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <Dropdown details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'date')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <DateComponent details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else
                return <Grid item xs={12} className={classes.inputGrids} > empty </Grid>
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.basicForm}>

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("panNumber")}
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {
                        formik.values.formType === 'correctionReprintMinor' ? (
                            <>
                                {getElementToBeRendered("gender", 5)}
                                {formik.values["gender"] != null ? getElementToBeRendered("titleOnName", 4) : ""}
                            </>
                        ) :
                            (
                                <>
                                    {getElementToBeRendered("gender", 5)}
                                    {formik.values["gender"] != null ? getElementToBeRendered("maritalStatus", 3) : ""}
                                    {formik.values["maritalStatus"] != null ? getElementToBeRendered("titleOnName", 4) : ""}
                                </>
                            )
                    }
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {orderedFieldsForApplicantName.map(field => {
                        return getElementToBeRendered(field)
                    })}
                    {getElementToBeRendered("nonIndividualName", 12)}
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {
                        formik.values.applicationCategory === "Individual" ? (
                            <>
                                {getElementToBeRendered("nameOnPanCard")}
                                {getElementToBeRendered("dateOfBirth")}
                                {getElementToBeRendered('registerNumber')}
                            </>
                        ) : (
                            <>
                                {getElementToBeRendered("nameOnPanCard", 12)}
                                {getElementToBeRendered("dateOfBirth", 12)}
                                {getElementToBeRendered('registerNumber', 12)}
                            </>
                        )
                    }
                </Grid>

                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered("parentsName")}
                    {formik.values["parentsName"] === "mothersName" ? getElementToBeRendered("singleParent") : ""}
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {
                        (formik.values["singleParent"] === "yes" && formik.values["parentsName"] === "mothersName") && (
                            orderedFieldsForMotherName.map(field => {
                                return getElementToBeRendered(field, 4)
                            })
                        )
                    }
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {
                        formik.values["parentsName"] === "fathersName" && (
                            orderedFieldsForFatherName.map(field => {
                                return getElementToBeRendered(field, 4)
                            })
                        )
                    }
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {
                        formik.values["singleParent"] === "no" && formik.values["parentsName"] == "mothersName" && (
                            <>
                                {orderedFieldsForFatherName.map(field => {
                                    return getElementToBeRendered(field, 4)
                                })}
                                {orderedFieldsForMotherName.map(field => {
                                    return getElementToBeRendered(field, 4)
                                })}
                            </>
                        )
                    }
                </Grid>

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered('isNickName', 12)}
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {
                        formik.values["isNickName"] === true && orderedFieldsForOtherName.map(field => {
                            return getElementToBeRendered(field)
                        })
                    }
                </Grid>

                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered('aadhaarCard')}
                    {formik.values['aadhaarCard'] === "yes" && getElementToBeRendered('aadhaarNumber')}
                    {getElementToBeRendered('aadhaarName')}
                </Grid>

                {/* {Applicant Other PAN correction} */}
                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered("isMentionOtherPan", 12)}
                </Grid>
                {
                    formik.values["isMentionOtherPan"] === true && (
                        <Grid container spacing={4} className={classes.separateSection}>
                            {getElementToBeRendered("otherPan1")}
                            {formik.values.otherPan1 && getElementToBeRendered("otherPan2")}
                            {formik.values.otherPan2 && getElementToBeRendered("otherPan3")}
                            {formik.values.otherPan3 && getElementToBeRendered("otherPan4")}
                        </Grid>
                    )
                }
                {/* {Applicant Other PAN correction} */}

                {/* foreign forms */}
                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered('countryCitizenship')}
                    {getElementToBeRendered('isdCitizenship')}
                </Grid>
                {/* foreign forms */}

            </Grid>
        </React.Fragment>
    )
};


export default withRouter(BasicDetails);