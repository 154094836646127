import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SignUp from '../SignUp/material-signup';
import SignIn from '../SignIn/material-signin';

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        minHeight: 550
    },
    userButtonGrp: {
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        marginTop: theme.spacing(5),
        boxShadow: 'none'
    },
    signupBtn: {

    },
    signinBtn: {

    }
}))

const UserEntry = () => {

    const [showSignUp, setShowSignUp] = useState(true);

    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.mainContainer}>
            <Paper className={classes.mainPaper}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <ButtonGroup className={classes.userButtonGrp} variant="contained"  color="primary" aria-label="contained primary button group">
                            <Button className={classes.signupBtn} onClick={() => { setShowSignUp(true) }}>Create User/ Sign Up</Button>
                            <Button className={classes.signinBtn} onClick={() => { setShowSignUp(false) }}>Existing User/ Sign In</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        {showSignUp ? <SignUp /> : <SignIn />}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default UserEntry;