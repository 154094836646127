import React from "react";
import { withRouter } from 'react-router-dom';
import TextBox from '../../../ui-elements/textbox';
import TextMask from '../../../ui-elements/textmask';
import Dropdown from '../../../ui-elements/dropdown';
import Radiobutton from '../../../ui-elements/radiobutton';
import DateComponent from '../../../ui-elements/datecomponent';
import SwitchComponent from '../../../ui-elements/switch';
import NameComponent from '../../../ui-elements/namebox';
import CheckBoxCompon from '../../../ui-elements/checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    basicForm: {
        paddingLeft: 20
    },
    inputGrids: {
        padding: "2px"
    },
    separateSection: {
        marginTop: 1
    }
}));

const BasicDetails = props => {

    const formik = props.formik;

    const classes = useStyles();

    const orderedFieldsForApplicantNameChange = ["entireNameChange", "fathersName", "husbandName", "spellingChange", "surname"]
    const orderedFieldsForApplicantName = ["lastName", "firstName", "middleName"]
    const orderedFieldsForFatherName = ["fathersLastName", "fathersFirstName", "fathersMiddleName"]
    const orderedFieldsForMotherName = ["mothersLastName", "mothersFirstName", "mothersMiddleName"]
    const orderedFieldsForOtherName = ["nickLastName", "nickFirstName", "nickMiddleName"]

    const getElementToBeRendered = (fieldItemName, colSize) => {
        if (props.applicantBasicDetails[fieldItemName]) {
            if (props.applicantBasicDetails[fieldItemName].type == 'text' || props.applicantBasicDetails[fieldItemName].type == 'email')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <TextBox details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'name')
                return <Grid item xs={12} md={colSize || 4} className={classes.inputGrids}> <NameComponent details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'number')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <TextMask details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'switch')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <SwitchComponent details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'checkbox')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <CheckBoxCompon details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'radio')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <Radiobutton details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'dropdown')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <Dropdown details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantBasicDetails[fieldItemName].type == 'date')
                return <Grid item xs={12} className={classes.inputGrids} md={4}> <DateComponent details={props.applicantBasicDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else
                return <Grid item xs={12} className={classes.inputGrids} > empty </Grid>
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.basicForm}>

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("panNumber", 12)}
                </Grid>

                {/* {Applicant Gender correction} */}
                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered("isGenderCorrection", 12)}
                    {
                        formik.values["isGenderCorrection"] != null && (
                            <>
                                <Grid container spacing={2} className={classes.separateSection}>
                                    {
                                        formik.values.formType === 'correctionMinor' ? (
                                            <>
                                                {getElementToBeRendered("gender", 5)}
                                                {formik.values["gender"] != null ? getElementToBeRendered("titleOnName", 4) : ""}
                                            </>
                                        ) :
                                            (
                                                <>
                                                    {getElementToBeRendered("gender", 5)}
                                                    {formik.values["gender"] != null ? getElementToBeRendered("maritalStatus", 3) : ""}
                                                    {formik.values["maritalStatus"] != null ? getElementToBeRendered("titleOnName", 4) : ""}
                                                </>
                                            )
                                    }
                                </Grid>
                            </>
                        )
                    }
                </Grid>
                {/* {Applicant Gender correction} */}

                {/* {Applicant Name Correction} */}
                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("isApplicantNameCorrection", 12)}
                </Grid>

                {
                    // applicantNameChange exists if exists individual forms not exists correction Non Individual forms
                    props.applicantBasicDetails['applicantNameChange'] ?
                        (
                            <>
                                {
                                    formik.values["isApplicantNameCorrection"] == true && (
                                        <>
                                            <Grid container spacing={3} className={classes.separateSection}>
                                                {getElementToBeRendered("applicantNameChange")}
                                            </Grid>
                                            {
                                                (formik.values["applicantNameChange"] && ["Husband's Name", "Surname", "Father's Name"].some(r => formik.values['applicantNameChange'].indexOf(r) >= 0)) && (
                                                    <Grid container spacing={1} className={classes.separateSection}>
                                                        {getElementToBeRendered("whatToDo")}
                                                    </Grid>
                                                )
                                            }
                                            <Grid container spacing={2} className={classes.separateSection}>
                                                {
                                                    (formik.values["applicantNameChange"] && ["Husband's Name", "Surname", "Father's Name", "Spelling Change"].some(r => formik.values['applicantNameChange'].indexOf(r) >= 0)) && (
                                                        getElementToBeRendered("changeProof")
                                                    )
                                                }
                                                {
                                                    (formik.values["applicantNameChange"] && formik.values['applicantNameChange'].includes("Entire Name Change")) && (
                                                        getElementToBeRendered("entireNameChangeProof")
                                                    )
                                                }
                                            </Grid>
                                            {
                                                (formik.values["applicantNameChange"] && ["Husband's Name", "Surname", "Father's Name", "Spelling Change"].some(r => formik.values['applicantNameChange'].indexOf(r) >= 0)) && (
                                                    <>
                                                        <Grid container spacing={3} className={classes.separateSection}>
                                                            {orderedFieldsForApplicantName.map(field => {
                                                                return getElementToBeRendered(field)
                                                            })}
                                                            {getElementToBeRendered("nonIndividualName", 12)}
                                                        </Grid>
                                                        <Grid container spacing={1} className={classes.separateSection}>
                                                            {getElementToBeRendered("nameOnPanCard")}
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    formik.values["isApplicantNameCorrection"] == false && (
                                        <>
                                            <Grid container spacing={3} className={classes.separateSection}>
                                                {orderedFieldsForApplicantName.map(field => {
                                                    return getElementToBeRendered(field)
                                                })}
                                                {getElementToBeRendered("nonIndividualName", 12)}
                                            </Grid>
                                            <Grid container spacing={1} className={classes.separateSection}>
                                                {getElementToBeRendered("nameOnPanCard")}
                                            </Grid>
                                        </>
                                    )
                                }
                            </>
                        )
                        :
                        (

                            formik.values["isApplicantNameCorrection"] != null && (
                                <>
                                    <Grid container spacing={3} className={classes.separateSection}>
                                        {orderedFieldsForApplicantName.map(field => {
                                            return getElementToBeRendered(field)
                                        })}
                                        {getElementToBeRendered("nonIndividualName", 12)}
                                    </Grid>
                                    <Grid container spacing={1} className={classes.separateSection}>
                                        {getElementToBeRendered("nameOnPanCard", 12)}
                                    </Grid>
                                </>
                            )
                        )
                }


                {/* {Applicant Name Correction} */}

                {/* {Applicant Parents Name Correction} */}
                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("isParentNameCorrection", 12)}
                </Grid>
                {
                    formik.values["isParentNameCorrection"] === true && (
                        <>
                            <Grid container spacing={1} className={classes.separateSection}>
                                {getElementToBeRendered("isWhoseName", 12)}
                            </Grid>
                            <Grid container spacing={1} className={classes.separateSection}>
                                {
                                    formik.values["isWhoseName"] === false && (
                                        <>
                                            {getElementToBeRendered("fathersNameChange")}
                                            <Grid container spacing={2} className={classes.separateSection}>
                                                {
                                                    (formik.values["fathersNameChange"] && ["Father's Last Name", "Father's Middle Name", "Father's First Name", "Spelling Change"].some(r => formik.values['fathersNameChange'].indexOf(r) >= 0)) && (
                                                        getElementToBeRendered("parentsChangeProof")
                                                    )
                                                }
                                                {
                                                    (formik.values["fathersNameChange"] && formik.values['fathersNameChange'].includes("Entire Name Change")) && (
                                                        getElementToBeRendered("parentsEntireNameChangeProof")
                                                    )
                                                }
                                            </Grid>
                                            {
                                                (formik.values["fathersNameChange"] && formik.values['fathersNameChange'].length >= 1) && (
                                                    <Grid container spacing={3} className={classes.separateSection}>
                                                        {
                                                            orderedFieldsForFatherName.map(field => {
                                                                return getElementToBeRendered(field, 4)
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    formik.values["isWhoseName"] === true && (
                                        <>
                                            {getElementToBeRendered("mothersNameChange")}
                                            <Grid container spacing={2} className={classes.separateSection}>
                                                {
                                                    (formik.values["mothersNameChange"] && ["Mother's Last Name", "Mother's Middle Name", "Mother's First Name", "Spelling Change"].some(r => formik.values['mothersNameChange'].indexOf(r) >= 0)) && (
                                                        getElementToBeRendered("parentsChangeProof")
                                                    )
                                                }
                                                {
                                                    (formik.values["mothersNameChange"] && formik.values['mothersNameChange'].includes("Entire Name Change")) && (
                                                        getElementToBeRendered("parentsEntireNameChangeProof")
                                                    )
                                                }
                                            </Grid>
                                            {
                                                (formik.values["mothersNameChange"] && formik.values['mothersNameChange'].length >= 1) && (
                                                    <Grid container spacing={3} className={classes.separateSection}>
                                                        {
                                                            orderedFieldsForMotherName.map(field => {
                                                                return getElementToBeRendered(field, 4)
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Grid>
                        </>
                    )
                }
                {
                    formik.values["isParentNameCorrection"] === false && (
                        <>
                            <Grid container spacing={2} className={classes.separateSection}>
                                {getElementToBeRendered("parentsName")}
                                {formik.values["parentsName"] === "mothersName" ? getElementToBeRendered("singleParent") : ""}
                            </Grid>

                            <Grid container spacing={3} className={classes.separateSection}>
                                {
                                    (formik.values["singleParent"] === "yes" && formik.values["parentsName"] === "mothersName") && (
                                        orderedFieldsForMotherName.map(field => {
                                            return getElementToBeRendered(field, 4)
                                        })
                                    )
                                }
                            </Grid>

                            <Grid container spacing={3} className={classes.separateSection}>
                                {
                                    formik.values["parentsName"] === "fathersName" && (
                                        orderedFieldsForFatherName.map(field => {
                                            return getElementToBeRendered(field, 4)
                                        })
                                    )
                                }
                            </Grid>

                            <Grid container spacing={3} className={classes.separateSection}>
                                {
                                    formik.values["singleParent"] === "no" && formik.values["parentsName"] == "mothersName" && (
                                        <>
                                            {orderedFieldsForFatherName.map(field => {
                                                return getElementToBeRendered(field, 4)
                                            })}
                                            {orderedFieldsForMotherName.map(field => {
                                                return getElementToBeRendered(field, 4)
                                            })}
                                        </>
                                    )
                                }
                            </Grid>
                        </>
                    )
                }
                {/* {Applicant Parents Name Correction} */}

                {/* {Applicant DOB correction} */}
                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered("isDobCorrection", 12)}
                    {
                        formik.values["isDobCorrection"] != null && (
                            getElementToBeRendered("dateOfBirth")
                        )
                    }
                </Grid>
                {/* {Applicant DOB correction} */}

                {/* {Applicant Aadhaar correction} */}
                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered("isAadhaarCorrection", 12)}
                </Grid>
                {
                    formik.values["isAadhaarCorrection"] != null && (
                        <Grid container spacing={4} className={classes.separateSection}>
                            {getElementToBeRendered('aadhaarCard')}
                            {formik.values['aadhaarCard'] === "yes" && getElementToBeRendered('aadhaarNumber')}
                            {getElementToBeRendered("aadhaarName")}
                        </Grid>
                    )
                }
                {/* {Applicant Aadhaar correction} */}

                {/* {Applicant Other PAN correction} */}
                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered("isMentionOtherPan", 12)}
                </Grid>
                {
                    formik.values["isMentionOtherPan"] === true && (
                        <Grid container spacing={4} className={classes.separateSection}>
                            {getElementToBeRendered("otherPan1")}
                            {formik.values.otherPan1 && getElementToBeRendered("otherPan2")}
                            {formik.values.otherPan2 && getElementToBeRendered("otherPan3")}
                            {formik.values.otherPan3 && getElementToBeRendered("otherPan4")}
                        </Grid>
                    )
                }
                {/* {Applicant Other PAN correction} */}

            </Grid>
        </React.Fragment>
    )
};


export default withRouter(BasicDetails);
