import React, { useEffect, useRef, useState } from "react";
import {
    SubVariantItem,
    ItemSeperator,
    ShrinkedItem
} from "./SharedComponents";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import { compose } from "recompose";
import { useHistory } from "react-router-dom";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ChooseApplication from './chooseApplication';

const ReprintNonIndividualPage = (props) => (
    <AuthUserContext.Consumer>
        {authUser => <ReprintNonIndividual authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const ReprintNonIndividualBase = props => {
    const viewRef = useRef(null);
    let history = useHistory();
    const [applicationId, setApplicationId] = useState(null);
    const [displayName, setDisplayName] = useState();
    const [nriForeignData, setNriForeignData] = useState("");

    useEffect(() => {
        setNriForeignData("")
    }, [props.applicationCategory])

    const BASE_STYLE = {
        borderLeft: `5px solid ${COLORS.DEFAULT_BORDER_COLOR}`,
        cursor: "pointer",
        padding: "20px 35px",
        height: "90%",
        marginBottom: "25px",
        fontSize: "10px"
    };

    useEffect(() => {
        viewRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    const continueToFillingForm = () => {
        const createApplicantFormDetails = props.firebase.functions.httpsCallable(
            "createApplicantFormDetails"
        );
        props.setPageLoading(true)

        createApplicantFormDetails({
            uid: props.authUser.uid,
            formType: props.choosenApplication
        }).then(result => {
            setApplicationId(result.data.documentId);
            props.setPageLoading(false)
            history.push({
                pathname: ROUTES.FORM_FILLING,
                search: "?id=" + result.data.documentId + "&formType=" + props.choosenApplication
            });
        });
    }

    const onFormVariantSelect = (formVariant, displayName) => {
        props.setChoosenApplication(formVariant)
        setDisplayName(displayName)
    };

    const onNriForeignSelect = (formVariant, displayName) => {
        console.log(formVariant)
        if (formVariant == "correctionReprintNriIndividual") {
            setNriForeignData("nriIndividual")
        }
        else if (formVariant == "correctionReprintNriMinor") {
            setNriForeignData("nriMinor")
        }
        else if (formVariant == "correctionReprintForeignIndividual") {
            setNriForeignData("foreignIndividual")
        }
        else if (formVariant == "correctionReprintForeignMinor") {
            setNriForeignData("foreignMinor")
        }
    }

    const showData = {
        ReprintNonIndividualdata: [
            {
                name: "correctionReprintHuf",
                caption: "HUF",
                displayName: "Correction Reprint HUF",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint HUF.svg"
            },
            {
                name: "correctionReprintFirm",
                caption: "Firm",
                displayName: "Correction Reprint Firm",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint Firm.svg"
            },
            {
                name: "correctionReprintTrust",
                caption: "Trust",
                displayName: "Correction Reprint Trust",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint Trust.svg"
            },
            {
                name: "correctionReprintAop",
                caption: "Association of Persons(AOP)",
                displayName: "Correction Reprint Association of Persons(AOP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint AOP.svg"
            },
            {
                name: "correctionReprintLlp",
                caption: "Limited Liability Partnership(LLP)",
                displayName: "Correction Reprint Limited Liability Partnership(LLP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint LLP.svg"
            },
            {
                name: "correctionReprintBoi",
                caption: "Body of Individuals(BOI)",
                displayName: "Correction Reprint Body of Individuals(BOI)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint BOI.svg"
            },
            {
                name: "correctionReprintLocalAuthority",
                caption: "Local Authority",
                displayName: "Correction Reprint Local Authority",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint Local Authority.svg"
            },
            {
                name: "correctionReprintAjp",
                caption: "Artificial Juridicial Person(AJP)",
                displayName: "Correction Reprint Artificial Juridicial Person(AJP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint AJP.svg"
            },
            {
                name: "correctionReprintGovernment",
                caption: "Government",
                displayName: "Correction Reprint Government",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint Govt.svg"
            },
            {
                name: "correctionReprintCompany",
                caption: "Company",
                displayName: "Correction Reprint Company",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/reprint/Reprint Company.svg"
            },
        ],
        nriData: [
            {
                name: "correctionReprintNriIndividual",
                caption: "NRI Individual",
                displayName: "NRI Individual",
                onSelect: onNriForeignSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/Individual.svg"
            },
            {
                name: "correctionReprintNriMinor",
                caption: "NRI Minor",
                displayName: "NRI Minor",
                onSelect: onNriForeignSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/Minor.svg"
            }
        ],
        nriIndividual: [
            {
                name: "correctionReprintNriIndia",
                caption: "Card Delivery to India",
                displayName: "correction NRI India",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            },
            {
                name: "correctionReprintNriAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction NRI Abroad",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            }
        ],
        nriMinor: [
            {
                name: "correctionReprintMinorNriIndia",
                caption: "Card Delivery to India",
                displayName: "correction NRI India",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            },
            {
                name: "correctionReprintMinorNriAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction NRI Abroad",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            }
        ],
        foreignData: [
            {
                name: "correctionReprintForeignIndividual",
                caption: "Foreign Individual",
                displayName: "Foreign Individual",
                onSelect: onNriForeignSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/Individual.svg"
            },
            {
                name: "correctionReprintForeignMinor",
                caption: "Foreign Minor",
                displayName: "Foreign Minor",
                onSelect: onNriForeignSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/Minor.svg"
            }
        ],
        foreignIndividual: [
            {
                name: "correctionReprintForeignAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction Foreign Abroad",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            },
            {
                name: "correctionReprintForeignIndia",
                caption: "Card Delivery to India",
                displayName: "correction Foreign India",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            }
        ],
        foreignMinor: [
            {
                name: "correctionReprintMinorForeignAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction Foreign Abroad",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            },
            {
                name: "correctionReprintMinorForeignIndia",
                caption: "Card Delivery to India",
                displayName: "correction Foreign India",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            }
        ]
    }

    var data = ""

    if (props.applicationCategory == "nri") {
        data = "nriData"
    }
    else if (props.applicationCategory == "foreign") {
        data = "foreignData"
    }
    else {
        data = "ReprintNonIndividualdata"
    }

    const ReprintNonIndividualItems = showData[data].map(i => {
        return (
            <ShrinkedItem
                name={i.name}
                active={i.name === props.choosenApplication}
                caption={i.caption}
                displayName={i.displayName}
                details={i.details}
                onSelect={i.onSelect}
                styles={BASE_STYLE}
                imgsrc={i.imgsrc}
                colSize={3}
                bgColor={COLORS.CORRECTION_APPLICATION}
                activeColor={props.activeColor}
            />
        );
    });

    var nriForeignItems;

    if (showData[nriForeignData]) {
        nriForeignItems = showData[nriForeignData].map(i => {
            console.log(i)
            return (
                <ShrinkedItem
                    name={i.name}
                    active={i.name === props.choosenApplication}
                    caption={i.caption}
                    displayName={i.displayName}
                    details={i.details}
                    onSelect={i.onSelect}
                    styles={BASE_STYLE}
                    imgsrc={i.imgsrc}
                    colSize={3}
                    bgColor={COLORS.FRESH_APPLICATION}
                    activeColor={props.activeColor}
                />
            );
        });
    }

    var currentApplicationTypes = ["correctionReprintHuf", "correctionReprintFirm", "correctionReprintTrust", "correctionReprintAop",
        "correctionReprintLlp", "correctionReprintBoi", "correctionReprintLocalAuthority", "correctionReprintAjp",
        "correctionReprintGovernment", "correctionReprintCompany", "correctionReprintNriIndia", "correctionReprintNriAbroad", "correctionReprintMinorNriIndia", "correctionReprintMinorNriAbroad", "correctionReprintForeignAbroad",
        "correctionReprintForeignIndia", "correctionReprintMinorForeignAbroad", "correctionReprintMinorForeignIndia"]

    return (
        <div>
            <div className="section-title mb-10 mt-10" ref={viewRef}></div>
            <Grid container style={{ display: "flex", justifyContent: "row" }}>
                {ReprintNonIndividualItems}
            </Grid>
            <Grid container style={{ display: "flex", justifyContent: "row" }}>
                {nriForeignItems}
            </Grid>
            {
                currentApplicationTypes.includes(props.choosenApplication) && (
                    <ChooseApplication
                        choosenApplication={props.choosenApplication}
                        displayName={displayName}
                        continueToFillingForm={continueToFillingForm}
                        activeColor={props.activeColor}
                        setChoosenApplication={props.setChoosenApplication}
                    />
                )
            }
        </div>
    );
};

const ReprintNonIndividual = compose(withFirebase)(ReprintNonIndividualBase);

export default ReprintNonIndividualPage;