export const LANDING = "/";
export const USER_ENTRY = "/user-entry";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/landing";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_CHANGE = "/pw-change";
export const FRESH_APPLICATION = "/fresh-application";
export const PRODUCTS = "/products";
export const APPLY_FORM = "/select-form";
export const APPLICATION = "/application";
export const FORM_FILLING = "/edit-application";
export const APPLIED_FORMS = "/appliedforms";
export const ORDER_PAGE = "/order-page";
export const TRANSACTION_STATUS = "/transaction-status";
export const CURRENT_ORDERS = "/current-orders";
export const CURRENT_TRANSACTIONS = "/current-transactions";
export const PROFILE_PAGE = "/profile"
export const FAQ_SECTION = "/faq"
export const FEES_CALCULATOR = "/fees-calculator"