import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.applypan.com/">
        Applypan.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    elevation: 3
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  eyeIcon: {
    cursor: "pointer"
  },
  firebaseErrorMsg: {
    marginTop: theme.spacing(1),
    border: '2px solid #f4b2b2',
    borderRadius: 5,
    backgroundColor: '#ffdddd',
    minHeight: 50,
    padding: 12
  },
  firebaseTxt: {
    color: '#ff8484',
    fontSize: 14
  },
  signinText: {
    marginTop: theme.spacing(1)
  }
}));

const SignIn = (props) => {
  const classes = useStyles();

  const [state, setState] = React.useState({ ...INITIAL_STATE });
  const [togglePwd, setTogglePwd] = React.useState(false);
  const [firebaseError, setFirebaseError] = useState();
  const [showFields, setShowFields] = useState({ mobile: true });
  const [otpResponse, setOtpResponse] = useState();
  const [isMobile, setIsMobile] = useState();

  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendOtp = () => {
    props.firebase.doVerifyRecaptcha()
    window.recaptchaVerifier.render()
    var appVerifier = window.recaptchaVerifier
    let mobileNo = '+91' + "9876543210"
    props.firebase.doSignInWithMobileNumber(mobileNo, appVerifier)
      .then((response) => {
        setOtpResponse(response)
        setShowFields({ verifyOtp: true });
      })
      .then(() => {
        verifyUserOtp()
      })
      .catch((error) => { console.log(error) })
  }

  const verifyUserOtp = () => {
    var code = "123456"
    otpResponse.confirm(code).then((result) => {
      var credential = props.firebase.authProv.PhoneAuthProvider.credential(otpResponse.verificationId, code);
      props.firebase.signInWithCredential(credential)
        .then(() => {
          props.firebase.db.collection("users").where("mobileNumber", "==", state.email)
            .onSnapshot(function (querySnapshot) {
              querySnapshot.forEach((userDoc) => {
                setState(state => ({
                  ...state, ["email"]: userDoc.data().email
                }))
              })
            })
        })
        .then(() => {
          const { email, password } = state;

          props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
              setState({ ...INITIAL_STATE });
              props.history.push(ROUTES.HOME);
            })
            .catch(error => {
              setState(prevState => ({ ...prevState, error }));
              setFirebaseError(error.message)
            });
        })
        .catch((error) => { console.log(error) })
    })
      .catch((error) => { setFirebaseError("Invalid OTP!"); console.log(error); })
  }

  const onSubmit = event => {
    event.preventDefault();

    const { email, password } = state;

    if (/^\d{10}$/.test(email)) {
      setIsMobile(true)
      sendOtp()
    }
    else {
      props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(() => {
          setState({ ...INITIAL_STATE });
          props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          setState(prevState => ({ ...prevState, error }));
          setFirebaseError(error.message)
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src="/assets/img/logo/applypan.svg" alt="logo" width="150" height="50" />
        <Typography component="h1" variant="h5" className={classes.signinText}>
          Existing User/ Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={togglePwd ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            InputProps={{
              endAdornment: togglePwd ? <Visibility onClick={() => setTogglePwd(!togglePwd)} className={classes.eyeIcon} /> : <VisibilityOff onClick={() => setTogglePwd(!togglePwd)} className={classes.eyeIcon} />
            }}
          />
          <Grid container>
            <Grid item xs={12} md={12} id="recaptcha-container" style={{ paddingLeft: 45, paddingTop: 20 }}></Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmit}
          >
            Sign In
          </Button>

          {
            firebaseError ?
              (
                <Grid container>
                  <Grid item xs={12} md={12} className={classes.firebaseErrorMsg}>
                    <Typography variant="h6" className={classes.firebaseTxt}>
                      {firebaseError}
                    </Typography>
                  </Grid>
                </Grid>
              ) :
              ""
          }

          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={() => { props.history.push(ROUTES.PASSWORD_FORGET) }}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={() => { props.history.push(ROUTES.SIGN_UP) }}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default withRouter(withFirebase(SignIn))