import * as Yup from 'yup';

const initialNameArray = [
    "AB", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AR", "AS", "AU", "AW", "BE", "BI", "BO", "BP", "CH",
    "CO", "CY", "DA", "DE", "DI", "DN", "DO", "DU", "EE", "EK", "EM", "EP", "ER", "ES", "EX", "FA", "FE", "FK", "FA",
    "FE", "FK", "FU", "GH", "GI", "GO", "GU", "HA", "HE", "HO", "HU", "IB", "ID", "II", "IK", "IL", "IM", "IN", "IV",
    "JA", "JE", "JI", "JO", "JR", "JU", "KA", "KC", "KE", "KH", "KI", "KJ", "KM", "KO", "KS", "KU", "LA", "LE", "LI",
    "LO", "LU", "MA", "MC", "MD", "MG", "MH", "MI", "MO", "MU", "NA", "NG", "NI", "NO", "OD", "OE", "OH", "OI", "OJ",
    "OK", "OM", "ON", "OR", "OS", "OU", "OZ", "PA", "PI", "PO", "PT", "PU", "QI", "RA", "AI", "RU", "SA", "SE", "SI",
    "SK", "SM", "SO", "SR", "SU", "TA", "TE", "TI", "TO", "TU", "UG", "UL", "UM", "UN", "UR", "UZ", "VK", "WO", "WU",
    "XI", "XU", "YE", "YH", "YI", "YJ", "YO", "YU", "ZA", "ZI", "ZU"
]

const Schema = Yup.object().shape({
    panNumber: Yup.string().matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Must match with the pattern').required('PAN number is required'),

    gender: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Gender is required").nullable(),
            }
        ),

    maritalStatus: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["correctionReprintMinor"].includes(val),
                            then: Yup.string().required("Marital Status is required").nullable(),
                        }
                    )
            }
        ),

    titleOnName: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Salutation on Name is required").nullable(),
            }
        ),

    lastName: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('lastName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less").required('Last Name/ Surname is mandatory'),
                        }
                    )
            }
        ).nullable(),

    firstName: Yup.string()
        .when('lastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'firstName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string(),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less")
                    }
                ),
                otherwise: Yup.string().max(0, "Last Name/ Surname must be filled")
            }
        ).nullable(),

    middleName: Yup.string()
        .when('firstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'middleName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string(),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less")
                    }
                ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ).nullable(),

    nameOnPanCard: Yup.string().required('Select a valid name to be printed on PAN Card').nullable(),

    nonIndividualName: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["Individual"].includes(val),
                then: Yup.string().required('Organisation name is required').nullable(),
            }
        ).nullable(),

    dateOfBirth: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Date of Birth is required"),
                otherwise: Yup.string().required("Date of Formation is required")
            }
        ).nullable(),

    parentsName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().required("Parents Name is required")
        }
    ).nullable(),

    singleParent: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('parentsName',
                {
                    is: (val) => val === "mothersName",
                    then: Yup.string().required("Single Parent is required")
                }
            )
        }
    ).nullable(),

    fathersLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('parentsName',
                {
                    is: (val) => val === "fathersName",
                    then: Yup.string()
                        .when(
                            'fathersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Father Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Father Last Name/ Surname is mandatory'),
                            }
                        )
                }
            )
        }
    ).when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('singleParent',
                {
                    is: (val) => val === "no",
                    then: Yup.string()
                        .when(
                            'fathersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Father Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Father Last Name or Surname is mandatory'),
                            }
                        )
                }
            )
        }
    )
        .nullable(),

    mothersLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('singleParent',
                {
                    is: (val) => val === "yes",
                    then: Yup.string()
                        .when(
                            'mothersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Mother Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Mother Last Name/ Surname is mandatory'),
                            }
                        )
                }
            )
                .when('parentsName',
                    {
                        is: (val) => val === "mothersName",
                        then: Yup.string()
                            .when(
                                'mothersLastName',
                                {
                                    is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                    then: Yup.string().required("Last Name is required"),
                                    otherwise: Yup.string()
                                        .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                        .max(40, "Must be 40 characters or less").required('Mother Last Name/ Surname is mandatory'),
                                }
                            )
                    }
                )
        }
    ).nullable(),

    fathersMiddleName: Yup.string()
        .when('fathersFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'fathersMiddleName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ),

    mothersMiddleName: Yup.string()
        .when('mothersFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'mothersMiddleName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ),

    fathersFirstName: Yup.string()
        .when('fathersLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'fathersFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ),

    mothersFirstName: Yup.string()
        .when('mothersLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'mothersFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ),

    nickLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('isNickName',
                {
                    is: (val) => val === true,
                    then: Yup.string()
                        .when(
                            'nickLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Other Last Name/ Surname is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Other Last Name/ Surname is mandatory'),
                            }
                        )
                }
            )
        }
    ).nullable(),


    nickFirstName: Yup.string()
        .when('nickLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'nickFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ).nullable(),

    nickMiddleName: Yup.string()
        .when('nickFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'nickMiddleName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string().required("Last Name/ Surname is required"),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less"),
                    }
                ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ).nullable(),

    aadhaarCard: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Do you have aadhaar card?").nullable(),
            }
        ),

    aadhaarNumber: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('aadhaarCard',
                {
                    is: (val) => val === "yes",
                    then: Yup.string().matches(/^\d{4}\s\d{4}\s\d{4}$/,
                        "Aadhaar is always twelve digit number").required("Aadhaar Number is mandatory"),
                }
            )
        }
    ).nullable(),

    aadhaarName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('aadhaarCard',
                {
                    is: (val) => val === "yes",
                    then: Yup.string().required("Aadhaar Name is mandatory")
                        .matches(/^[a-zA-Z'.,-\s]*$/, 'Numbers are not permitted')
                        .max(26, 'Must have atmost 26 characters')
                }
            )
        }
    ).nullable(),

    otherPan1: Yup.string().when('isMentionOtherPan',
        {
            is: (val) => val === true,
            then: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern').required('Must mention atleast one PAN number')
        }
    ).nullable(),
    otherPan2: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern'),
    otherPan3: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern'),
    otherPan4: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern'),

    isOfficeAddressDelivery: Yup.boolean().required('Office address delivery is requires'),

    addressArea: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionReprintNriAbroad", "correctionReprintForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address Area is required"),
            }
        ),

    addressBuilding: Yup.string()
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Name of Office is required"),
            }
        ),

    addressCity: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionReprintNriAbroad", "correctionReprintForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address City is required"),
            }
        ),

    addressCountry: Yup.string()
        .max(25,
            "Must be 25 characters or less").nullable().required("Address Country is required"),

    addressFlat: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionReprintNriAbroad", "correctionReprintForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address Flat is required"),
            }
        ),

    addressPincode: Yup.string().nullable()
        .required("Pincode is required")
        .min(7, "Invalid Pincode")
        .max(7, "Invalid Pincode"),

    addressRoad: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionReprintNriAbroad", "correctionReprintForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address Road is required"),
            }
        ),

    addressState: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionReprintNriAbroad", "correctionReprintForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address State is required"),
            }
        ),

    emailAddress: Yup.string().nullable().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "we can contact you only via a valid mail Id").required('Email ID is required for mailing you the document').email('we can contact you only via a valid mail Id'),
    
        mobileNumber: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionReprintNriAbroad", "correctionReprintForeignAbroad"].includes(val),
                then: Yup.string().required('Mobile number is required'),
            }
        ),

    mobileNumberForeign: Yup.string()
        .when('formType',
            {
                is: (val) => ["correctionReprintNriAbroad", "correctionReprintForeignAbroad"].includes(val),
                then: Yup.string().required('Mobile number is required').min(13, "Mobile Number must have 13 numbers").max(13, "Mobile Number must have 13 numbers"),
            }
        ),


    panProof: Yup.string().required("PAN proof required").nullable(),
    proofOfDob: Yup.string().required("DOB proof required").nullable(),
    proofOfIdentity: Yup.string().required("Identity proof required").nullable(),

    proofOfOffice: Yup.string()
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().required("Office proof required").nullable(),
            }
        ),

    proofOfResidence: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Residence proof required").nullable(),
            }
        ).nullable()
})

export default Schema;