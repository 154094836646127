import React, { useEffect, useLayoutEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import { compose, mapProps } from "recompose";
import { AuthUserContext, withAuthorization } from "../Session";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import SortIcon from '@material-ui/icons/Sort';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';

const useStyles = makeStyles((theme) => ({
    paper: {
        flexGrow: 1,
        backgroundColor: "white",
        minHeight: 550,
        bFormRadius: theme.spacing(2, 2, 0, 0),
        padding: theme.spacing(5, 10, 0, 5),
    },
    heading: {
        marginLeft: theme.spacing(45),
        marginBottom: theme.spacing(2),
        fontSize: 42
    },
    eachFaq: {
        marginBottom: theme.spacing(2)
    },
    question: {
        marginBottom: theme.spacing(1),
        fontSize: 20,
        fontWeight: 400
    },
    answer: {
        fontSize: 14,
        color: "gray",
        fontWeight: 400,
        marginLeft: theme.spacing(3)
    }
}));

const FaqListPage = () => (
    <AuthUserContext.Consumer>
        {authUser => <FaqList authUser={authUser} />}
    </AuthUserContext.Consumer>
);

const FaqListBase = props => {

    const classes = useStyles();

    const [existingFaq, setExistingFaq] = useState();

    useEffect(() => {
        let arrayOfFaq = []
        //  Bring all Faq for current application
        props.firebase.db.collection("faq").orderBy("position")
            .onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    arrayOfFaq.push(doc.data())
                });
                setExistingFaq(arrayOfFaq)
            })
    }, [])

    const printFaq = () => {
        let arrayOfFaq = []
        existingFaq.map((value, index) => {
            arrayOfFaq.push(
                <Grid item xs={12} md={12} className={classes.eachFaq}>
                    <Typography variant="h4" className={classes.question}>{index + 1}. {value.question}</Typography>
                    <Typography variant="h6" className={classes.answer}>{value.answer}</Typography>
                </Grid>
            )
        })
        return arrayOfFaq;
    } 

    if (!existingFaq) return <h1>Loading...</h1>

    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.mainContainer}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.heading}>FAQ's</Typography>
                    <Grid container className={classes.faqContainer}>
                        {printFaq()}
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
};

const FaqList = compose(withFirebase)(FaqListBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(FaqListPage);