import React from "react";
import * as ROUTES from "../../constants/routes";
import CircularProgress from '@material-ui/core/CircularProgress';

const TransactionPending = () => {
    return (
        <div>
            YOUR TRANSACTION IS STILL IN PROGRESS... PLEASE WAIT... <CircularProgress color="primary" />
        </div>
    )
};

export default TransactionPending;