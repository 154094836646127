import React, { useEffect, useRef } from "react";
import { ShrinkedItem, SubVariantItem } from "./SharedComponents";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as COLORS from "../../constants/colors";

const MakeCorrectionLayout = props => {
  const viewRef = useRef(null);

  const styleForCategory = {
    backgroundColor: "#F5DA1D",
    color: "white",
    padding: "10px",
    marginLeft: "5px",
    borderRadius: "10px"
  };

  const styleForItem = {
    borderLeft: "5px solid #F5DA1D",
    cursor: "pointer",
    padding: "20px 35px",
    height: "90%",
    marginBottom: "25px"
  };

  useEffect(() => {
    viewRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="section-title text-center mb-60" ref={viewRef}>
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          What type of PAN do you already have?
        </Typography>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">
          <Grid container style={{ display: "flex", justifyContent: "row" }}>
            <ShrinkedItem
              name="correction"
              caption="Correction Forms"
              active={'correction' == props.correctionApplicationType}
              details={"If you wnat to make changes on you individual PAN card"}
              onSelect={props.onSelect}
              styles={styleForItem}
              colSize={3}
              bgColor={COLORS.CORRECTION_APPLICATION}
              activeColor={props.activeColor}
              imgsrc={"assets/img/applywizard/correction/correctionForm.svg"}
            />
            <ShrinkedItem
              name="reprint"
              caption="Reprint/ Duplicate Form"
              active={'reprint' == props.correctionApplicationType}
              details={"If you wnat to make changes on you individual PAN card"}
              onSelect={props.onSelect}
              styles={styleForItem}
              colSize={3}
              bgColor={COLORS.CORRECTION_APPLICATION}
              activeColor={props.activeColor}
              imgsrc={"assets/img/applywizard/correction/reprintForm.svg"}
            />
          </Grid>
        </div>
      </div>
    </>
  );
};
export default MakeCorrectionLayout;
