import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Tooltip from '@material-ui/core/Tooltip';
import CurrentOrders from '../CurrentOrders';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StatusStepper from './statusStepper';
import CurrentTransactions from '../CurrentTransactions';
import Grow from '@material-ui/core/Grow';
import Chip from '@material-ui/core/Chip';

export const useCardStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto'
    },
    img: {
        // margin: 'auto',
        display: 'block',
        width: 40,
        height: 40,
        maxWidth: '100%',
        maxHeight: '100%',
    },
    boxApplication: {
        margin: "0px 1%",
        borderRadius: "10px 10px 0px 0px",
        padding: "15px",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    iconButton: {
        fontSize: "30px",
        cursor:"pointer"
    },
    cuDate: {
        fontSize: "12px"
    },
    secondCardItem: {
        fontWeight: 500,
        fontSize: 14
    },
    fourthCardItem: {
        display: "flex",
        justifyContent: "center"
    },
    sixthCardItem: {
        display: "flex",
        justifyContent: "center"
    },
    orderAccordian: {
        margin: "0px 1% 1% 1%",
    }
}));

const steps = ['Created', 'Attemted', 'Paid']

const Card = (props) => {
    const classes = useCardStyles();

    return (
        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}
        >
            <Grid container xs={12} className={classes.individualApplication}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <div className={classes.boxApplication}>
                            <Grid container>
                                <Grid item xs={12} sm={2} className={classes.image}>
                                    <img className={classes.img} alt="complex" src="/assets/img/feature/orders.svg" />
                                    <Typography gutterBottom variant="subtitle2">
                                        {props.actionDetails.firstCardItem}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="subtitle1" gutterBottom className={classes.secondCardItem}>
                                        {props.actionDetails.secondCardItem.toUpperCase()}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.thirdCardItem}>
                                    <Chip label={props.actionDetails.thirdCardItem} color="primary" variant="outlined" />
                                    {/* <Typography variant="body6" color="textSecondary"> */}
                                    {/* <StatusStepper currentStep={props.actionDetails.thirdCardItem} steps={steps}/> */}
                                    {/* </Typography> */}
                                </Grid>
                                <Grid item xs={12} sm={1} className={classes.fourthCardItem}>
                                    <Typography variant="subtitle1">
                                        {props.actionDetails.fourthCardItem}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2} className={classes.fifthCardItem}>
                                    <Typography className={classes.cuDate} variant="body2" color="textSecondary">
                                        {props.actionDetails.fifthCardItem}
                                    </Typography>
                                    <Typography className={classes.cuDate} variant="body2" color="textSecondary">
                                        {props.actionDetails.sixthCardItem}
                                    </Typography>
                                </Grid>
                                {props.actionDetails.iconName1 ?
                                    <Grid item xs={12} sm={2} className={classes.sixthCardItem}>
                                        <Tooltip title={props.actionDetails.tooltip1}>
                                            <Link href={props.actionDetails.href} target={props.actionDetails.target}>
                                                {
                                                    props.onClick
                                                        ?
                                                        <Icon class={props.actionDetails.iconName1 + " " + classes.iconButton} onClick={() => props.onClick()} />
                                                        :
                                                        <Icon class={props.actionDetails.iconName1 + " " + classes.iconButton} style={{color: props.disabled?"gray":""}}  />
                                                }
                                            </Link>
                                        </Tooltip>
                                    </Grid> : <Grid item xs={12} sm={1} className={classes.sixthCardItem}></Grid>}
                            </Grid>
                        <hr />
                        </div>
                        <Grid item xs={12}>
                            <Accordion className={classes.orderAccordian}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    onClick={() => props.setOrderId()}
                                >
                                    <Typography className={classes.heading}>Transactions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {props.orderId === props.actionDetails.firstCardItem ? <CurrentTransactions orderId={props.orderId} formType={props.actionDetails.secondCardItem} /> : ""}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grow>
    );
}

export default Card;