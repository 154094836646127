import React, { useEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { AuthUserContext, withAuthorization } from "../Session";
import "./style.css";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F8F8F8",
  },
  image: {
    width: 100,
    height: 50
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  gridImages: {
    position: "relative",
    top: 0,
    left: 0
  },
  gridImageOne: {
    top: 30,
    left: 490,
    position: "absolute",
    [theme.breakpoints.down('xs')]: {
      top: 20,
      left: 130
    },
  },
  gridImageTwo: {
    top: 50,
    left: 350,
    position: "absolute",
    [theme.breakpoints.down('xs')]: {
      top: 40,
      left: 90
    },
  },
  gridImageThree: {
    top: 70,
    left: 210,
    position: "absolute",
    [theme.breakpoints.down('xs')]: {
      top: 60,
      left: 50
    },
  },
  gridImageFour: {
    top: 90,
    left: 70,
    position: "absolute",
    [theme.breakpoints.down('xs')]: {
      top: 80,
      left: 10
    },
  },
  images: {
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "10px 6px 50px 0 rgba(0, 0, 0, 0.19), 0 6px 50px 0 rgba(0, 0, 0, 0.19)",
    borderRightMargin: "50px",
    "-webkit-transform": "skewY(15deg)",
    opacity: 1,
    [theme.breakpoints.down('xs')]: {
      // opacity: 0.3
    },
  },
  img: {
    width: 250,
    height: 400,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 250
    },
  },
  firstContainer: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      'z-index': '2'
    },
  },
  secondContainer: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      'z-index': '1',
      opacity: 0.3
    },
  },
  homeLeftContainer: {
    margin: "30% 0px 0px 10%",
    height: "auto",
    [theme.breakpoints.down('xs')]: {
      margin: "30% 0px 0px 0%",
    },
  },
  homeLeftButtons: {
    marginTop: "5%"
  },
  getStarted: {
    height: "40px",
    width: "150px",
    marginRight: "5%"
  },
  imagesHeading: {
    marginLeft: theme.spacing(7),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1),
      fontSize: 10
    },
  },
  stepNumber: {
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20,
      textAlign: 'center'
    },
  },
  customBadge: {
    backgroundColor: 'red',
    color: 'white'
  }
}));

const HomePage = () => (
  <AuthUserContext.Consumer>
    {authUser => <Home authUser={authUser} />}
  </AuthUserContext.Consumer>
);

const HomeBase = props => {

  const [uid, setUid] = useState(props.authUser.uid);
  const [username, setUsername] = useState();
  const classes = useStyles();
  const [greetings, setGreetings] = useState();
  const [applicationsCount, setApplicationsCount] = useState();

  async function getUserDataFromuid(uid) {
    props.firebase
      .user(uid)
      .get()
      .then(function (doc) {
        if (doc && doc.exists) {
          setUsername(doc.data().username);
        }
      });
  }

  useEffect(async () => {
    document.title = `Applypan - Home`;
    var today = new Date()
    var curHr = today.getHours()
    if (curHr < 12) {
      setGreetings("Good Morning")
    } else if (curHr < 18) {
      setGreetings("Good Afternoon")
    } else {
      setGreetings("Good Evening")
    }

    if (props.authUser) {
      getUserDataFromuid(props.authUser.uid)
    }
    props.firebase.db.collection("applications").where("uid", "==", props.authUser.uid)
      .onSnapshot(function (querySnapshot) {
        setApplicationsCount(querySnapshot.size)
      })

  }, [props.authUser]);

  return (
    <div className={classes.root} style={{ height: "600px" }}>
      <Grid container spacing={2}>
        <Grid xs={12} md={4} item className={classes.firstContainer}>
          <Grid container className={classes.homeLeftContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ size: 32, "l-height": 48, fontWeight: 600 }}>
                {username && `Hello ${username}, ${greetings} ..!`}
              </Typography>
              <Typography variant="body1" style={{ color: "gray", fontWeight: 500, margin: "5% 0px" }}>
                Apply PAN in 4 simple steps
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.homeLeftButtons}>
              <Button component={Link} to={ROUTES.APPLY_FORM} id="startApplyBtn" variant="contained" color="primary" className={classes.getStarted}>
                Start Applying
              </Button>
              <Badge classes={{ badge: classes.customBadge }} badgeContent={applicationsCount}>
              <Button component={Link} to={ROUTES.APPLIED_FORMS} id="dashboardBtn" variant="contained" color="primary" className={classes.getStarted}>
                Dashboard
              </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={8} item className={classes.secondContainer}>
          <Grid container className={classes.gridImages}>

            <Grid item className={classes.gridImageOne + " " + classes.images}>
              <Typography variant="subtitle1" className={classes.imagesHeading}>
                <Chip label="4" color="primary" className={classes.stepNumber} /> Downloads
            </Typography>
              <img src="/assets/img/home/download.svg" className={classes.img} alt="gridImageOne" />
            </Grid>

            <Grid item className={classes.gridImageTwo + " " + classes.images}>
              <Typography variant="subtitle1" className={classes.imagesHeading}>
                <Chip label="3" color="primary" className={classes.stepNumber} /> Payment
            </Typography>
              <img src="/assets/img/home/payment.svg" className={classes.img} alt="gridImageOne" />
            </Grid>

            <Grid item className={classes.gridImageThree + " " + classes.images}>
              <Typography variant="subtitle1" className={classes.imagesHeading}>
                <Chip label="2" color="primary" className={classes.stepNumber} /> Form Filling
            </Typography>
              <img src="/assets/img/home/fillingforms.svg" className={classes.img} alt="gridImageTwo" />
            </Grid>

            <Grid item className={classes.gridImageFour + " " + classes.images}>
              <Typography variant="subtitle1" className={classes.imagesHeading}>
                <Chip label="1" color="primary" className={classes.stepNumber} /> Select Forms
                </Typography>
              <img src="/assets/img/home/applynow.svg" className={classes.img} alt="gridImageTwo" />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const Home = compose(withFirebase)(HomeBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);