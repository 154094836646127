import * as Yup from 'yup';

const Schema = Yup.object().shape({
    username: Yup.string()
        .min(4, 'Username must contains atleast 4 characters')
        .max(50, 'Username must contains atmost 50 characters')
        .required('Username is required!'),

    email: Yup.string()
        .email('Invalid email-id')
        .required('Email is required!'),
    mobileNumber: Yup.string()
        .min(10, 'Mobile number must contains 10 characters')
        .max(10, 'Mobile number must contains 10 characters')
        .required('Mobile number is required!'),
    passwordOne: Yup.string()
        .matches(/\d/,
            "password must contains one digit")
        .matches(/(?=.*[a-z])(?=.*[A-Z])/,
            "password must contains upper and lowercase")
        .matches(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
            "password must contains one special characters")
        .min(8, 'password must contains atleast 8 characters')
        .max(50, 'password must contains atmost 50 characters')
        .required('Password is required!'),
    passwordTwo: Yup.string()
        .required("confirm your password!")
        .oneOf([Yup.ref('passwordOne'), null], 'Passwords must match')
});

export default Schema;