import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { SignInLink } from "../SignIn";

import * as ROUTES from "../../constants/routes";

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email
        });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <section className="signup signup-style-3 mb-80 pt-100">
        <div className="container">
          <div className="signup-wrapper">
            <div className="shape">
              <img src="assets/img/signup/signup-3/shape.svg" alt="" />
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="signup-form-wrapper">
                  <form onSubmit={this.onSubmit} className="signup-form">
                    {error && <p>{error.message}</p>}
                    <div className="single-input">
                      <label htmlFor="username">Name</label>
                      <input
                        name="username"
                        value={username}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="email">Email</label>
                      <input
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        type="email"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="signup-password">Password</label>
                      <input
                        name="passwordOne"
                        value={passwordOne}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Password"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="passwordTwo">Re-Type Password</label>
                      <input
                        name="passwordTwo"
                        value={passwordTwo}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Re-Type Password"
                      />
                    </div>

                    <div className="signup-button mb-25">
                      <button
                        disabled={isInvalid}
                        className="button button-lg radius-10 btn-block"
                        type="submit"
                      >
                        Sign up
                      </button>
                    </div>
                    <SignInLink />
                  </form>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1 order-first order-lg-last">
                <div className="signup-content-wrapper">
                  <div className="section-title mb-30">
                    <h3 className="mb-20">Sign Up</h3>
                    <p>Best cuetomer experience is awaiting you.</p>
                  </div>
                  <div className="action-button">
                    <p>Sign up With</p>
                    <a
                      href="#0"
                      className="button border-button button-lg radius-10 mr-15"
                    >
                      {" "}
                      <i className="lni lni-facebook-filled" /> Facebook{" "}
                    </a>
                    <a
                      href="#0"
                      className="button border-button button-lg radius-10"
                    >
                      {" "}
                      <i className="lni lni-google" /> Google{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
