import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import StatusStepper from './statusStepper';
import Chip from '@material-ui/core/Chip';

export const useCardStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto'
    },
    img: {
        // margin: 'auto',
        display: 'block',
        width: 40,
        height: 40,
        maxWidth: '100%',
        maxHeight: '100%',
    },
    boxApplication: {
        margin: "0px 1%",
        borderRadius: "10px 10px 0px 0px",
        padding: "15px",
    },
    iconButton: {
        fontSize: "30px",
        cursor:"pointer"
    },
    cuDate: {
        fontSize: "12px"
    },
    secondCardItem: {
        fontWeight: 500,
        fontSize: 14
    },
    fourthCardItem: {
        display: "flex",
        justifyContent: "center"
    },
    sixthCardItem: {
        display: "flex",
        justifyContent: "center"
    },
    transactionAccordian: {
        margin: "0px 1% 1% 1%",
    }
}));

const steps = ['Created', 'Attemted', 'Paid']

const Card = (props) => {
    const classes = useCardStyles();

    return (
        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}
        >
            <Grid container xs={12} className={classes.individualApplication}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <div className={classes.boxApplication}>
                            <Grid container>
                                <Grid item xs={12} sm={2} className={classes.image}>
                                    <img className={classes.img} alt="complex" src="/assets/img/feature/transactions.svg" />
                                    <Typography gutterBottom variant="subtitle2">
                                        {props.actionDetails.firstCardItem}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="subtitle1" gutterBottom className={classes.secondCardItem}>
                                        {props.actionDetails.secondCardItem.toUpperCase()}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.thirdCardItem}>
                                    <Chip label={props.actionDetails.thirdCardItem} color="primary" variant="outlined" />
                                    {/* <Typography variant="body6" color="textSecondary"> */}
                                    {/* <StatusStepper currentStep={props.actionDetails.thirdCardItem} steps={steps}/> */}
                                    {/* </Typography> */}
                                </Grid>
                                <Grid item xs={12} sm={1} className={classes.fourthCardItem}>
                                    <Typography variant="subtitle1">
                                        {props.actionDetails.fourthCardItem}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.fifthCardItem}>
                                    <Typography className={classes.cuDate} variant="body2" color="textSecondary">
                                        {props.actionDetails.fifthCardItem}
                                    </Typography>
                                    <Typography className={classes.cuDate} variant="body2" color="textSecondary">
                                        {props.actionDetails.sixthCardItem}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <hr />
                    </Grid>
                </Grid>
            </Grid>
        </Grow>
    );
}

export default Card;