import React from "react";
import * as ROUTES from "../../constants/routes";
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const TransactionFailure = () => {
    return (
        <div>TRANSACTION FAILURE. YOU NEED TO RETRY <Typography component={Link} to={ROUTES.CURRENT_ORDERS}>HERE</Typography></div>
    )
};

export default TransactionFailure;