import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AuthUserContext, withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import * as COLORS from '../../constants/colors';
import { useFormik } from 'formik';
import Schema from '../../lib/Validation/profile';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProfileLayout = props => (
    <AuthUserContext.Consumer>
        {authUser => <ProfileBase authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 900
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    profileBg: {
        paddingRight: 10,
        width: 900,
        height: 200
    },
    secondContainer: {
        minHeight: 100,
        position: 'relative'
    },
    userImg: {
        fontSize: 120,
        color: COLORS.FRESH_APPLICATION,
        position: 'absolute',
        top: -65
    },
    userEdit: {
        position: 'absolute',
        color: COLORS.FRESH_APPLICATION,
        right: 20,
        top: 5,
        cursor: "pointer"
    },
    userDetailsDiv: {
        marginLeft: theme.spacing(20),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    detailVal: {
        fontWeight: 400,
        fontSize: 16
    },
    detailHeading: {
        color: 'gray',
        fontSize: 14,
        fontWeight: 100
    },
    textFields: {
        width: 300
    },
    dateFields: {
        width: 350,
        marginRight: theme.spacing(7),
        marginTop: theme.spacing(2)
    }
}))

const Profile = (props) => {

    const classes = useStyles();

    const [userDetails, setUserDetails] = useState({});
    const [editFields, setEditFields] = useState(false);
    const [showBackDrop, setShowBackdrop] = useState(false);

    const updateUserProfile = props.firebase.functions.httpsCallable("updateUserProfile");

    const extractDate = (dateId) => {
        if (formik.values[dateId]) {
            // assuming date string format is dd/MM/yyyy
            const dateArr = formik.values[dateId].split('/');
            return new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
        }
        return new Date(2000, 0, 1)
    }

    useEffect(() => {
        //Get current user Details
        if (props.authUser) {
            props.firebase.db.collection("users").doc(props.authUser.uid)
                .onSnapshot(function (userDoc) {
                    console.log(userDoc.data())
                    setUserDetails(userDoc.data())
                })
        }
    }, []);

    const formik = useFormik({
        initialValues: userDetails,
        enableReinitialize: true,
        // validationSchema: Schema,
        onSubmit: formValues => {

            setShowBackdrop(true)
            
            updateUserProfile({
                uid: props.authUser.uid,
                username: formValues.username,
                email: formValues.email,
                mobileNumber: formValues.mobileNumber,
                dob: formValues.dob,
                marriageDate: formValues.marriageDate,
                religion: formValues.religion,
                companyName: formValues.companyName,
                residenceAddress: formValues.residenceAddress,
                officeAddress: formValues.officeAddress
            })
                .then((response) => { console.log(response); setEditFields(false); setShowBackdrop(false) })
                .catch((error) => { console.log(error); setShowBackdrop(false) })

        }
    })

    const handleDateChange = (date) => {
        var date = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);

        return [day, mnth, date.getFullYear()].join("/")
    };

    return (
        <Container maxWidth="md">
            <Paper className={classes.paper}>
                <Backdrop className={classes.backdrop} open={showBackDrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container>
                    <Grid item xs={12} md={12} className={classes.firstContainer}>
                        <img src="/assets/img/profile/profilebg.jpg" alt="Profile Background" className={classes.profileBg} />
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.secondContainer}>
                        <AccountCircleIcon className={classes.userImg} />
                        {
                            editFields ?
                                <Tooltip title={"Update Details"} placement={"right"}><CheckCircleIcon className={classes.userEdit} onClick={() => formik.handleSubmit()} /></Tooltip>
                                : <Tooltip title={"Edit Details"} placement={"right"}><EditIcon className={classes.userEdit} onClick={() => setEditFields(true)} /></Tooltip>
                        }
                        <Grid container spacing={2} className={classes.userDetailsDiv}>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="username"
                                            label="Username"
                                            name="username"
                                            autoComplete="username"
                                            autoFocus
                                            disabled={true}
                                            values={formik.values.username}
                                            onBlur={(e) => { formik.handleBlur(e) }}
                                            onChange={(e) => { formik.handleChange(e) }}
                                            className={classes.textFields}
                                            error={formik.touched.username && formik.errors.username}
                                            helperText={
                                                formik.touched.username && formik.errors.username
                                            }
                                        />
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.username || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Username</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            disabled={true}
                                            autoComplete="email"
                                            value={formik.values.email}
                                            className={classes.textFields}
                                            onBlur={(e) => { formik.handleBlur(e) }}
                                            onChange={(e) => { formik.handleChange(e) }}
                                            error={formik.touched.email && formik.errors.email}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.email || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Email Address</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ?
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="mobileNumber"
                                            label="Mobile Number"
                                            name="mobileNumber"
                                            disabled={true}
                                            type={"number"}
                                            className={classes.textFields}
                                            onBlur={(e) => { formik.handleBlur(e) }}
                                            onChange={(e) => {
                                                formik.handleChange(e)
                                            }}
                                            error={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                            helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                        /> : (
                                            <>
                                                <Typography variant="h5" className={classes.detailVal}>{formik.values.mobileNumber || '-'}</Typography>
                                                <Typography variant="h6" className={classes.detailHeading}>Mobile Number</Typography>
                                            </>
                                        )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardDatePicker
                                                        autoOk={true}
                                                        format="dd/MM/yyyy"
                                                        defaultValue="01/01/2000"
                                                        id={"dob"}
                                                        name={"dob"}
                                                        label={"Date of Birth"}
                                                        value={extractDate("dob")}
                                                        onChange={(e) => {
                                                            formik.handleChange({
                                                                target: {
                                                                    name: "dob",
                                                                    value: handleDateChange(e)
                                                                }
                                                            })
                                                        }}
                                                        className={classes.dateFields}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.dob || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Date of Birth</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardDatePicker
                                                        autoOk={true}
                                                        format="dd/MM/yyyy"
                                                        defaultValue="01/01/2000"
                                                        id={"marriageDate"}
                                                        name={"marriageDate"}
                                                        label={"Marriage Date"}
                                                        value={extractDate("marriageDate")}
                                                        onChange={(e) => {
                                                            formik.handleChange({
                                                                target: {
                                                                    name: "marriageDate",
                                                                    value: handleDateChange(e)
                                                                }
                                                            })
                                                        }
                                                        }
                                                        className={classes.dateFields}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.marriageDate || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Marriage Date</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="religion"
                                            label="Religion"
                                            name="religion"
                                            value={formik.values.religion}
                                            onBlur={(e) => { formik.handleBlur(e) }}
                                            onChange={(e) => { formik.handleChange(e) }}
                                            className={classes.textFields}
                                            error={formik.touched.religion && formik.errors.religion}
                                            helperText={
                                                formik.touched.religion && formik.errors.religion
                                            }
                                        />
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.religion || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Religion</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="companyName"
                                            label="Company Name"
                                            name="companyName"
                                            value={formik.values.companyName}
                                            onBlur={(e) => { formik.handleBlur(e) }}
                                            onChange={(e) => { formik.handleChange(e) }}
                                            className={classes.textFields}
                                            error={formik.touched.companyName && formik.errors.companyName}
                                            helperText={
                                                formik.touched.companyName && formik.errors.companyName
                                            }
                                        />
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.companyName || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Name of the Company</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="residenceAddress"
                                            label="Residence Address"
                                            name="residenceAddress"
                                            value={formik.values.residenceAddress}
                                            onBlur={(e) => { formik.handleBlur(e) }}
                                            onChange={(e) => { formik.handleChange(e) }}
                                            className={classes.textFields}
                                            error={formik.touched.residenceAddress && formik.errors.residenceAddress}
                                            helperText={
                                                formik.touched.residenceAddress && formik.errors.residenceAddress
                                            }
                                        />
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.residenceAddress || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Residential Address</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} md={5}>
                                {
                                    editFields ? (
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="officeAddress"
                                            label="Office Address"
                                            name="officeAddress"
                                            value={formik.values.officeAddress}
                                            onBlur={(e) => { formik.handleBlur(e) }}
                                            onChange={(e) => { formik.handleChange(e) }}
                                            className={classes.textFields}
                                            error={formik.touched.officeAddress && formik.errors.officeAddress}
                                            helperText={
                                                formik.touched.officeAddress && formik.errors.officeAddress
                                            }
                                        />
                                    ) : (
                                        <>
                                            <Typography variant="h5" className={classes.detailVal}>{formik.values.officeAddress || '-'}</Typography>
                                            <Typography variant="h6" className={classes.detailHeading}>Office Address</Typography>
                                        </>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

const condition = authUser => !!authUser;

const ProfileBase = compose(withFirebase)(Profile)
const ProfilePage = compose(withAuthorization(condition))(ProfileLayout);

export default ProfilePage;
