import React, { Component } from 'react'
import { SvgLoader, SvgProxy } from 'react-svgmt';

export default class SamplePanCard extends Component {
    render() {
        var nameLabel = "Last Name";
        const IndividualArr = ['Individual']
        const MinorArr = ['freshMinor', 'correctionMinor', 'correctionReprintMinor']
        var svgUrl = "/assets/img/samplepancard/Organisation.svg";

        if (IndividualArr.includes(this.props.formValues.applicationCategory) && !MinorArr.includes(this.props.formValues.formType)) {
            var nameLabel = "First Name Middle Name Last Name";
            svgUrl = "/assets/img/samplepancard/Individual.svg";
        }

        if (MinorArr.includes(this.props.formValues.formType)) {
            var nameLabel = "First Name Middle Name Last Name";
            svgUrl = "/assets/img/samplepancard/Minor.svg";
        }

        var DOB = this.props.formValues.dateOfBirth || 'DD/MM/YYYY'

        var fatherName = (this.props.formValues.fathersFirstName ? this.props.formValues.fathersFirstName.toUpperCase() + ' ' : "")
            + (this.props.formValues.fathersMiddleName ? this.props.formValues.fathersMiddleName.toUpperCase() + ' ' : "")
            + (this.props.formValues.fathersLastName ? this.props.formValues.fathersLastName.toUpperCase() + ' ' : "")

        var motherName = (this.props.formValues.mothersFirstName ? this.props.formValues.mothersFirstName.toUpperCase() + ' ' : "")
            + (this.props.formValues.mothersMiddleName ? this.props.formValues.mothersMiddleName.toUpperCase() + ' ' : "")
            + (this.props.formValues.mothersLastName ? this.props.formValues.mothersLastName.toUpperCase() + ' ' : "")

        return (
            <SvgLoader width="350" height="200" path={svgUrl}>
                <SvgProxy selector="#panNumber">Permanent Account Number Card</SvgProxy>
                <SvgProxy selector="#panNumber">{this.props.formValues.panNumber ? this.props.formValues.panNumber.toUpperCase() : ""}</SvgProxy>
                <SvgProxy selector="#applicant_name">{nameLabel}</SvgProxy>
                <SvgProxy selector="#applicant_name">{this.props.formValues.nameOnPanCard != null ? this.props.formValues.nameOnPanCard.toUpperCase() : ''}</SvgProxy>
                <SvgProxy selector="#parent_header">Parent's Name</SvgProxy>

                {
                    (this.props.formValues.parentsName == null) ?
                        "" :
                        (
                            <SvgProxy selector="#parent_header">{
                                (this.props.formValues.parentsName == "mothersName" || this.props.formValues.isWhoseName === true) ?
                                    "Mother's Name" : "Father's Name"
                            }</SvgProxy>
                        )
                }
                {
                    (this.props.formValues.isWhoseName == null) ?
                        "" :
                        (
                            <SvgProxy selector="#parent_header">{
                                (this.props.formValues.parentsName == "mothersName" || this.props.formValues.isWhoseName === true) ?
                                    "Mother's Name" : "Father's Name"
                            }</SvgProxy>
                        )
                }

                <SvgProxy selector="#father_name">Father's Name/Mother's Name</SvgProxy>
                {
                    (this.props.formValues.parentsName === "fathersName" || this.props.formValues.isWhoseName === false) ?
                        (
                            <SvgProxy selector="#father_name">{fatherName}</SvgProxy>
                        ) :
                        (
                            ""
                        )
                }

                {
                    (this.props.formValues.parentsName === "mothersName" || this.props.formValues.isWhoseName === true) ?
                        (
                            <SvgProxy selector="#father_name">{motherName}</SvgProxy>
                        ) :
                        (
                            ""
                        )
                }
                <SvgProxy selector="#date_of_birth">{DOB}</SvgProxy>
            </SvgLoader>
        )
    }
}