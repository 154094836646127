import React, { useEffect, useState } from "react";
import * as COLORS from "../../constants/colors";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const chooseApplication = (props) => {

    const [slide, setSlide] = useState(false)

    useEffect(() => {
        if (props.choosenApplication && slide) {
            setSlide(false)
            setTimeout(() => {
                setSlide(true)
            }, 1000)
        }
        else { setSlide(true) }
    }, [props.choosenApplication])

    return (
        <Slide direction={"right"} in={slide} timeout={1000} mountOnEnter unmountOnExit>
            <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Grid item xs={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <Typography variant="h6" component="h6" style={{ color: 'gray' }}>
                        Do you want to continue with
                  <span style={{ color: props.activeColor, backgroundColor: props.activeColor === COLORS.FRESH_APPLICATION ? COLORS.CORRECTION_APPLICATION : COLORS.FRESH_APPLICATION, margin: 5 }}> {props.displayName} </span>
                  PAN Form?
                </Typography>
                </Grid>
                <Grid item xs={6} md={2} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <IconButton aria-label="done" style={{ backgroundColor: "green" }}>
                        <DoneIcon id="doneBtn" onClick={() => props.continueToFillingForm()} style={{ color: "white" }} />
                    </IconButton>
                    <IconButton aria-label="close" style={{ backgroundColor: "red" }}>
                        <CloseIcon id="closeBtn" onClick={() => { props.setChoosenApplication(null); }} style={{ color: "white" }} />
                    </IconButton>
                </Grid>
            </Grid>
        </Slide>
    )
}

export default chooseApplication;