import React, { useState, useEffect } from "react";

import { withFirebase } from "../Firebase";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AuthUserContext, withAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { compose } from "recompose";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null
};
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    elevation: 3
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  eyeIcon: {
    cursor: "pointer"
  },
  firebaseErrorMsg: {
    marginTop: theme.spacing(1),
    border: '2px solid #f4b2b2',
    borderRadius: 5,
    backgroundColor: '#ffdddd',
    minHeight: 50,
    padding: 12
  },
  firebaseTxt: {
    color: '#ff8484',
    fontSize: 14
  },
  signinText: {
    marginTop: theme.spacing(1)
  }
}));


const PasswordChangeLayout = props => (
  <AuthUserContext.Consumer>
      {authUser => <PasswordChangeBase authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const PasswordChange = (props) => {

  const [state, setState] = useState({});
  const [togglePwd, setTogglePwd] = React.useState(false);

  const classes = useStyles();

  const onSubmit = event => {
    event.preventDefault();

    const { passwordOne } = state;

    props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        setState({ error: {
          message: "Password updated successfully!"
        } });
      })
      .catch(error => {
        setState({ error });
      });
  };

  const onChange = event => {
    setState(state => ({
      ...state, [event.target.name]: event.target.value
    }))
  }

  const { passwordOne, passwordTwo, error } = state;

  const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src="/assets/img/logo/applypan.svg" alt="logo" width="150" height="50" />
        <Typography component="h1" variant="h5" className={classes.signinText}>
          Change/ Reset Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="passwordOne"
            label="Password"
            name="passwordOne"
            autoComplete="passwordOne"
            autoFocus
            type={togglePwd ? "text" : "password"}
            InputProps={{
              endAdornment: togglePwd ? <Visibility onClick={() => setTogglePwd(!togglePwd)} className={classes.eyeIcon} /> : <VisibilityOff onClick={() => setTogglePwd(!togglePwd)} className={classes.eyeIcon} />
            }}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="passwordTwo"
            label="Confirm Password"
            name="passwordTwo"
            autoComplete="passwordTwo"
            onChange={onChange}
            type={togglePwd ? "text" : "password"}
            InputProps={{
              endAdornment: togglePwd ? <Visibility onClick={() => setTogglePwd(!togglePwd)} className={classes.eyeIcon} /> : <VisibilityOff onClick={() => setTogglePwd(!togglePwd)} className={classes.eyeIcon} />
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isInvalid}
            onClick={onSubmit}
            className={classes.submit}
          >
            Reset Password
          </Button>
        </form>
      </div>

      {
        (error && error.message) ?
          (
            <Grid container>
              <Grid item xs={12} md={12} className={classes.firebaseErrorMsg}>
                <Typography variant="h6" className={classes.firebaseTxt}>
                  {error.message}
                </Typography>
              </Grid>
            </Grid>
          ) :
          ""
      }
    </Container>
  );
}

const condition = authUser => !!authUser;
const PasswordChangeBase = compose(withFirebase)(PasswordChange)
const PasswordChangeComponent = compose(withAuthorization(condition))(PasswordChangeLayout);

export default PasswordChangeComponent;