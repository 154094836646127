import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import queryString from 'query-string';
import TxnSuccess from './transactionSuccess';
import TxnFailure from './transactionFailure';
import TxnPending from './transactionPending';

const TransactionPageLayout = props => (
    <AuthUserContext.Consumer>
        {authUser => <TransactionPageBase authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const TransactionPage = (props) => {
    let params = queryString.parse(props.location.search);

    const checkTransactionStatus = props.firebase.functions.httpsCallable("checkTransactionStatus");
    const getTransactionStatus = props.firebase.functions.httpsCallable("getTransactionStatus");
    const [existingOrders, setexistingOrders] = useState([]);
    const [existingTransactions, setExistingTransactions] = useState([]);
    const [applicationMetaData, setApplicationMetaData] = useState({});
    const [isFirstTransaction, setIsFirstTransaction] = useState(true);

    useEffect(() => {
        //get current existing orders
        let orderId = params.orderId || props.orderId

        props.firebase.db.collection("applications").doc(params.id)
            .onSnapshot(function (doc) {
                console.log(doc.data())
                setApplicationMetaData(doc.data())
            });

        props.firebase.db.collection("orders").doc(orderId)
            .get()
            .then(function (order_doc) {
                setexistingOrders(order_doc.data())
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });

        props.firebase.db.collection("transactions").doc(orderId)
            .get()
            .then(function (transaction_doc) {
                setExistingTransactions(transaction_doc.data())
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });

        //real time updates using firebase snapshot
        props.firebase.db.collection("orders").doc(orderId)
            .onSnapshot(function (doc) {
                setexistingOrders(doc.data())
                console.log("Snapshot triggered!")
            });

        let arrayOfTransactions = []
        props.firebase.db.collection("transactions").where("transactionOrderId", "==", orderId).orderBy('createdDate')
            .onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    arrayOfTransactions.push(doc.data())
                });
                arrayOfTransactions.reverse()
                if (!isFirstTransaction)
                    setExistingTransactions(arrayOfTransactions)
                else
                    setIsFirstTransaction(false)
            })
    }, []);

    const chooseStatusPage = (orderStatus) => {
        if (orderStatus === "paid" && applicationMetaData.applicationPDFFileURL)
            return <TxnSuccess applicationMetaData={applicationMetaData} />
        else if ((existingTransactions && existingTransactions.length) > 0 && existingTransactions[0].transactionDetails.payload.payment.entity.status === 'failed')
            return <TxnFailure />
        else if ((orderStatus === "created" || orderStatus === "attempted") || (orderStatus === "paid" && !applicationMetaData.applicationPDFFileURL))
            return <TxnPending />
        else
            return
    }
    return (
        <section id="feature" className="feature-section feature-style-6">
            <div className="container">
                <div className="single-feature">
                    {existingOrders.createdOrderRazorpay && chooseStatusPage(existingOrders.createdOrderRazorpay.status)}
                </div>
            </div>
        </section>
    )
};

const condition = authUser => !!authUser;

const TransactionPageLink = () => <Link to={ROUTES.FORM_FILLING}>Form Filling</Link>;

const TransactionPageBase = compose(withFirebase)(TransactionPage)
const TransactionPageComponent = compose(withAuthorization(condition))(TransactionPageLayout);

export default TransactionPageComponent;

export { TransactionPageComponent, TransactionPageBase, TransactionPageLink };