import React from "react";

import { withFirebase } from "../Firebase";

const SignOutButton = ({ firebase }) => (
  <div className="small-buttons">
    <ul>
      <li>
        <a onClick={firebase.doSignOut} className="button button-sm radius-50">
          Sign Out
        </a>
      </li>
    </ul>
  </div>
  // <button type="button" onClick={firebase.doSignOut}>
  //   Sign Out
  // </button>
);

export default withFirebase(SignOutButton);
