export const FeesCalcFunc = (formMetaData, applicationType, serviceType, subType) => {
    if (applicationType == "FRESH PAN CARD" && serviceType == "INDIVIDUAL") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Fresh Individual")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "FRESH PAN CARD" && serviceType == "MINOR") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Fresh Minor")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "FRESH PAN CARD" && serviceType == "NON INDIVIDUAL") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Fresh Firm")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "FRESH PAN CARD" && serviceType == "NRI") && subType == "CARD DELIVERY TO INDIA") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Fresh NRI India")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "FRESH PAN CARD" && serviceType == "NRI") && subType == "CARD DELIVERY TO ABROAD") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Fresh NRI Abroad")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "FRESH PAN CARD" && serviceType == "FOREIGN NATIONAL") && subType == "CARD DELIVERY TO INDIA") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Fresh Foreign India")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "FRESH PAN CARD" && serviceType == "FOREIGN NATIONAL") && subType == "CARD DELIVERY TO ABROAD") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Fresh Foreign Abroad")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "MINOR TO MAJOR" && serviceType == "UPDATION") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint Individual")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "MINOR TO MAJOR" && serviceType == "CORRECTION") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction Individual")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "CORRECTIONS/ CHANGES" && serviceType == "INDIVIDUAL") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction Individual")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "CORRECTIONS/ CHANGES" && serviceType == "Minor") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction Minor")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "CORRECTIONS/ CHANGES" && serviceType == "Non Individal") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction Firm")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "CORRECTIONS/ CHANGES" && serviceType == "NRI") && subType == "CARD DELIVERY TO INDIA") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction NRI India")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "CORRECTIONS/ CHANGES" && serviceType == "NRI") && subType == "CARD DELIVERY TO ABROAD") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction NRI Abroad")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "CORRECTIONS/ CHANGES" && serviceType == "FOREIGN NATIONAL") && subType == "CARD DELIVERY TO INDIA") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction Foreign India")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "CORRECTIONS/ CHANGES" && serviceType == "FOREIGN NATIONAL") && subType == "CARD DELIVERY TO ABROAD") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Correction Foreign Abroad")
        return obj[0].amountToBePaid.amount
    }

    else if (applicationType == "REPRINT OF DAMAGED/ LOST" && serviceType == "INDIVIDUAL") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint Individual")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "REPRINT OF DAMAGED/ LOST" && serviceType == "Minor") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint Minor")
        return obj[0].amountToBePaid.amount
    }
    else if (applicationType == "REPRINT OF DAMAGED/ LOST" && serviceType == "Non Individal") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint Firm")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "REPRINT OF DAMAGED/ LOST" && serviceType == "NRI") && subType == "CARD DELIVERY TO INDIA") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint NRI India")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "REPRINT OF DAMAGED/ LOST" && serviceType == "NRI") && subType == "CARD DELIVERY TO ABROAD") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint NRI Abroad")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "REPRINT OF DAMAGED/ LOST" && serviceType == "FOREIGN NATIONAL") && subType == "CARD DELIVERY TO INDIA") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint Foreign India")
        return obj[0].amountToBePaid.amount
    }
    else if ((applicationType == "REPRINT OF DAMAGED/ LOST" && serviceType == "FOREIGN NATIONAL") && subType == "CARD DELIVERY TO ABROAD") {
        let obj = formMetaData.filter(item => item.formTypeNormal === "Reprint Foreign Abroad")
        return obj[0].amountToBePaid.amount
    }
    else {
        return 0
    }
}