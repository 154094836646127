import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import FormType from "./FormCategory";
import ApplicationType from "./ApplicationType-Material";
import TanApplication from "./TanForms";
import FreshApplication from "./FreshApplication";
import MinortoMajor from "./minorToMajor";
import CorrectionApplication from "./correctionApplication";
import ReprintApplication from "./reprintApplication";
import MakeCorrection from "./MakeCorrection";
import NonIndividual from "./NonIndividual";
import CorrectionNonIndividual from "./correctionNonIndividual";
import ReprintNonIndividual from "./reprintNonIndividual";
import NotifyCustomer from "./Notification";
import * as ROUTES from "../../constants/routes";
import * as COLORS from "../../constants/colors";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Loading from '../Loading';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: theme.spacing(1)
  }
}))


const ApplyFormLayout = () => (
  <AuthUserContext.Consumer>
    {authUser => <ApplyForm />}
  </AuthUserContext.Consumer>
);

const ApplyForm = props => {
  const classes = useStyles();
  const pageStartRef = useRef(null);

  const [applicationType, setapplicationType] = useState(null);
  const [formCategory, setFormCategory] = useState(null);
  const [nriForeignApplicationType, setNriForeignApplicationType] = useState(null);
  const [correctionApplicationType, setCorrectionApplicationType] = useState(null);
  const [applicationCategory, setapplicationCategory] = useState(null);
  const [applicationVariant, setapplicationVariant] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [activeColor, setActiveColor] = useState(null);
  const [choosenApplication, setChoosenApplication] = useState(null);

  useEffect(() => {
    document.title = `Applypan - Form Wizard`;

    setapplicationType(null)
    setapplicationCategory(null)
    setapplicationVariant(null)
    setChoosenApplication(null)
    setCorrectionApplicationType(null)

    if (formCategory === "panForm")
      setActiveColor(COLORS.CORRECTION_APPLICATION)
    else
      setActiveColor(COLORS.FRESH_APPLICATION)

  }, [formCategory]);

  useEffect(() => {
    document.title = `Applypan - Form Wizard`;

    setapplicationCategory(null)
    setapplicationVariant(null)
    setChoosenApplication(null)
    setCorrectionApplicationType(null)

    if (applicationType === "freshApplication")
      setActiveColor(COLORS.CORRECTION_APPLICATION)
    else
      setActiveColor(COLORS.FRESH_APPLICATION)

  }, [applicationType]);

  useEffect(() => {
    setapplicationCategory(null)
    setapplicationVariant(null)
  }, [correctionApplicationType]);

  if (pageLoading)
    return <Loading loadingMsg="creating application..." />
  return (
    <div>
      <Container color="variant" className={classes.mainContainer}>
        <div ref={pageStartRef}>
          <FormType
            setPageLoading={setPageLoading}
            onSelect={setFormCategory}
            formType={formCategory}
            activeColor={formCategory === 'panForm' ? COLORS.FRESH_APPLICATION : COLORS.CORRECTION_APPLICATION}
          />

          {
            formCategory == "panForm" ?
              (
                <ApplicationType
                  setPageLoading={setPageLoading}
                  onSelect={setapplicationType}
                  applicationType={applicationType}
                  activeColor={applicationType === 'freshApplication' ? COLORS.FRESH_APPLICATION : COLORS.CORRECTION_APPLICATION}
                />
              ) : ""
          }
          {
            formCategory == "tanForm" && (
              <>
                <TanApplication
                  setPageLoading={setPageLoading}
                  setapplicationVariant={setapplicationVariant}
                  applicationCategory={applicationCategory}
                  onSelect={setapplicationCategory}
                  choosenApplication={choosenApplication}
                  setChoosenApplication={setChoosenApplication}
                  activeColor={COLORS.FRESH_APPLICATION}
                />
                <hr />
              </>
            )
          }
        </div>

        {applicationType === "freshApplication" && (
          <>
            <FreshApplication
              setPageLoading={setPageLoading}
              setapplicationVariant={setapplicationVariant}
              applicationCategory={applicationCategory}
              onSelect={setapplicationCategory}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.FRESH_APPLICATION}
            />
            <hr />
          </>
        )}

        {applicationType === "minorToMajor" && (
          <>
            <MinortoMajor
              setPageLoading={setPageLoading}
              setapplicationVariant={setapplicationVariant}
              applicationCategory={applicationCategory}
              onSelect={setapplicationCategory}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.CORRECTION_APPLICATION}
            />
            <hr />
          </>
        )}

        {(applicationVariant === "nonIndividual" && applicationType === "freshApplication") && (
          <>
            <NonIndividual
              setPageLoading={setPageLoading}
              applicationCategory={applicationCategory}
              onSelect={setapplicationCategory}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.FRESH_APPLICATION}
            />
            <hr />
          </>
        )}

        {applicationType === "makeCorrection" && (
          <>
            <MakeCorrection
              setPageLoading={setPageLoading}
              onSelect={setCorrectionApplicationType}
              correctionApplicationType={correctionApplicationType}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.CORRECTION_APPLICATION}
            />
            <hr />
          </>
        )}

        {correctionApplicationType === "correction" && (
          <>
            <CorrectionApplication
              setPageLoading={setPageLoading}
              setapplicationVariant={setapplicationVariant}
              applicationCategory={applicationCategory}
              onSelect={setapplicationCategory}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.CORRECTION_APPLICATION}
            />
            <hr />
          </>
        )}

        {(applicationVariant === "correctionNonIndividual" && applicationType === "makeCorrection") && (
          <>
            <CorrectionNonIndividual
              setPageLoading={setPageLoading}
              applicationCategory={applicationCategory}
              onSelect={setapplicationCategory}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.CORRECTION_APPLICATION}
            />
            <hr />
          </>
        )}

        {correctionApplicationType === "reprint" && (
          <>
            <ReprintApplication
              setPageLoading={setPageLoading}
              setapplicationVariant={setapplicationVariant}
              applicationCategory={applicationCategory}
              onSelect={setapplicationCategory}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.CORRECTION_APPLICATION}
            />
            <hr />
          </>
        )}

        {(applicationVariant === "reprintNonIndividual" && applicationType === "makeCorrection") && (
          <>
            <ReprintNonIndividual
              setPageLoading={setPageLoading}
              applicationCategory={applicationCategory}
              onSelect={setapplicationCategory}
              choosenApplication={choosenApplication}
              setChoosenApplication={setChoosenApplication}
              activeColor={COLORS.CORRECTION_APPLICATION}
            />
            <hr />
          </>
        )}

        {applicationType && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} style={{ margin: "2% 0px 10% 39%" }}>
            </Grid>
          </Grid>
        )}

      </Container>
    </div>
  );
};

const condition = authUser => !!authUser;

const ApplyNowLink = () => <Link to={ROUTES.APPLY_FORM}>Apply Now</Link>;

const ApplyFormPage = compose(withAuthorization(condition))(ApplyFormLayout);

export default ApplyFormPage;

export { ApplyFormPage, ApplyNowLink };
