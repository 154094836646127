import * as Yup from 'yup';

const Schema = Yup.object().shape({
    chooseAddress: Yup.string().required("Choose the Address for Communication"),

    residenceAddressArea: Yup.string().max(25,
        "Must be 25 characters or less").nullable().required("Residence Area is required"),
    residenceAddressBuilding: Yup.string().max(25,
        "Must be 25 characters or less").nullable().required("Residence Building is required"),
    residenceAddressCity: Yup.string().max(25,
        "Must be 25 characters or less").nullable().required("Residence City is required"),
    // residenceAddressCountry: Yup.string().max(25,
    //     "Must be 25 characters or less").nullable().required("Residence Country is required"),
    residenceAddressFlat: Yup.string().max(25,
        "Must be 25 characters or less").nullable().required("Residence Flat is required"),
    residenceAddressPincode: Yup.string().nullable()
        .required("Pincode is required")
        .min(7, "Invalid Pincode")
        .max(7, "Invalid Pincode"),
    residenceAddressRoad: Yup.string().max(25,
        "Must be 25 characters or less").nullable().required("Residence Road is required"),
    residenceAddressState: Yup.string().max(25,
        "Must be 25 characters or less").nullable().required("Residence State is required"),

    officeAddressArea: Yup.string().when('chooseAddress',
        {
            is: (val) => val === 'office',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Area is required"),
        }
    ).when('incomeSource',
        {
            is: (val) => val === 'salary',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Area is required as income source is selcted as salary"),
        }
    ).nullable(),
    officeAddressBuilding: Yup.string().max(25,
        "Must be 25 characters or less").when('chooseAddress',
            {
                is: (val) => val === 'office',
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Building is required"),
            }
        ).when('incomeSource',
            {
                is: (val) => val === 'salary',
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Building is required as income source is selcted as salary"),
            }
        ).nullable(),
    officeAddressCity: Yup.string().max(25,
        "Must be 25 characters or less").when('chooseAddress',
            {
                is: (val) => val === 'office',
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office City is required"),
            }
        ).when('incomeSource',
            {
                is: (val) => val === 'salary',
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office City is required as income source is selcted as salary"),
            }
        ).nullable(),
    officeAddressCountry: Yup.string().when('chooseAddress',
        {
            is: (val) => val === 'office',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Country is required"),
        }
    ).when('incomeSource',
        {
            is: (val) => val === 'salary',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Country is required as income source is selcted as salary"),
        }
    ).nullable(),
    officeAddressFlat: Yup.string().when('chooseAddress',
        {
            is: (val) => val === 'office',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Flat is required"),
        }
    ).when('incomeSource',
        {
            is: (val) => val === 'salary',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Flat is required as income source is selcted as salary"),
        }
    ).nullable(),
    officeAddressRoad: Yup.string().when('chooseAddress',
        {
            is: (val) => val === 'office',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Road is required"),
        }
    ).when('incomeSource',
        {
            is: (val) => val === 'salary',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office Road state is required as income source is selcted as salary"),
        }
    ).nullable(),
    officeAddressState: Yup.string().when('chooseAddress',
        {
            is: (val) => val === 'office',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office state is required"),
        }
    ).when('incomeSource',
        {
            is: (val) => val === 'salary',
            then: Yup.string().max(25,
                "Must be 25 characters or less").required("Office location state is required as income source is selcted as salary"),
        }
    ).nullable(),
    officeAddressPincode: Yup.string().when('chooseAddress',
        {
            is: (val) => val === 'office',
            then: Yup.string().required("Office Pin Code is required").min(7, "Invalid Pincode")
                .max(7, "Invalid Pincode"),
        }
    ).when('incomeSource',
        {
            is: (val) => val === 'salary',
            then: Yup.string().required("Office Postal code is required as income source is selcted as salary")
                .min(7, "Invalid Pincode")
                .max(7, "Invalid Pincode"),
        }
    ).nullable(),

    emailAddress: Yup.string().nullable().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "we can contact you only via a valid mail Id").required('Email ID is required for mailing you the document').email('we can contact you only via a valid mail Id'),
    mobileNumber: Yup.string().nullable().required('Mobile number is required').matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        "Please share us a valid number")
});

export default Schema;