const fathersArray = ['fathersLastName', 'fathersFirstName', 'fathersMiddleName']
const mothersArray = ['mothersLastName', 'mothersFirstName', 'mothersMiddleName']
const nickNameArray = ['nickLastName', 'nickFirstName', 'nickMiddleName']
const representativeNameArray = ["representativeTitleOnName", "representativeLastName", "representativeFirstName", "representativeMiddleName"]
const representativeAddressArray = ["representativeAddressFlat", "representativeAddressRoad", "representativeAddressArea", "representativeAddressVillage", "representativeAddressPincode", "representativeAddressCity", "representativeAddressState", "representativeAddressCountry"]
const officeAddressArray = ["officeAddressBuilding", "officeAddressFlat", "officeAddressVillage", "officeAddressRoad", "officeAddressArea", "officeAddressPincode", "officeAddressCity", "officeAddressState", "officeAddressCountry"]

export const removeCorrectionNotRequired = (formValues) => {
    if(formValues.isParentNameCorrection === true){
        formValues.parentsName = ""
        formValues.singleParent = ""
        if(formValues.isWhoseName === true){
            formValues.fathersNameChange = ""
            fathersArray.map((fathersName)=>{
                formValues[fathersName] = ""
            })
        }
        else{
            formValues.mothersNameChange = ""
            mothersArray.map((mothersName)=>{
                formValues[mothersName] = ""
            })
        }
    }
    else{
        if (formValues.parentsName === "fathersName") {
            mothersArray.map((motherName) => {
                formValues[motherName] = ""
            })
        }
    
        if (formValues.parentsName === "mothersName" && formValues.singleParent === "yes") {
            fathersArray.map((fatherName) => {
                formValues[fatherName] = ""
            })
        }
    }

    if(formValues.aadhaarCard == "no"){
        formValues.aadhaarNumber = ""
    }

    return formValues;
}