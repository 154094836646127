import React, { useEffect, useRef, useState } from "react";
import {
    SubVariantItem,
    ItemSeperator,
    ShrinkedItem
} from "./SharedComponents";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import { compose } from "recompose";
import { useHistory } from "react-router-dom";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ChooseApplication from './chooseApplication';

const MinorToMajorPage = (props) => (
    <AuthUserContext.Consumer>
        {authUser => <MinorToMajor authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const MinorToMajorBase = props => {
    const viewRef = useRef(null);
    let history = useHistory();
    const [applicationId, setApplicationId] = useState(null);
    const [displayName, setDisplayName] = useState();

    const BASE_STYLE = {
        borderLeft: `5px solid ${COLORS.DEFAULT_BORDER_COLOR}`,
        cursor: "pointer",
        padding: "20px 35px",
        height: "90%",
        marginBottom: "25px",
        fontSize: "10px"
    };

    useEffect(() => {
        console.log(props)
        viewRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    const continueToFillingForm = () => {
        const createApplicantFormDetails = props.firebase.functions.httpsCallable(
            "createApplicantFormDetails"
        );
        props.setPageLoading(true)

        createApplicantFormDetails({
            uid: props.authUser.uid,
            formType: props.choosenApplication
        }).then(result => {
            setApplicationId(result.data.documentId);
            props.setPageLoading(false)
            history.push({
                pathname: ROUTES.FORM_FILLING,
                search: "?id=" + result.data.documentId + "&formType=" + props.choosenApplication
            });
        });
    }

    const onFormVariantSelect = (formVariant, displayName) => {
        props.setapplicationVariant(null)
        props.onSelect(formVariant)
        props.setChoosenApplication(formVariant)
        setDisplayName(displayName)
    };
    const data = [
        {
            name: "minorToMajorUpdation",
            caption: "UPDATION - Only Photo and Sign Updation",
            displayName: "Updation Individual",
            details: "If you are an individual and your age is 18+",
            imgsrc: "assets/img/applywizard/correction/reprintForm.svg",
            onSelect: onFormVariantSelect
        },
        {
            name: "minorToMajorCorrection",
            caption: "CORRECTION - Changes or Correction in Datas",
            displayName: "Correction Individual",
            details:
                "If you are applying PAN card your child whoose age is less than 18",
            imgsrc: "assets/img/applywizard/correction/correctionForm.svg",
            onSelect: onFormVariantSelect
        }
    ];

    const MinorToMajorItems = data.map(i => {
        return (

            <ShrinkedItem
                name={i.name}
                active={i.name == props.applicationCategory}
                caption={i.caption}
                displayName={i.displayName}
                details={i.details}
                onSelect={i.onSelect}
                styles={BASE_STYLE}
                imgsrc={i.imgsrc}
                colSize={4}
                bgColor={COLORS.CORRECTION_APPLICATION}
                activeColor={props.activeColor}
            />
        );
    });

    var currentApplicationTypes = ["minorToMajorUpdation", "minorToMajorCorrection"]

    return (
        <div>
            <div className="section-title mb-10 mt-10" ref={viewRef}>
                <Typography variant="h6" component="h6">
                    How do you want to apply?
                </Typography>
            </div>
            <Grid container style={{ display: "flex", justifyContent: "row" }}>
                {MinorToMajorItems}
            </Grid>
            {
                currentApplicationTypes.includes(props.choosenApplication) && (
                    <ChooseApplication
                        choosenApplication={props.choosenApplication}
                        displayName={displayName}
                        continueToFillingForm={continueToFillingForm}
                        activeColor={props.activeColor}
                        setChoosenApplication={props.setChoosenApplication}
                    />
                )
            }
            <div>{applicationId}</div>
        </div>
    );
};

const MinorToMajor = compose(withFirebase)(MinorToMajorBase);

export default MinorToMajorPage;