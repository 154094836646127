import React, { useEffect, useRef, useState, } from "react";
import {
    SubVariantItem,
    ItemSeperator,
    ShrinkedItem
} from "./SharedComponents";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import { compose } from "recompose";
import { useHistory } from "react-router-dom";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ChooseApplication from './chooseApplication';

const NonIndividualPage = (props) => (
    <AuthUserContext.Consumer>
        {authUser => <NonIndividual authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const NonIndividualBase = props => {
    const viewRef = useRef(null);
    let history = useHistory();
    const [applicationId, setApplicationId] = useState(null);
    const [displayName, setDisplayName] = useState();
    const [nriForeignData, setNriForeignData] = useState("");


    useEffect(() => {
        setNriForeignData("")
    }, [props.applicationCategory])

    const BASE_STYLE = {
        borderLeft: `5px solid ${COLORS.DEFAULT_BORDER_COLOR}`,
        cursor: "pointer",
        padding: "20px 35px",
        height: "90%",
        marginBottom: "25px",
        fontSize: "10px"
    };

    useEffect(() => {
        viewRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    var data = ""

    if (props.applicationCategory == "nri") {
        data = "nriData"
    }
    else if (props.applicationCategory == "foreign") {
        data = "foreignData"
    }
    else {
        data = "nonIndividualdata"
    }

    const continueToFillingForm = () => {
        const createApplicantFormDetails = props.firebase.functions.httpsCallable(
            "createApplicantFormDetails"
        );
        props.setPageLoading(true)

        createApplicantFormDetails({
            uid: props.authUser.uid,
            formType: props.choosenApplication
        }).then(result => {
            setApplicationId(result.data.documentId);
            props.setPageLoading(false)
            history.push({
                pathname: ROUTES.FORM_FILLING,
                search: "?id=" + result.data.documentId + "&formType=" + props.choosenApplication
            });
        });
    }

    const onFormVariantSelect = (formVariant, displayName) => {
        props.setChoosenApplication(formVariant)
        setDisplayName(displayName)
    };

    const onNriForeignSelect = (formVariant, displayName) => {
        if (formVariant == "freshNriIndividual") {
            setNriForeignData("nriIndividual")
        }
        else if (formVariant == "freshNriMinor") {
            setNriForeignData("nriMinor")
        }
        else if(formVariant == "freshForeignIndividual"){
            setNriForeignData("foreignIndividual")
        }
        else if(formVariant == "freshForeignMinor") {
            setNriForeignData("foreignMinor")
        }
    }

    const showData = {
        nonIndividualdata: [
            {
                name: "freshHuf",
                caption: "HUF",
                displayName: "Fresh HUF",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Huf.svg"
            },
            {
                name: "freshFirm",
                caption: "Firm",
                displayName: "Fresh Firm",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Firm.svg"
            },
            {
                name: "freshTrust",
                caption: "Trust",
                displayName: "Fresh Trust",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Trust.svg"
            },
            {
                name: "freshAop",
                caption: "Association of Persons(AOP)",
                displayName: "Fresh Association of Persons(AOP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Aop.svg"
            },
            {
                name: "freshLlp",
                caption: "Limited Liability Partnership(LLP)",
                displayName: "Fresh Limited Liability Partnership(LLP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Llp.svg"
            },
            {
                name: "freshBoi",
                caption: "Body of Individuals(BOI)",
                displayName: "Fresh Body of Individuals(BOI)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Boi.svg"
            },
            {
                name: "freshLocalAuthority",
                caption: "Local Authority",
                displayName: "Fresh Local Authority",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Local Authority.svg"
            },
            {
                name: "freshAjp",
                caption: "Artificial Juridicial Person(AJP)",
                displayName: "Fresh Artificial Juridicial Person(AJP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual Ajp.svg"
            },
            {
                name: "freshGovernment",
                caption: "Government",
                displayName: "Fresh Government",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/freshNonIndividual/Non Individual govt.svg"
            }
        ],
        nriData: [
            {
                name: "freshNriIndividual",
                caption: "NRI Individual",
                displayName: "NRI Individual",
                onSelect: onNriForeignSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/Individual.svg"
            },
            {
                name: "freshNriMinor",
                caption: "NRI Minor",
                displayName: "NRI Minor",
                onSelect: onNriForeignSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/Minor.svg"
            }
        ],
        nriIndividual: [
            {
                name: "freshNriIndia",
                caption: "Card Delivery to India",
                displayName: "Fresh NRI India",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            },
            {
                name: "freshNriAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "Fresh NRI Abroad",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            }
        ],
        nriMinor: [
            {
                name: "freshMinorNriIndia",
                caption: "Card Delivery to India",
                displayName: "Fresh NRI India",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            },
            {
                name: "freshMinorNriAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "Fresh NRI Abroad",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            }
        ],
        foreignData: [
            {
                name: "freshForeignIndividual",
                caption: "Foreign Individual",
                displayName: "Foreign Individual",
                onSelect: onNriForeignSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/Individual.svg"
            },
            {
                name: "freshForeignMinor",
                caption: "Foreign Minor",
                displayName: "Foreign Minor",
                onSelect: onNriForeignSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/Minor.svg"
            }
        ],
        foreignIndividual: [
            {
                name: "freshForeignAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "Fresh Foreign Abroad",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            },
            {
                name: "freshForeignIndia",
                caption: "Card Delivery to India",
                displayName: "Fresh Foreign India",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            }
        ],
        foreignMinor: [
            {
                name: "freshMinorForeignAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "Fresh Foreign Abroad",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            },
            {
                name: "freshMinorForeignIndia",
                caption: "Card Delivery to India",
                displayName: "Fresh Foreign India",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            }
        ]
    }

    const NonIndividualItems = showData[data].map(i => {
        return (
            <ShrinkedItem
                name={i.name}
                active={i.name === props.choosenApplication}
                caption={i.caption}
                displayName={i.displayName}
                details={i.details}
                onSelect={i.onSelect}
                styles={BASE_STYLE}
                imgsrc={i.imgsrc}
                colSize={3}
                bgColor={COLORS.FRESH_APPLICATION}
                activeColor={props.activeColor}
            />
        );
    });

    var nriForeignItems;

    if (showData[nriForeignData]) {
        nriForeignItems = showData[nriForeignData].map(i => {
            console.log(i)
            return (
                <ShrinkedItem
                    name={i.name}
                    active={i.name === props.choosenApplication}
                    caption={i.caption}
                    displayName={i.displayName}
                    details={i.details}
                    onSelect={i.onSelect}
                    styles={BASE_STYLE}
                    imgsrc={i.imgsrc}
                    colSize={3}
                    bgColor={COLORS.FRESH_APPLICATION}
                    activeColor={props.activeColor}
                />
            );
        });
    }

    var currentApplicationTypes = ["freshHuf", "freshFirm", "freshTrust", "freshAop",
        "freshLlp", "freshBoi", "freshLocalAuthority", "freshAjp",
        "freshGovernment", "freshNriIndia", "freshNriAbroad", "freshMinorNriIndia", "freshMinorNriAbroad", "freshForeignAbroad",
        "freshForeignIndia", "freshMinorForeignAbroad", "freshMinorForeignIndia"]

    return (
        <div>
            <div className="section-title mb-10 mt-10" ref={viewRef}></div>
            <Grid container style={{ display: "flex", justifyContent: "row" }}>
                {NonIndividualItems}
            </Grid>
            <Grid container style={{ display: "flex", justifyContent: "row" }}>
                {nriForeignItems}
            </Grid>
            {
                currentApplicationTypes.includes(props.choosenApplication) && (
                    <ChooseApplication
                        choosenApplication={props.choosenApplication}
                        displayName={displayName}
                        continueToFillingForm={continueToFillingForm}
                        activeColor={props.activeColor}
                        setChoosenApplication={props.setChoosenApplication}
                    />
                )
            }
        </div>
    );
};

const NonIndividual = compose(withFirebase)(NonIndividualBase);

export default NonIndividualPage;