import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grow from '@material-ui/core/Grow';
import * as COLORS from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 72,
    height: 72,
  },
  applicationCard: {
    height: "60px",
    display: "flex",
    justifyContent: "row",
    borderRadius: "50px",
    margin: "10px",
    cursor: "pointer",
    backgroundColor: props.activeColor,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  formTitle: {
    marginTop: "20px",
    marginLeft: "10px",
    fontSize: 14,
    fontWeight: 600
  }
}));
const Item = props => {
  return (
    <div
      className="single-feature"
      style={props.styles}
      onClick={() => {
        props.onSelect(props.name);
      }}
    >
      <div className="text-centre">
        <h6 className="font-weight-medium mb-25">{props.caption}</h6>
        <div className="image">
          <div style={{ width: "129px", height: "129px" }}>
            <img src={props.imgsrc} alt="" />
          </div>
        </div>
        <p className="mb-15">{props.details}</p>
        <p style={{ color: "#D95204" }}>{props.warning}</p>
      </div>
    </div>
  );
};

const ShrinkedItem = props => {
  const useStyles = makeStyles((theme) => ({
    image: {
      width: 72,
      height: 72,
    },
    applicationCard: {
      height: "60px",
      display: "flex",
      justifyContent: "row",
      borderRadius: "50px",
      margin: "10px",
      cursor: "pointer",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      "&:hover": {
        backgroundColor: props.bgColor,
        color: props.bgColor === COLORS.FRESH_APPLICATION ? "white" : COLORS.FRESH_APPLICATION
      }
    },
    formTitle: {
      marginTop: "20px",
      marginLeft: "10px",
      fontSize: 14,
      fontWeight: 600
    }
  }));
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={props.colSize} style={{ margin: "auto" }}>
      <Grow
        in={true}
        style={{ transformOrigin: '0 0 0' }}
        {...(true ? { timeout: props.disableAnimation ? 0 : 1000 } : {})}
      >
        <Grid containter className={classes.applicationCard}
          style={{
            backgroundColor: props.active ? props.activeColor :
              "",
            color: props.active ?
              props.activeColor === COLORS.FRESH_APPLICATION ? "white" : COLORS.FRESH_APPLICATION
              :
              ""
          }}
          onClick={() => {
            props.onSelect(props.name, props.displayName);
          }}
        >
          <Grid row item xs={4} sm={4} >
            <div style={{ width: 60 }}>
              <img className={classes.img} alt="complex" src={props.imgsrc} />
            </div>
          </Grid>
          <Grid row item xs={8} sm={8}>
            <Typography className={classes.formTitle} variant="h5" component="h5">
              {props.caption}
            </Typography>
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );
};

const SubVariantItem = props => {
  return (
    <div
      className="single-feature"
      style={props.styles}
      onClick={() => {
        props.onSelect(props.name);
      }}
    >
      <h6 className="font-weight-medium mb-3" style={{ textAlign: "left" }}>
        {props.caption}
      </h6>
      <div style={{ display: "flex" }}>
        <div className="image">
          <div
            style={{ width: "72px", height: "72px" }}
            className="align-self-center"
          >
            <img src={props.imgsrc} alt="" />
          </div>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <div className="text-centre">
            <p className="mb-1 text-align-left" style={{ textAlign: "left" }}>
              {props.details}
            </p>
            <p style={{ color: "#D95204" }}>{props.warning}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemSeperator = () => {
  return <h6>or</h6>;
};

export { Item, ItemSeperator, SubVariantItem, ShrinkedItem };
