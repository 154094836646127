import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATMLTMcjsGHrmG8GntoDvchDWRM8Fjo6A",
  authDomain: "applypan-landing.firebaseapp.com",
  databaseURL: "https://applypan-landing-default-rtdb.firebaseio.com",
  projectId: "applypan-landing",
  storageBucket: "applypan-landing.appspot.com",
  messagingSenderId: "456384047605",
  appId: "1:456384047605:web:62003d520661ff793fd8bc",
  measurementId: "G-T8HTYHL6M2"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.authProv = app.auth;
    this.auth = app.auth();
    // this.db = app.database();
    this.db = app.firestore();
    this.functions = app.functions();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doCreateUserWithMobileNumber = (mobileNo, appVerifier) =>
    this.auth.signInWithPhoneNumber(mobileNo, appVerifier)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithMobileNumber = (mobileNumber, appVerifier) =>
    this.auth.signInWithPhoneNumber(mobileNumber, appVerifier)

  PhoneAuthProvider = () =>
    this.auth.PhoneAuthProvider

  signInWithCredential = (credential) =>
    this.auth.signInWithCredential(credential)

  doVerifyRecaptcha = () => {
    window.recaptchaVerifier = new app.auth.RecaptchaVerifier("recaptcha-container")
  }

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** User API ***

  // user = uid => this.db.ref(`users/${uid}`);
  user = uid => this.db.doc(`users/${uid}`);

  // users = () => this.db.ref("users");
  users = () => this.db.doc("users");
}

export default Firebase;
