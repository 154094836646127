import React, { useEffect, useRef, useState } from "react";
import {
    SubVariantItem,
    ItemSeperator,
    ShrinkedItem
} from "./SharedComponents";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import { compose } from "recompose";
import { useHistory } from "react-router-dom";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ChooseApplication from './chooseApplication';

const CorrectionNonIndividualPage = (props) => (
    <AuthUserContext.Consumer>
        {authUser => <CorrectionNonIndividual authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const CorrectionNonIndividualBase = props => {
    const viewRef = useRef(null);
    let history = useHistory();
    const [applicationId, setApplicationId] = useState(null);
    const [displayName, setDisplayName] = useState();
    const [nriForeignData, setNriForeignData] = useState("");

    useEffect(() => {
        setNriForeignData("")
    }, [props.applicationCategory])

    const BASE_STYLE = {
        borderLeft: `5px solid ${COLORS.DEFAULT_BORDER_COLOR}`,
        cursor: "pointer",
        padding: "20px 35px",
        height: "90%",
        marginBottom: "25px",
        fontSize: "10px"
    };

    useEffect(() => {
        viewRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    const continueToFillingForm = () => {
        const createApplicantFormDetails = props.firebase.functions.httpsCallable(
            "createApplicantFormDetails"
        );
        props.setPageLoading(true)

        createApplicantFormDetails({
            uid: props.authUser.uid,
            formType: props.choosenApplication
        }).then(result => {
            setApplicationId(result.data.documentId);
            props.setPageLoading(false)
            history.push({
                pathname: ROUTES.FORM_FILLING,
                search: "?id=" + result.data.documentId + "&formType=" + props.choosenApplication
            });
        });
    }

    const onFormVariantSelect = (formVariant, displayName) => {
        props.setChoosenApplication(formVariant)
        setDisplayName(displayName)
    };

    const onNriForeignSelect = (formVariant, displayName) => {
        console.log(formVariant)
        if (formVariant == "correctionNriIndividual") {
            setNriForeignData("nriIndividual")
        }
        else if (formVariant == "correctionNriMinor") {
            setNriForeignData("nriMinor")
        }
        else if (formVariant == "correctionForeignIndividual") {
            setNriForeignData("foreignIndividual")
        }
        else if (formVariant == "correctionForeignMinor") {
            setNriForeignData("foreignMinor")
        }
    }

    const showData = {
        CorrectionNonIndividualdata: [
            {
                name: "correctionHuf",
                caption: "HUF",
                displayName: "Correction HUF",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Huf.svg"
            },
            {
                name: "correctionFirm",
                caption: "Firm",
                displayName: "Correction Firm",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Firm.svg"
            },
            {
                name: "correctionTrust",
                caption: "Trust",
                displayName: "Correction Trust",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Trust.svg"
            },
            {
                name: "correctionAop",
                caption: "Association of Persons(AOP)",
                displayName: "Correction Association of Persons(AOP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Aop.svg"
            },
            {
                name: "correctionLlp",
                caption: "Limited Liability Partnership(LLP)",
                displayName: "Correction Limited Liability Partnership(LLP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Llp.svg"
            },
            {
                name: "correctionBoi",
                caption: "Body of Individuals(BOI)",
                displayName: "Correction Body of Individuals(BOI)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Boi.svg"
            },
            {
                name: "correctionLocalAuthority",
                caption: "Local Authority",
                displayName: "Correction Local Authority",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Local Authority.svg"
            },
            {
                name: "correctionAjp",
                caption: "Artificial Juridicial Person(AJP)",
                displayName: "Correction Artificial Juridicial Person(AJP)",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Ajp.svg"
            },
            {
                name: "correctionGovernment",
                caption: "Government",
                displayName: "Correction Government",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Govt.svg"
            },
            {
                name: "correctionCompany",
                caption: "Company",
                displayName: "Correction Company",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card as a non individual",
                imgsrc: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Company.svg"
            },
        ],
        nriData: [
            {
                name: "correctionNriIndividual",
                caption: "NRI Individual",
                displayName: "NRI Individual",
                onSelect: onNriForeignSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/Individual.svg"
            },
            {
                name: "correctionNriMinor",
                caption: "NRI Minor",
                displayName: "NRI Minor",
                onSelect: onNriForeignSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/Minor.svg"
            }
        ],
        nriIndividual: [
            {
                name: "correctionNriIndia",
                caption: "Card Delivery to India",
                displayName: "correction NRI India",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            },
            {
                name: "correctionNriAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction NRI Abroad",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            }
        ],
        nriMinor: [
            {
                name: "correctionMinorNriIndia",
                caption: "Card Delivery to India",
                displayName: "correction NRI India",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            },
            {
                name: "correctionMinorNriAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction NRI Abroad",
                onSelect: onFormVariantSelect,
                details: "If you are applying PAN card and residing outside India",
                imgsrc: "assets/img/applywizard/nri.svg"
            }
        ],
        foreignData: [
            {
                name: "correctionForeignIndividual",
                caption: "Foreign Individual",
                displayName: "Foreign Individual",
                onSelect: onNriForeignSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/Individual.svg"
            },
            {
                name: "correctionForeignMinor",
                caption: "Foreign Minor",
                displayName: "Foreign Minor",
                onSelect: onNriForeignSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/Minor.svg"
            }
        ],
        foreignIndividual: [
            {
                name: "correctionForeignAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction Foreign Abroad",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            },
            {
                name: "correctionForeignIndia",
                caption: "Card Delivery to India",
                displayName: "correction Foreign India",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            }
        ],
        foreignMinor: [
            {
                name: "correctionMinorForeignAbroad",
                caption: "Card Delivery to Abroad",
                displayName: "correction Foreign Abroad",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            },
            {
                name: "correctionMinorForeignIndia",
                caption: "Card Delivery to India",
                displayName: "correction Foreign India",
                onSelect: onFormVariantSelect,
                details:
                    "If you are applying PAN card in India but a citizen of a national other than India",
                imgsrc: "assets/img/applywizard/foreign.svg"
            }
        ]
    }

    var data = ""

    if (props.applicationCategory == "nri") {
        data = "nriData"
    }
    else if (props.applicationCategory == "foreign") {
        data = "foreignData"
    }
    else {
        data = "CorrectionNonIndividualdata"
    }

    const CorrectionNonIndividualItems = showData[data].map(i => {
        return (
            <ShrinkedItem
                name={i.name}
                active={i.name === props.choosenApplication}
                caption={i.caption}
                displayName={i.displayName}
                details={i.details}
                onSelect={i.onSelect}
                styles={BASE_STYLE}
                imgsrc={i.imgsrc}
                colSize={3}
                bgColor={COLORS.CORRECTION_APPLICATION}
                activeColor={props.activeColor}
            />
        );
    });

    var nriForeignItems;

    if (showData[nriForeignData]) {
        nriForeignItems = showData[nriForeignData].map(i => {
            console.log(i)
            return (
                <ShrinkedItem
                    name={i.name}
                    active={i.name === props.choosenApplication}
                    caption={i.caption}
                    displayName={i.displayName}
                    details={i.details}
                    onSelect={i.onSelect}
                    styles={BASE_STYLE}
                    imgsrc={i.imgsrc}
                    colSize={3}
                    bgColor={COLORS.FRESH_APPLICATION}
                    activeColor={props.activeColor}
                />
            );
        });
    }

    var currentApplicationTypes = ["correctionHuf", "correctionFirm", "correctionTrust", "correctionAop",
        "correctionLlp", "correctionBoi", "correctionLocalAuthority", "correctionAjp",
        "correctionGovernment", "correctionNriIndia", "correctionNriAbroad", "correctionMinorNriIndia", "correctionMinorNriAbroad", "correctionForeignAbroad",
        "correctionForeignIndia", "correctionMinorForeignAbroad", "correctionMinorForeignIndia"]
        
    return (
        <div>
            <div className="section-title mb-10 mt-10" ref={viewRef}></div>
            <Grid container style={{ display: "flex", justifyContent: "row" }}>
                {CorrectionNonIndividualItems}
            </Grid>
            <Grid container style={{ display: "flex", justifyContent: "row" }}>
                {nriForeignItems}
            </Grid>
            {
                currentApplicationTypes.includes(props.choosenApplication) && (
                    <ChooseApplication
                        choosenApplication={props.choosenApplication}
                        displayName={displayName}
                        continueToFillingForm={continueToFillingForm}
                        activeColor={props.activeColor}
                        setChoosenApplication={props.setChoosenApplication}
                    />
                )
            }
        </div>
    );
};

const CorrectionNonIndividual = compose(withFirebase)(CorrectionNonIndividualBase);

export default CorrectionNonIndividualPage;