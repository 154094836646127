import React, { useEffect, useLayoutEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import { compose, mapProps } from "recompose";
import { AuthUserContext, withAuthorization } from "../Session";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import SortIcon from '@material-ui/icons/Sort';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import { FeesCalcFunc } from './feesCalcFunc';

const useStyles = makeStyles((theme) => ({
    paper: {
        flexGrow: 1,
        backgroundColor: "white",
        minHeight: 550,
        bFormRadius: theme.spacing(2, 2, 0, 0),
        padding: theme.spacing(5, 10, 0, 5),
    },
    heading: {
        marginLeft: theme.spacing(35),
        marginBottom: theme.spacing(2),
        fontSize: 34
    },
    selectControl: {
        margin: theme.spacing(1),
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "pre"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    dropdownTitle: {
        fontSize: 14
    },
    tableContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: theme.spacing(2)
    },
    leftTable: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "skyblue",
        margin: 5
    },
    rightTable: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "skyblue",
        margin: 5
    },
    rightTableContent: {
        marginLeft: 15
    },
    infoFields: {
        fontSize: 16,
        fontWeight: 400,
        margin: theme.spacing(0.5, 0, 0.5, 0),
        padding: 5
    },
    tableFields: {
        fontSize: 16,
        fontWeight: 400,
        margin: theme.spacing(0.5, 0, 0.5, 0),
        backgroundColor: "#efeded",
        padding: 5
    },
    infoDetails: {
        fontSize: 16,
        fontWeight: 400,
        color: "gray",
        margin: theme.spacing(0.5, 0, 0.5, 0),
        padding: 5
    },
    tableDetails: {
        fontSize: 16,
        fontWeight: 400,
        backgroundColor: "#efeded",
        color: "gray",
        margin: theme.spacing(0.5, 0, 0.5, 0),
        padding: 5
    }
}));

const FeesCalculatorPage = () => (
    <AuthUserContext.Consumer>
        {authUser => <FeesCalculator authUser={authUser} />}
    </AuthUserContext.Consumer>
);

const FeesCalculatorBase = props => {

    const classes = useStyles();

    const [existingForms, setExistingForms] = useState();
    const [formMetaData, setFormMetaData] = useState();
    const [currentForm, setCurrentForm] = useState({});
    const [currentAmount, setCurrentAmount] = useState();

    useEffect(() => {
        let arrayOfForms = []
        let arrayOfMetaData = []
        //  Bring all Forms for current application
        props.firebase.db.collection("forms")
            .onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    arrayOfForms.push(doc.data().formTypeNormal)
                    arrayOfMetaData.push(doc.data())
                });
                setExistingForms(arrayOfForms)
                setFormMetaData(arrayOfMetaData)
            })
    }, [])

    // useEffect(() => {
    //     if (formMetaData) {
    //         let obj = formMetaData.filter(item => item.formTypeNormal === currentForm)
    //         setCurrentAmount(obj[0].amountToBePaid);
    //     }
    // }, [currentForm])

    useEffect(() => {
        let amount = 0;
        amount = FeesCalcFunc(formMetaData, currentForm.applicationsDropDown, currentForm.serviceDropDown, currentForm.subServiceDropDown)
        setCurrentAmount(amount)
    }, [currentForm])

    const printApplicationOptions = () => {
        let arrayOfOptions = [];
        let applicationArray = ["FRESH PAN CARD", "MINOR TO MAJOR", "CORRECTIONS/ CHANGES", "REPRINT OF DAMAGED/ LOST"]
        for (let field of applicationArray) {
            arrayOfOptions.push(
                <MenuItem value={field}>{field}</MenuItem>
            )
        }
        return arrayOfOptions
    }

    const printServiceOptions = () => {
        let arrayOfOptions = [];
        var applicationArray = [];

        if (currentForm.applicationsDropDown == "MINOR TO MAJOR")
            applicationArray = ["UPDATION", "CORRECTION"]
        else
            applicationArray = ["INDIVIDUAL", "MINOR", "NON INDIVIDUAL", "NRI", "FOREIGN NATIONAL"]


        for (let field of applicationArray) {
            arrayOfOptions.push(
                <MenuItem value={field}>{field}</MenuItem>
            )
        }
        return arrayOfOptions
    }

    const printSubServiceOptions = () => {
        let arrayOfOptions = [];
        let applicationArray = ["CARD DELIVERY TO INDIA", "CARD DELIVERY TO ABROAD"]
        for (let field of applicationArray) {
            arrayOfOptions.push(
                <MenuItem value={field}>{field}</MenuItem>
            )
        }
        return arrayOfOptions
    }

    const handleChange = (e) => {
        setCurrentForm(currentForm => ({
            ...currentForm, [e.target.name]: e.target.value
        }))
    }

    if (!existingForms) return <h1>Loading...</h1>

    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.mainContainer}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.heading}>FEES CALCULATOR</Typography>

                    <InputLabel className={classes.dropdownTitle}>Choose Application Type</InputLabel>
                    <Select
                        labelId="Select Application Type"
                        displayEmpty
                        id="applicationsDropDown"
                        name="applicationsDropDown"
                        onChange={(e) => handleChange(e)}
                        className={classes.selectControl}
                    >
                        {printApplicationOptions()}
                    </Select>

                    {
                        currentForm.applicationsDropDown && (
                            <>
                                <InputLabel className={classes.dropdownTitle}>Type of Service</InputLabel>
                                <Select
                                    labelId="Type of Service"
                                    displayEmpty
                                    id="serviceDropDown"
                                    name="serviceDropDown"
                                    onChange={(e) => handleChange(e)}
                                    className={classes.selectControl}
                                >
                                    {printServiceOptions()}
                                </Select>
                            </>
                        )
                    }

                    {
                        ["NRI", "FOREIGN NATIONAL"].includes(currentForm.serviceDropDown) && (
                            <>
                                <InputLabel className={classes.dropdownTitle}>Sub Service</InputLabel>
                                <Select
                                    labelId="Sub Service"
                                    displayEmpty
                                    id="subServiceDropDown"
                                    name="subServiceDropDown"
                                    onChange={(e) => handleChange(e)}
                                    className={classes.selectControl}
                                >
                                    {printSubServiceOptions()}
                                </Select>
                            </>
                        )
                    }

                    <Grid container className={classes.tableContainer}>
                        <Grid item>
                            <Typography variant="h6">Amount to be Paid: {currentAmount}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
};

const FeesCalculator = compose(withFirebase)(FeesCalculatorBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(FeesCalculatorPage);