import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const SignInPage = () => (
  <div>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <section className="login-section login-style-2 mb-80 pt-100">
        <div className="container">
          <div className="login-wrapper img-bg">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-xl-5 offset-xl-1">
                <div className="login-content-wrapper">
                  <div className="section-title mb-40">
                    <h3 className="mb-20">Sign In</h3>
                  </div>
                  <form onSubmit={this.onSubmit} className="login-form">
                    <div className="single-input">
                      <label htmlFor="email">Email</label>
                      <input
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        type="email"
                        placeholder="Email Address"
                      />
                      <i className="lni lni-envelope" />
                    </div>
                    <div className="single-input">
                      <label htmlFor="login-password">Password</label>
                      <input
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Password"
                      />
                      <i className="lni lni-lock" />
                    </div>
                    <div className="form-footer mb-25">
                      <PasswordForgetLink />
                    </div>
                    <div className="signup-button mb-25">
                      <button
                        disabled={isInvalid}
                        type="submit"
                        className="button button-lg radius-10 btn-block"
                      >
                        Sign in
                      </button>
                    </div>
                    {error && <p className="mb-25">{error.message}</p>}
                    <SignUpLink />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const SignInLink = () => (
  <p>
    Already have an account? <Link to={ROUTES.SIGN_IN}>Log In</Link>
  </p>
);

const SignInForm = compose(
  withRouter,
  withFirebase
)(SignInFormBase);

export default SignInPage;

export { SignInForm, SignInLink };
