import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { useHistory } from "react-router-dom";
import ApplicationCard, { useCardStyles } from '../CardComponent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CurrentOrders from '../CurrentOrders';
import { makeStyles } from '@material-ui/core/styles';
import { getApplications } from '../ApplicationContext';
import Grid from '@material-ui/core/Grid';
import Loading from '../Loading';
import Container from '@material-ui/core/Container';

const AppliedFormsLayout = props => (
    <AuthUserContext.Consumer>
        {authUser => <AppliedFormsBase authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

export const useStyles = makeStyles((theme) => ({
    boxApplication: {
        backgroundColor: "#E8E8E8",
        margin: "2% 1%",
        borderRadius: 10,
        padding: "15px",
    },
    applicationsDiv: {
        display: 'flex',
        flexDirection: 'row',
    },
    gridItems: {
        margin: "auto",
        paddingLeft: "1%",
        fontWeight: 600,
        fontSize: 15,
        color: "gray"
    },
    serialNoDiv: { width: 30 },
    firstCardItem: { width: 100 },
    secondCardItem: { width: 130 },
    thirdCardItem: { width: 180 },
    fourthCardItem: { width: 200 },
    fifthCardItem: { width: 290 },
    sixthCardItem: { width: 30 },

    '@media only screen and (max-width: 600px)': {
        individualApplication: {
            display: 'none'
        }
    }
}))

const AppliedForms = props => {
    const classes = useStyles();
    let history = useHistory();

    const [userApplications, setUserApplications] = useState([]);
    const [notifications, setNotifications] = useState();
    const [applicationId, setApplicationId] = useState();
    const [pageLoading, setPageLoading] = useState(true);


    const createOrderForUser = props.firebase.functions.httpsCallable(
        "createOrderForUser"
    );

    //useeffect sync with user applications and orders page changes
    useEffect(() => {

        setUserApplications(props.userApplications)
        setPageLoading(false)

    }, [CurrentOrders]);

    // seconds to hours (time calculation)
    const getTime = (utc) => {
        var formatedString = new Date(utc).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        return formatedString;
    }

    // edit application in form filling page
    const redirectToFormFilling = (applicationId, applicationFormType) => {
        history.push({
            pathname: ROUTES.FORM_FILLING,
            search: "?id=" + applicationId + "&formType=" + applicationFormType
        });
    }

    //Create Order and then redirect to ORDERS PAGE
    const redirectToCreateOrderPage = (applicationId) => {
        history.push({
            pathname: ROUTES.ORDER_PAGE,
            search: "?applicationId=" + applicationId
        });
    }

    // Redirect to ORDERS PAGE
    const redirectToEditOrderPage = (applicationId) => {
        history.push({
            pathname: ROUTES.ORDER_PAGE,
            search: "?applicationId=" + applicationId
        });
    }

    //set application Id for display respective orders
    const setApplicationIdForOrder = (selectedApplicationId) => {
        if (selectedApplicationId === applicationId) {
            setApplicationId(null)
        }
        else {
            setApplicationId(selectedApplicationId)
        }
    }

    // Display each applications in the table
    const displayApplications_material = () => {
        let arrayOfCards = []
        let count = 0
        for (let application of userApplications) {
            count = count + 1
            let created_At = getTime(new Date(application.applicationData.createdDate["seconds"] * 1000));
            let updated_At = application.applicationData.updatedDate ? getTime(new Date(application.applicationData.updatedDate["seconds"] * 1000)) : ""
            let actionDetails = {
                index: count,
                firstCardItem: application.applicationId,
                secondCardItem: application.applicationData.formType,
                thirdCardItem: application.applicationData.applicationStatus,
                fourthCardItem: '\u20B9' + "defaultAmount here",
                fifthCardItem: created_At,
                sixthCardItem: "Updated(IST) " + updated_At,
            }

            if (application.applicationData.applicationPDFFileURL) {
                actionDetails["iconName1"] = "fas fa-file-download"
                actionDetails["iconName2"] = "Show Orders"
                actionDetails["tooltip1"] = "Download Application"
                actionDetails["tooltip2"] = "Show Orders"
                actionDetails["href"] = `https://us-central1-applypan-landing.cloudfunctions.net/downloadFile?fileName=${application.applicationData.applicationPDFFileURL}`
                actionDetails["target"] = "_blank"
                arrayOfCards.push(<ApplicationCard actionDetails={actionDetails} applicationId={applicationId} setApplicationId={() => setApplicationIdForOrder(application.applicationId)} />)
            }
            else if (!(application.applicationData.applicationStatus === "New" || application.applicationData.applicationStatus === "Draft") && !application.applicationData.orderId) {
                actionDetails["iconName1"] = "fas fa-cart-plus"
                actionDetails["iconName2"] = "Show Orders"
                actionDetails["tooltip1"] = "Create Order"
                actionDetails["tooltip2"] = "Show Orders"
                arrayOfCards.push(<ApplicationCard actionDetails={actionDetails} applicationId={applicationId} setApplicationId={() => setApplicationIdForOrder(application.applicationId)} onClick={() => redirectToCreateOrderPage(application.applicationId)} />)
            }
            else if (application.applicationData.applicationStatus === "New" || application.applicationData.applicationStatus === "Draft") {
                actionDetails["iconName1"] = "fas fa-edit"
                actionDetails["iconName2"] = "Show Orders"
                actionDetails["tooltip1"] = "Edit Application"
                actionDetails["tooltip2"] = "Show Orders"
                arrayOfCards.push(<ApplicationCard actionDetails={actionDetails} applicationId={applicationId} setApplicationId={() => setApplicationIdForOrder(application.applicationId)} onClick={() => redirectToFormFilling(application.applicationId, application.applicationData.formType)} />)
            }
            else if (application.applicationData.orderId && !application.applicationData.applicationPDFFileURL) {
                actionDetails["iconName1"] = "fas fa-credit-card"
                actionDetails["iconName2"] = "Show Orders"
                actionDetails["tooltip1"] = "Make Payment"
                actionDetails["tooltip2"] = "Show Orders"
                arrayOfCards.push(<ApplicationCard actionDetails={actionDetails} applicationId={applicationId} setApplicationId={() => setApplicationIdForOrder(application.applicationId)} onClick={() => redirectToEditOrderPage(application.applicationId)} />)
            }
            else {
                continue
            }
        }
        return arrayOfCards
    }

    if (pageLoading)
        return <Loading loadingMsg="Loading Applications..." />
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Container>
                    <Grid container xs={12} className={classes.individualApplication}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <div className={classes.boxApplication}>
                                    <Grid container className={classes.applicationsDiv}>
                                        <Grid item className={classes.serialNoDiv}>
                                            <Typography gutterBottom variant="h6" className={classes.gridItems}>
                                                Sl. No.
                                        </Typography>
                                        </Grid>
                                        <Grid item className={classes.firstCardItem}>
                                            <Typography gutterBottom variant="h6" className={classes.gridItems}>
                                                Application
                                        </Typography>
                                        </Grid>
                                        <Grid item className={classes.secondCardItem}>
                                            <Typography gutterBottom variant="h6" className={classes.gridItems}>
                                                Date/Time
                                        </Typography>
                                        </Grid>
                                        <Grid item className={classes.thirdCardItem}>
                                            <Typography gutterBottom variant="h6" className={classes.gridItems}>
                                                Application Id
                                        </Typography>
                                        </Grid>
                                        <Grid item className={classes.fourthCardItem}>
                                            <Typography variant="h6" gutterBottom className={classes.gridItems}>
                                                Category
                                        </Typography>
                                        </Grid>
                                        <Grid item className={classes.fifthCardItem}>
                                            <Typography variant="h6" gutterBottom className={classes.gridItems}>
                                                Status
                                        </Typography>
                                        </Grid>
                                        <Grid item className={classes.sixthCardItem}>
                                            <Typography variant="h6" color="textSecondary" className={classes.gridItems}>
                                                Actions
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {displayApplications_material()}
                </Container>
            </Paper>
        </div>
    )
};

const condition = authUser => !!authUser;

const AppliedFormsLink = () => <Link to={ROUTES.FORM_FILLING}>Form Filling</Link>;

const AppliedFormsBase = compose(withFirebase, getApplications)(AppliedForms)
const AppliedFormsPage = compose(withAuthorization(condition))(AppliedFormsLayout);

export default AppliedFormsPage;

export { AppliedFormsPage, AppliedFormsBase, AppliedFormsLink };