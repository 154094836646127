import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import * as COLORS from '../constants/colors';

const useStyles = makeStyles((theme) => ({
    textboxDiv: {
        position: 'relative'
    },
    textBox: {
        width: "100%"
    },
    labelRoot: {
        fontSize: 16
    },
    adornmentStyle: {
        fontSize: 12
    },
    inforIconDiv: {
        position: "absolute"
    },
    inforIconLabelDiv: {
        position: "absolute",
        right: 128,
        top: -1
    },
    inforIcon: {
        fontSize: 18,
        cursor: "pointer",
        color: 'gray'
    }
}));

const CustomToolTip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12,
        backgroundColor: COLORS.FRESH_APPLICATION,
        color: "white"
    },
    title: {
        width: "100%"
    }
}))(Tooltip);

const BasicTextFields = (props) => {
    const classes = useStyles();
    const [maxChars, setMaxChars] = useState();

    const CssTextField = withStyles({
        root: {
            '& .MuiInput-underline:after': {
                borderBottomColor: (props.formik.errors[props.id] && Object.keys(props.formik.errors[props.id]).length === 0) ? 'green' : 'blue'
            }
        }
    })(TextField);

    return (
        <div className={classes.textboxDiv}>

            <TextField className={classes.textBox}
                error={(props.formik.errors[props.id] && props.formik.touched[props.id])}
                id={props.id}
                label={!props.details.required ? props.details.label + " (optional)" : props.details.label}
                helperText={
                    (props.formik.errors[props.id] && props.formik.touched[props.id]) ? props.formik.errors[props.id] : ""
                }
                inputProps={{
                    maxLength: props.details.maxlength,
                }}
                InputProps={{
                    endAdornment: <Typography variant="h6" className={classes.adornmentStyle}>{maxChars}</Typography>
                }}
                value={(props.value && props.value.toUpperCase()) || props.details.value || ""}
                onChange={(event) => {
                    setMaxChars(
                        props.details.maxlength ? (event.target.value ? event.target.value.length + "/" + props.details.maxlength : "0" + "/" + props.details.maxlength) : ""
                    )
                    props.onChange(event);
                }}
                disabled={props.details.disabled}
                onBlur={(event) => {
                    setMaxChars("")
                    props.onBlur(event)
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                    },
                    shrink: true
                }}
                multiline
                style={{
                    padding: "2px",
                    borderBottomColor: (props.formik.errors[props.id] && Object.keys(props.formik.errors[props.id]).length === 0) ? 'green' : 'blue'
                }}
            />
            {
                props.details.info &&
                <div className={classes.inforIconLabelDiv}>
                    <CustomToolTip title={props.details.info} placement="right">
                        <InfoIcon className={classes.inforIcon} />
                    </CustomToolTip>
                </div>
            }
        </div>
    );
}

export default BasicTextFields;