import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    textBox: {
        width: "100%"
    },
    textBoxDiv: {
        position: 'relative'
    },
    labelRoot: {
        fontSize: 16,
    },
    inforIconDiv: {
        position: "absolute"
    },
    inforIconLabelDiv: {
        position: "absolute",
        right: 128,
        top: -1
    },
    adornmentStyle: {
        fontSize: 12
    },
    inforIcon: {
        fontSize: 18,
        cursor: "pointer",
        color: 'gray'
    }
}));

const CustomToolTip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12,
        backgroundColor: "inherit"
    }
}))(Tooltip);


const BasicTextFields = (props) => {

    const classes = useStyles();

    const [maxChars, setMaxChars] = useState();
    const [disabled, setDisabled] = useState();

    useEffect(() => {
        let lastnameArray = ['lastName', 'fathersLastName', 'mothersLastName', 'nickLastName', 'representativeLastName']
        let firstnameArray = ['firstName', 'fathersFirstName', 'mothersFirstName', 'nickFirstName', 'representativeFirstName']

        let nameJson = {
            lastName: 'firstName',
            firstName: 'middleName',

            fathersLastName: 'fathersFirstName',
            fathersFirstName: 'fathersMiddleName',

            mothersLastName: 'mothersFirstName',
            mothersFirstName: 'mothersMiddleName',

            nickLastName: 'nickFirstName',
            nickFirstName: 'nickMiddleName',

            representativeLastName: 'representativeFirstName',
            representativeFirstName: 'representativeMiddleName'
        }

        lastnameArray.map((name) => {
            if (!props.formik.values[name] || props.formik.values[name].length === 0) {
                props.formik.values[nameJson[name]] = ""
                props.formik.touched[nameJson[name]] = true
            }
        })

        firstnameArray.map((name) => {
            if (!props.formik.values[name] || props.formik.values[name].length === 0) {
                props.formik.values[nameJson[name]] = ""
                props.formik.touched[nameJson[name]] = true
            }
        })

    }, [props.formik.values])

    return (
        <div className={classes.textBoxDiv}>

            <TextField className={classes.textBox}
                error={(props.formik.errors[props.id] && props.formik.touched[props.id])}
                id={props.id}
                label={!props.details.required ? props.details.label + " (optional)" : props.details.label}
                helperText={
                    (props.formik.errors[props.id] && props.formik.touched[props.id]) ? props.formik.errors[props.id] : ""
                }
                value={(props.value && props.value.toUpperCase()) || ""}
                onChange={(event) => {
                    if (!/\d/.test(event.target.value)) {
                        setMaxChars(
                            props.details.maxlength ? (event.target.value ? event.target.value.length + "/" + props.details.maxlength : "0" + "/" + props.details.maxlength) : ""
                        )
                        props.onChange(event);
                    }
                }}
                disabled={props.details.disabled || props.disabled}
                onBlur={(event) => {
                    setMaxChars("")
                    props.onBlur(event)
                }}
                inputProps={{
                    maxLength: props.details.maxlength,
                }}
                InputProps={{
                    endAdornment: <Typography variant="h6" className={classes.adornmentStyle}>{maxChars}</Typography>
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                    },
                    shrink: true
                }}
                multiline
                rows={1}
                style={{ padding: "2px" }}
            />
            {
                props.details.imageInfo &&
                <div className={classes.inforIconLabelDiv}>
                    <CustomToolTip title={<img src={props.details.imageInfo} />} placement="right">
                        <InfoIcon className={classes.inforIcon} />
                    </CustomToolTip>
                </div>
            }
        </div>
    );
}

export default BasicTextFields;