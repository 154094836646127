import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

    return (
        <div>
            <Dialog
                open={props.routeChange.showModal}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Do you want to Exit the page?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This might leads to lost of your current application data. Do you want to save as draft?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.saveDraft() }} component={Link} to={props.routeChange.pathName} color="primary">
                        Save
                    </Button>
                    <Button onClick={() => { props.deleteApplication() }} component={Link} to={props.routeChange.pathName} color="primary" >
                        Discard
                    </Button>
                    <Button onClick={() => { props.setRouteChange({ showModal: false }) }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}