import React, { useState, useEffect } from "react";

import OrderContext from "./context";
import { withFirebase } from "../Firebase";

const getOrders = Component => {

    const GetOrders = (props) => {

        const [existingOrders, setExistingOrders] = useState([]);

        //useeffect sync with user applications and orders page changes
        useEffect(() => {
            if (props.authUser) {
                let arrayOfOrders = []
                //  Bring all orders for current application
                props.firebase.db.collection("orders").where("applicationId", "==", props.applicationId)
                    .onSnapshot(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            arrayOfOrders.push(doc.data())
                        });
                        setExistingOrders(arrayOfOrders)
                    })
            }
        }, []);
        return (
            <OrderContext.Provider value={existingOrders}>
                {
                    (existingOrders && existingOrders.length > 0) &&
                    <Component {...props} existingOrders={existingOrders} />
                }
            </OrderContext.Provider>
        );
    }
    return withFirebase(GetOrders)
}

export default getOrders;