import * as Yup from 'yup';

const initialNameArray = [
    "AB", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AR", "AS", "AU", "AW", "BE", "BI", "BO", "BP", "CH",
    "CO", "CY", "DA", "DE", "DI", "DN", "DO", "DU", "EE", "EK", "EM", "EP", "ER", "ES", "EX", "FA", "FE", "FK", "FA",
    "FE", "FK", "FU", "GH", "GI", "GO", "GU", "HA", "HE", "HO", "HU", "IB", "ID", "II", "IK", "IL", "IM", "IN", "IV",
    "JA", "JE", "JI", "JO", "JR", "JU", "KA", "KC", "KE", "KH", "KI", "KJ", "KM", "KO", "KS", "KU", "LA", "LE", "LI",
    "LO", "LU", "MA", "MC", "MD", "MG", "MH", "MI", "MO", "MU", "NA", "NG", "NI", "NO", "OD", "OE", "OH", "OI", "OJ",
    "OK", "OM", "ON", "OR", "OS", "OU", "OZ", "PA", "PI", "PO", "PT", "PU", "QI", "RA", "AI", "RU", "SA", "SE", "SI",
    "SK", "SM", "SO", "SR", "SU", "TA", "TE", "TI", "TO", "TU", "UG", "UL", "UM", "UN", "UR", "UZ", "VK", "WO", "WU",
    "XI", "XU", "YE", "YH", "YI", "YJ", "YO", "YU", "ZA", "ZI", "ZU"
]

const Schema = Yup.object().shape({
    panNumber: Yup.string().matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Must match with the pattern'),

    isApplicantNameCorrection: Yup.boolean()
        .when('applicationCategory',
            {
                is: (val) => val === ["Individual"].includes(val),
                then: Yup.boolean().required("Applicant Name correction required")
            }
        ).nullable(),

    isParentNameCorrection: Yup.boolean()
        .when('applicationCategory',
            {
                is: (val) => val === ["Individual"].includes(val),
                then: Yup.boolean().required("Parents Name correction required")
            }
        ).nullable(),

    applicantNameChange: Yup.array()
        .when('applicationCategory',
            {
                is: (val) => val === ["Individual"].includes(val),
                then: Yup.array().when('isApplicantNameCorrection',
                    {
                        is: (val) => val === true,
                        then: Yup.array().required("Applicant Name change required")
                    }
                )
            }
        ).nullable(),

    changeProof: Yup.string()
        .when('applicantNameChange',
            {
                is: (val) => val && ["Husband's Name", "Surname", "Father's Name", "Spelling Change"].some(r => val.indexOf(r) >= 0),
                then: Yup.string().required("select applicant Change proof")
            }
        ).nullable(),

    entireNameChangeProof: Yup.string()
        .when('applicantNameChange',
            {
                is: (val) => val && val.includes("Entire Name Change"),
                then: Yup.string().required("select applicant entire name change proof")
            }
        ).nullable(),

    whatToDo: Yup.string()
        .when('applicantNameChange',
            {
                is: (val) => val && ["Husband's Name", "Surname", "Father's Name"].some(r => val.indexOf(r) >= 0),
                then: Yup.string().required("Select what changes to do")
            }
        ).nullable(),

    maritalStatus: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["correctionMinor"].includes(val),
                            then: Yup.string().required("Marital Status is required").nullable(),
                        }
                    )
            }
        ).nullable(),

    titleOnName: Yup.string()
        .when('applicantNameChange',
            {
                is: (val) => val && ["Husband's Name", "Surname", "Father's Name", "Spelling Change"].some(r => val.indexOf(r) >= 0),
                then: Yup.string().required("Salutation required")
            }
        )
        .when('applicationCategory',
            {
                is: (val) => val === ["Individual"].includes(val),
                then: Yup.array().when('isApplicantNameCorrection',
                    {
                        is: (val) => val === false,
                        then: Yup.string().required("Salutation required")
                    }
                )
            }
        ).nullable(),

    lastName: Yup.string()
        .when('applicantNameChange',
            {
                is: (val) => val && ["Husband's Name", "Surname", "Father's Name", "Spelling Change"].some(r => val.indexOf(r) >= 0),
                then: Yup.string()
                    .when('lastName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less").required('Last Name or Surname is mandatory'),
                        }
                    )
            }
        )
        .when('applicationCategory',
            {
                is: (val) => val === ["Individual"].includes(val),
                then: Yup.array().when('isApplicantNameCorrection',
                    {
                        is: (val) => val === false,
                        then: Yup.string().when('lastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Last Name or Surname is mandatory'),
                            }
                        )
                    }
                )
            }
        ).nullable(),

    firstName: Yup.string()
        .when('lastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'firstName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string(),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less")
                    }
                ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ).nullable(),

    middleName: Yup.string()
        .when('firstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'middleName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string(),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less")
                    }
                ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ).nullable(),

    nameOnPanCard: Yup.string().required("Name on pancard is required").nullable(),

    isWhoseName: Yup.string().when('isParentNameCorrection',
        {
            is: (val) => val === true,
            then: Yup.string().required("select whose name to print")
        }
    ).nullable(),

    fathersNameChange: Yup.array().when('isWhoseName',
        {
            is: (val) => val === true,
            then: Yup.array().required("Father name change is required")
        }
    ).nullable(),

    parentsChangeProof: Yup.string().when('fathersNameChange',
        {
            is: (val) => val && ["Father's Last Name", "Father's Middle Name", "Father's First Name", "Spelling Change"].some(r => val.indexOf(r) >= 0),
            then: Yup.string().required("Parents change proof is required")
        }
    )
        .when('mothersNameChange',
            {
                is: (val) => val && ["Mother's Last Name", "Mother's Middle Name", "Mother's First Name", "Spelling Change"].some(r => val.indexOf(r) >= 0),
                then: Yup.string().required("Parents name change proof is required")
            }
        ).nullable(),

    parentsEntireNameChangeProof: Yup.string().when('fathersNameChange',
        {
            is: (val) => val && val.includes("Entire Name Change"),
            then: Yup.string().required("Parents Entire name change proof required")
        }
    )
        .when('mothersNameChange',
            {
                is: (val) => val && val.includes("Entire Name Change"),
                then: Yup.string().required("Parents Entire name change proof required")
            }
        ).nullable(),

    parentsName: Yup.string().when('isParentNameCorrection',
        {
            is: (val) => val === false,
            then: Yup.string().required("Parents name required")
        }
    ).nullable(),

    singleParent: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('parentsName',
                {
                    is: (val) => val === "mothersName",
                    then: Yup.boolean().required("single parent required")
                }
            )
        }
    ).nullable(),

    fathersLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('parentsName',
                {
                    is: (val) => val === "fathersName",
                    then: Yup.string()
                        .when(
                            'fathersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Last Name or Surname is mandatory'),
                            }
                        )
                }
            )
        }
    ).when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('singleParent',
                {
                    is: (val) => val === "no",
                    then: Yup.string()
                        .when(
                            'fathersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Last Name or Surname is mandatory'),
                            }
                        )
                }
            )
        }
    ).nullable(),

    mothersLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('singleParent',
                {
                    is: (val) => val === "yes",
                    then: Yup.string()
                        .when(
                            'mothersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Last Name or Surname is mandatory'),
                            }
                        )
                }
            )
                .when('parentsName',
                    {
                        is: (val) => val === "mothersName",
                        then: Yup.string()
                            .when(
                                'mothersLastName',
                                {
                                    is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                    then: Yup.string().required("Last Name is required"),
                                    otherwise: Yup.string()
                                        .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                        .max(40, "Must be 40 characters or less").required('Last Name or Surname is mandatory'),
                                }
                            )
                    }
                )
        }
    ).nullable(),

    fathersMiddleName: Yup.string()
        .when('fathersFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'fathersMiddleName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ),

    mothersMiddleName: Yup.string()
        .when('mothersFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'mothersMiddleName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ),

    fathersFirstName: Yup.string()
        .when('fathersLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'fathersFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ),

    mothersFirstName: Yup.string()
        .when('mothersLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'mothersFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ),

    isDobCorrection: Yup.boolean().required("Dob correction required").nullable(),

    dateOfBirth: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Date of Birth is required"),
                otherwise: Yup.string().required("Date of Formation is required")
            }
        ).nullable(),

    isGenderCorrection: Yup.boolean().when('applicationCategory',
        {
            is: (val) => val === ["Individual"].includes(val),
            then: Yup.boolean().required("Gender correction required"),
        }
    ).nullable(),

    gender: Yup.string().when('isGenderCorrection',
        {
            is: (val) => val != null,
            then: Yup.string().required("Gender required")
        }
    ).nullable(),

    isAadhaarCorrection: Yup.boolean().when('applicationCategory',
        {
            is: (val) => val === ["Individual"].includes(val),
            then: Yup.boolean().required("Aadhaar correction required"),
        }
    ).nullable(),

    aadhaarCard: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Do you have aadhaar card?").nullable(),
            }
        ),
    aadhaarNumber: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('aadhaarCard',
                {
                    is: (val) => val === "yes",
                    then: Yup.string().matches(/^\d{4}\s\d{4}\s\d{4}$/,
                        "Aadhaar is always twelve digit number").required("Aadhaar Number is mandatory"),
                }
            )
        }
    ).nullable(),
    aadhaarName: Yup.string().when('isAadhaarCorrection',
        {
            is: (val) => val != null,
            then: Yup.string().required("Aadhaar name required")
        }
    ).nullable(),

    otherPan1: Yup.string().when('isMentionOtherPan',
        {
            is: (val) => val === true,
            then: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern').required('Must mention atleast one PAN number')
        }
    ).nullable(),
    otherPan2: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern'),
    otherPan3: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern'),
    otherPan4: Yup.string().matches(/^([A-Za-z]){1,5}(([0-9]){4}([A-Za-z]){1})?$/, 'Must match with the pattern'),

    isOfficeAddressDelivery: Yup.boolean().required("Choose office address delivery").nullable(),

    isAddressCorrection: Yup.boolean().required("Address correction required").nullable(),

    addressArea: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionNriAbroad", "correctionForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address Area is required"),
            }
        ),

    addressBuilding: Yup.string()
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Name of Office is required"),
            }
        ),

    addressCity: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionNriAbroad", "correctionForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address City is required"),
            }
        ),

    addressCountry: Yup.string()
        .max(25,
            "Must be 25 characters or less").nullable().required("Address Country is required"),

    addressFlat: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionNriAbroad", "correctionForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address Flat is required"),
            }
        ),

    addressPincode: Yup.string().nullable()
        .required("Pincode is required")
        .min(7, "Invalid Pincode")
        .max(7, "Invalid Pincode"),

    addressRoad: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionNriAbroad", "correctionForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address Road is required"),
            }
        ),

    addressState: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionNriAbroad", "correctionForeignAbroad"].includes(val),
                then: Yup.string()
                    .max(25,
                        "Must be 25 characters or less").nullable().required("Address State is required"),
            }
        ),

    isEmailMobileCorrection: Yup.boolean().required("Mobile & Email correction is required").nullable(),

    emailAddress: Yup.string()
        .when('isEmailMobileCorrection',
            {
                is: (val) => val != null,
                then: Yup.string().nullable().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    "Email must be valid").required('Email ID is required').email('Email must be valid'),
            }
        ).nullable(),

    mobileNumber: Yup.string()
        .when('formType',
            {
                is: (val) => !["correctionNriAbroad", "correctionForeignAbroad"].includes(val),
                then: Yup.string().required('Mobile number is required'),
            }
        ),

    mobileNumberForeign: Yup.string()
        .when('formType',
            {
                is: (val) => ["correctionNriAbroad", "correctionForeignAbroad"].includes(val),
                then: Yup.string().required('Mobile number is required').min(13, "Mobile Number must have 13 numbers").max(13, "Mobile Number must have 13 numbers"),
            }
        ),

    panProof: Yup.string().required("PAN Proof document required").nullable(),
    isPhotoCorrection: Yup.boolean().required("Photo Correction is required").nullable(),
    isSignatureCorrection: Yup.boolean().required("Signature Correction document required").nullable(),
    proofOfDob: Yup.string().required("DOB Proof document required").nullable(),
    proofOfIdentity: Yup.string().required("Identity Proof document required").nullable(),
    proofOfOffice: Yup.string()
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().required("Office proof required").nullable(),
            }
        ),
    proofOfResidence: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Residence Proof document required").nullable(),
            }
        ).nullable()

})

export default Schema;