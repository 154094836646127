import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { useHistory } from "react-router-dom";
import ApplicationCard, { useCardStyles } from '../CardComponent/orderCard';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Grow from '@material-ui/core/Grow';
import { getOrders } from '../OrderContext';

const CurrentOrdersLayout = props => (
    <AuthUserContext.Consumer>
        {authUser => <CurrentOrdersBase authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const CurrentOrders = props => {
    const classes = useCardStyles();
    const history = useHistory();
    const [existingOrders, setExistingOrders] = useState([]);
    const [orderId, setOrderId] = useState(null);

    // sync with props.applicationId for change in the applicationId in the Applied Forms Page
    useEffect(() => {
        if (!props.applicationId) return
        setExistingOrders(props.existingOrders)
    }, []);


    //Redirect to order page
    const redirectToPaymentPage = (orderId) => {
        history.push({
            pathname: ROUTES.ORDER_PAGE,
            search: "?&orderId=" + orderId
        });
    }

    const getTime = (utc) => {
        var formatedString = new Date(utc).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        return formatedString;
    }

    // display all current orders for user
    const displayOrders_material = existingOrders.map((order, indx) => {

        let created_At = getTime(new Date(order.createdDate["seconds"] * 1000))

        let actionDetails = {
            firstCardItem: order.orderId,
            secondCardItem: order.formType,
            thirdCardItem: order.createdOrderRazorpay.status,
            fourthCardItem: '\u20B9' + order.createdOrderRazorpay.amount / 100,
            fifthCardItem: "Created At (IST) " + created_At
        }

        if (order.createdOrderRazorpay && order.createdOrderRazorpay.status === "created") {
            actionDetails["iconName1"] = "fas fa-cart-plus"
            actionDetails["tooltip1"] = "Make Payment"
            return <ApplicationCard actionDetails={actionDetails} orderId={orderId} setOrderId={() => setOrderId(order.orderId)} onClick={() => redirectToPaymentPage(order.orderId)} />
        }
        else if (order.createdOrderRazorpay && order.createdOrderRazorpay.status === "attempted") {
            actionDetails["iconName1"] = "fas fa-cart-plus"
            actionDetails["tooltip1"] = "Retry Payment"
            return <ApplicationCard actionDetails={actionDetails} orderId={orderId} setOrderId={() => setOrderId(order.orderId)} onClick={() => redirectToPaymentPage(order.orderId)} />
        }
        else if (order.createdOrderRazorpay && order.createdOrderRazorpay.status === "paid") {
            actionDetails["iconName1"] = "fas fa-cart-plus"
            actionDetails["tooltip1"] = "Payment Done"
            return <ApplicationCard actionDetails={actionDetails} orderId={orderId} setOrderId={() => setOrderId(order.orderId)} disabled={true} />
        } else {
            return
        }
    })

    if (existingOrders.length !== 0)
        return (
            <div className={classes.root}>
                <Grid container xs={12} spacing={2}>
                    {displayOrders_material}
                </Grid>
            </div>
        )
    else
        return (
            <Grow
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 1000 } : {})}
            >
                <Skeleton variant="text" width={"100%"} height={30} />
            </Grow>
        )
};

const condition = authUser => !!authUser;

const CurrentOrdersLink = () => <Link to={ROUTES.CURRENT_ORDERS}>Form Filling</Link>;

const CurrentOrdersBase = compose(withFirebase, getOrders)(CurrentOrders)
const CurrentOrdersPage = compose(withAuthorization(condition))(CurrentOrdersLayout);

export default CurrentOrdersPage;

export { CurrentOrdersPage, CurrentOrdersBase, CurrentOrdersLink };