import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import * as ROUTES from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'inherit',
    display: 'flex',
    justifyContent: 'center'
  },
  breadcrumbItems: {
    textDecoration: 'none'
  },
  breadcrumbRow:{
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: 5,
    backgroundColor: '#c0e1ed'
  }
}))

const BreadCrumbs = (props) => {

  const classes = useStyles();

  const [currentPathName, setCurrentPathName] = useState();

  useEffect(() => {
    setCurrentPathName(props.history.location.pathname)
  }, [props])

  //set route link for redirect via breadcrumbs
  const routeTo = {
    "Home": "/",
    "Select Form": "/select-form",
    "Filling Form": "/edit-application",
    "My Applications": "/appliedforms"
  }

  //set breadcrumbs list items
  const routes = {
    "/select-form": ["Home", "Select Form"],
    "/edit-application": ["Home", "Select Form", "Filling Form"],
    "/appliedforms": ["Home", "Select Form", "My Applications"],
  }

  //print breadcrumbs function
  const printBreadCrumbs = () => {
    if (props.history.location) {
      let pathnames = routes[props.history.location.pathname]
      const breadcrumb = pathnames ? pathnames.map((route, index) => {
        if (index === pathnames.length - 1) {
          return <Typography color="textPrimary">{route}</Typography>
        }
        else {
          return (
            <Link color="inherit" href={routeTo[route]}>
              {route}
            </Link>
          )
        }
      }) : ""
      return breadcrumb;
    }
  }
  const pageRequiredBreadCrumbs = [ROUTES.APPLY_FORM, ROUTES.FORM_FILLING, ROUTES.APPLIED_FORMS]

  return (
    <>
      {
        pageRequiredBreadCrumbs.includes(currentPathName) &&
        (<Paper className={classes.paper} elevation={0}>
          <Breadcrumbs className={classes.breadcrumbRow} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Typography color={currentPathName === ROUTES.APPLY_FORM ? "primary" : "inherit"} className={classes.breadcrumbItems}>
              Select Forms
            </Typography>
            <Typography color={currentPathName === ROUTES.FORM_FILLING ? "primary" : "inherit"} className={classes.breadcrumbItems}>
              Filling Forms and Payment
            </Typography>
            <Typography color={currentPathName === ROUTES.APPLIED_FORMS ? "primary" : "inherit"} className={classes.breadcrumbItems}>
              Download and Mail
            </Typography>
          </Breadcrumbs>
        </Paper>)
      }
    </>
  )
}

export default withRouter(BreadCrumbs)