import React, { useState, useEffect } from 'react';
// import './styles.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import * as COLORS from '../constants/colors';

const useStyles = makeStyles((theme) => ({
    SwitchContainer: {
        width: "100%"
    },
    radioLabel: {
        fontSize: 11,
    },
    radioTitle: {
        fontSize: 14
    },
    inforIconDiv: {
        position: "absolute",
        right: 0,
        top: 25
    },
    inforIconLabelDiv: {
        position: "absolute",
        right: 35,
        top: 2
    },
    inforIcon: {
        fontSize: 18,
        cursor: "pointer"
    }
}));

const CustomToolTip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12,
        backgroundColor: COLORS.FRESH_APPLICATION,
        color: "white"
    },
}))(Tooltip);

const Radiobutton = props => {
    const classes = useStyles();
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        if ((props.value && props.value === true) || (props.details.default && props.details.default === true)) {
            let defaultEvent = {
                target: {
                    name: props.id,
                    value: props.value || props.details.default
                }
            }
            props.onChange(defaultEvent);
            setToggle(true)
        }
    }, [])

    const handleChange = (event) => {
        props.onChange(event);
        setToggle(!toggle);
    }

    const printRadioBtns = () => {
        return (
            <>
                <FormControlLabel
                    control={<Switch
                        value={true}
                        checked={toggle}
                        onChange={(event) => { handleChange(event); }}
                        onBlur={(event) => props.onBlur(event)}
                        name={props.id}
                        id={props.id}
                        color="primary"
                        disabled={props.details.disabled}
                    />}
                    label={props.details.label}
                />
                {
                    props.details.info &&
                    <div className={classes.inforIconLabelDiv}>
                        <CustomToolTip title={props.details.info} placement="right">
                            <InfoIcon className={classes.inforIcon} />
                        </CustomToolTip>
                    </div>
                }
            </>
        )
    }

    return (
        <FormControl component="fieldset">
            <div className={classes.SwitchContainer}>
                {printRadioBtns()}
            </div>
        </FormControl>
    )
};

export default Radiobutton;