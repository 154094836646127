import React, { useEffect, useState, useRef, Suspense } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";

import FreshSchema from '../../lib/Validation/freshForms';
import CorrectionSchema from '../../lib/Validation/correctionForms';
import CorrectionReprintSchema from '../../lib/Validation/reprintForms';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import OrderPage from '../OrderPage';
import TransactionStatus from '../TransactionStatus';
import Snackbar from '../SnackBar';
import PanPreview from './SamplePanCard';

import FreshBasicDetailsPage from './freshIndividual/basicdetails';
import FreshAddressDetailsPage from './freshIndividual/addressdetails';
import FreshIncomeDetailsPage from './freshIndividual/incomedetails';

import CorrectionBasicDetailsPage from './correctionForms/basicdetails';
import CorrectionAddressDetailsPage from './correctionForms/addressdetails';
import CorrectionIncomeDetailsPage from './correctionForms/incomedetails';

import CorrectionReprintBasicDetailsPage from './reprintForms/basicdetails';
import CorrectionReprintAddressDetailsPage from './reprintForms/addressdetails';
import CorrectionReprintIncomeDetailsPage from './reprintForms/incomedetails';

import FreshKycDetailsPage from './freshIndividual/kycdetails';
import { Prompt } from 'react-router-dom';
import Loading from '../Loading';
import CustomModal from './Modals/routeChange';
import AadhaarModal from './Modals/aadhaarMandatory';
import { removeFreshNotRequired } from './PreProcessFunction/freshRemovalFunc';
import { removeCorrectionNotRequired } from './PreProcessFunction/correctionRemovalFunc';
import { removeReprintNotRequired } from './PreProcessFunction/reprintRemovalFunc';

const FormFillingLayout = props => (
    <AuthUserContext.Consumer>
        {authUser => <FormFillingBase authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

export const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            width: 'auto',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    formHeading: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2)
    },
    formHeadingItems: {
        color: "gray",
        fontSize: 14
    },
    panPreview: {
        marginTop: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto"
    },
    panTitle: {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(6)
    },
    stepper: {
        padding: theme.spacing(0, 0, 3),
    },
    fillingForm: {
        padding: 5,
        marginLeft: 10
    },
    draftButtons: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginRight: "auto"
    },
    buttonsDiv: {
        display: 'flex',
        flexDirection: "row"
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: "auto"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    inputGrids: {
        padding: "2px"
    }
}));

const FormFilling = props => {

    //get query string from the url
    let params = queryString.parse(props.location.search);

    const classes = useStyles();

    const APPLICATION_CATEGORY_MAPPIING = {
        'freshIndividual': 'Individual',
        'correctionIndividual': 'Individual',
        'correctionReprintIndividual': 'Individual',

        'freshMinor': 'Individual',
        'correctionMinor': 'Individual',
        'correctionReprintMinor': 'Individual',

        'freshNriIndia': 'Individual',
        'correctionNriIndia': 'Individual',
        'correctionReprintNriIndia': 'Individual',

        'freshNriAbroad': 'Individual',
        'correctionNriAbroad': 'Individual',
        'correctionReprintNriAbroad': 'Individual',

        'freshForeignIndia': 'Individual',
        'correctionForeignIndia': 'Individual',
        'correctionReprintForeignIndia': 'Individual',

        'freshForeignAbroad': 'Individual',
        'correctionForeignAbroad': 'Individual',
        'correctionReprintForeignAbroad': 'Individual',

        'freshHuf': 'HUF',
        'correctionHuf': 'HUF',
        'correctionReprintHuf': 'HUF',

        'freshFirm': 'Firm',
        'correctionFirm': 'Firm',
        'correctionReprintFirm': 'Firm',

        'freshCompany': 'Company',
        'correctionCompany': 'Company',
        'correctionReprintCompany': 'Company',

        'freshTrust': 'Trust',
        'correctionTrust': 'Trust',
        'correctionReprintTrust': 'Trust',

        'freshAop': 'AOP',
        'correctionAop': 'AOP',
        'correctionReprintAop': 'AOP',

        'freshBoi': 'BOI',
        'correctionBoi': 'BOI',
        'correctionReprintBoi': 'BOI',

        'freshLocalAuthority': 'Local Authority',
        'correctionLocalAuthority': 'Local Authority',
        'correctionReprintLocalAuthority': 'Local Authority',

        'freshAjp': 'AJP',
        'correctionAjp': 'AJP',
        'correctionReprintAjp': 'AJP',

        'freshGovernment': 'Government',
        'correctionGovernment': 'Government',
        'correctionReprintGovernment': 'Government',

        'freshLlp': 'LLP',
        'correctionLlp': 'LLP',
        'correctionReprintLlp': 'LLP'
    }

    const INITIAL_STATE = {
        APPLICATION_DETAILS: {
            applicationCategory: APPLICATION_CATEGORY_MAPPIING[params.formType],
            emailAddress: props.authUser.email,
        },
    }

    //local state declaration
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackBarData, setSnackBarData] = useState({ show: false });
    const [paymentStepper, setPaymentStepper] = useState(false)
    const [documentName, setDocumentId] = useState('freshIndividual')
    const [applicationDetails, setApplicationsDetails] = useState(INITIAL_STATE.APPLICATION_DETAILS);
    const [applicationMetaData, setApplicationsMetaData] = useState({});
    const [orderId, setOrderId] = useState();
    const [applicationBasicFormFieldDetails, setApplicationBasicFormFieldDetails] = useState({});
    const [applicantAddressFormFieldDetails, setApplicantAddressFormFieldDetails] = useState({});
    const [applicantIncomeFormFieldDetails, setApplicantIncomeFormFieldDetails] = useState({});
    const [applicantKycFormFieldDetails, setApplicantKycFormFieldDetails] = useState({});
    const [applicationFieldDetails, setApplicationFieldDetails] = useState({});
    const [pageLoading, setPageLoading] = useState(true);
    const [validationSchema, setValidationSchema] = useState();
    const [applicationTitle, setApplicationTitle] = useState();
    const [routeChange, setRouteChange] = useState({});
    const [removeBeforeSubmit, setRemoveBeforeSubmit] = useState();
    const [showModal, setShowModal] = useState(false);

    // loading functions from firebase
    const getFormDetails = props.firebase.functions.httpsCallable("getFormDetails");
    const updateApplicationsDetails = props.firebase.functions.httpsCallable("updateApplicationsDetails");
    const createOrderForUser = props.firebase.functions.httpsCallable("createOrderForUser");
    const deleteUserApplication = props.firebase.functions.httpsCallable("deleteUserApplication");

    useEffect(() => {
        const onUnload = e => { // the method that will be used for both add and remove event
            e.preventDefault();
            e.returnValue = '';
        }
        window.addEventListener("beforeunload", onUnload);
        return () => window.removeEventListener("beforeunload", onUnload);
    }, [])

    useEffect(() => {

        if (params.formType.substring(0, 5) === 'fresh') {
            setValidationSchema(FreshSchema)
            setApplicationTitle("Fresh-")
            setRemoveBeforeSubmit(() => removeFreshNotRequired)
        }
        else if (params.formType.substring(0, 17) === 'correctionReprint') {
            setValidationSchema(CorrectionReprintSchema)
            setApplicationTitle("Reprint-")
            setRemoveBeforeSubmit(() => removeReprintNotRequired)
        }
        else {
            setValidationSchema(CorrectionSchema)
            setApplicationTitle("Correction-")
            setRemoveBeforeSubmit(() => removeCorrectionNotRequired)
        }

        //real time updates using firebase snapshot
        props.firebase.db.collection("applications").doc(params.id)
            .onSnapshot(function (doc) {
                var userApplicationsDetails = {}
                if (doc.exists) {
                    setApplicationsMetaData(doc.data())
                    userApplicationsDetails = Object.assign({}, applicationDetails, { formType: doc.data().formType })
                }
                if (doc.exists && doc.data().applicationDetails) {
                    userApplicationsDetails = Object.assign({}, userApplicationsDetails, doc.data().applicationDetails)
                }
                setApplicationsDetails(userApplicationsDetails)
            });

        getFormDetails({ documentName: params.formType })
            .then(result => {
                //get application layout data from db
                setPageLoading(false)
                const applicationFields = result.data
                setApplicationFieldDetails(applicationFields);
                setApplicationBasicFormFieldDetails(applicationFields["basicDetails"]);
                setApplicantAddressFormFieldDetails(applicationFields["addressDetails"]);
                if (applicationFields["incomeProof"])
                    setApplicantIncomeFormFieldDetails(applicationFields["incomeProof"]);
                else
                    setApplicantIncomeFormFieldDetails(applicationFields["proofDetails"]);
                setApplicantKycFormFieldDetails(applicationFields["kyc"]);
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    useEffect(() => {
        let userDetails = {}
        let defaultArray = ['aadhaarCard', 'residenceAddressCountry', 'officeAddressCountry', 'addressCountry', 'mandatoryProof']
        Object.keys(applicationFieldDetails).forEach(function (step) {
            Object.keys(applicationFieldDetails[step]).forEach(function (key) {
                if (applicationFieldDetails[step][key].type === 'switch' && applicationDetails[key] == null) {
                    userDetails[key] = applicationFieldDetails[step][key].default || false
                }
                if (defaultArray.includes(key) && applicationDetails[key] == null) {
                    userDetails[key] = applicationFieldDetails[step][key].value || applicationFieldDetails[step][key].default
                }
            });
        });
        userDetails = Object.assign({}, applicationDetails, userDetails)
        if (JSON.stringify(userDetails) != JSON.stringify(applicationDetails))
            setApplicationsDetails(userDetails)
    }, [applicationDetails, applicationFieldDetails])

    // goto next step in stepper
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    //goto prev step in stepper
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    //create order or if order exists go to orders page for payment
    const createOrderForSubmitted = () => {
        var orderCreationData = {
            amountToBePaid: applicationMetaData.amountToBePaid.defaultAmount.amount,
            uid: props.authUser.uid,
            formType: applicationMetaData.formType,
            applicationId: params.id,
            userEmail: props.authUser.email
        }
        if (!applicationMetaData.orderId && applicationMetaData.amountToBePaid) {
            createOrderForUser(orderCreationData).then(result => {
                setSnackBarData({ show: true, message: "Application Saved!" })
                setOrderId(result.data.id)
                setActiveStep(0)
                setPaymentStepper(true)
                setPageLoading(false)
            })
                .catch((err) => console.log(err))
        }
        else {
            setSnackBarData({ show: true, message: "Application Saved!" })
            setOrderId(applicationMetaData.orderId)
            setActiveStep(0)
            setPaymentStepper(true)
            setPageLoading(false)
        }
    }

    //save draft application in db
    const saveDraft = () => {
        setPageLoading(true)
        updateApplicationsDetails({ applicationStatus: "Draft", documentId: params.id, applicationDetails: formik.values })
            .then(() => {
                setPageLoading(false)
                setSnackBarData({ show: true, message: "Draft Saved!" })
                console.log("Success!")
            })
            .catch(err => {
                console.log(err)
            })
    }

    const deleteApplication = () => {
        deleteUserApplication({ applicationId: params.id })
            .then((response) => { console.log(response) })
            .catch((error) => { console.log(error) })
    }
 
    // stepper steps for form and payment stepper
    const getStepContent = (step) => {
        var BasicDetails;
        var AddressDetails;
        var IncomeDetails;
        var KycDetails;

        if (params.formType.substring(0, 5) === "fresh") {
            BasicDetails = FreshBasicDetailsPage
            AddressDetails = FreshAddressDetailsPage
            IncomeDetails = FreshIncomeDetailsPage
            KycDetails = FreshKycDetailsPage
        }
        else if ((params.formType.substring(0, 17) === "correctionReprint")) {
            BasicDetails = CorrectionReprintBasicDetailsPage
            AddressDetails = CorrectionReprintAddressDetailsPage
            IncomeDetails = CorrectionReprintIncomeDetailsPage
        }
        else {
            BasicDetails = CorrectionBasicDetailsPage
            AddressDetails = CorrectionAddressDetailsPage
            IncomeDetails = CorrectionIncomeDetailsPage
        }

        if (paymentStepper) {
            switch (step) {
                case 0:
                    return <OrderPage applicationId={params.id} formTypeNormal={applicationFieldDetails.formTypeNormal} handleNext={handleNext} setOrderId={setOrderId} formik={formik} saveDraft={saveDraft} applicantIncomeDetails={applicantIncomeFormFieldDetails} />
                case 1:
                    return <TransactionStatus orderId={orderId} applicationMetaData={applicationMetaData} />
                default:
                    throw new Error('Unknown step');
            }
        }
        else {
            switch (step) {
                case 0:
                    return <BasicDetails formik={formik} saveDraft={saveDraft} applicantBasicDetails={applicationBasicFormFieldDetails} />
                case 1:
                    return <AddressDetails formik={formik} saveDraft={saveDraft} applicationMetaData={applicationMetaData} applicantAddressDetails={applicantAddressFormFieldDetails} setApplicationsDetails={setApplicationsDetails} />
                case 2:
                    return <IncomeDetails formik={formik} saveDraft={saveDraft} applicationFieldDetails={applicationFieldDetails} setActiveStep={(errorStep) => { setActiveStep(errorStep) }} applicantIncomeDetails={applicantIncomeFormFieldDetails} />
                case 3:
                    return <KycDetails formik={formik} saveDraft={saveDraft} applicationFieldDetails={applicationFieldDetails} setActiveStep={(errorStep) => { setActiveStep(errorStep) }} applicantKycDetails={applicantKycFormFieldDetails} />
                default:
                    throw new Error('Unknown step');
            }
        }
    }

    //validate aadhaar for individual forms
    const validateAadhaar = () => {
        let proofArray = ['individualProofIdentity', 'individualProofDob', 'individualProofResidence',
            'proofOfResidence', 'proofOfDob', 'proofOfIdentity']
        for (let proof of proofArray) {
            if (formik.values[proof] === "Aadhaar Card")
                return false
        }
        return true
    }

    // formik initialization for form validation and submission
    const formik = useFormik({
        initialValues: applicationDetails,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: formValues => {
            if (applicationDetails.applicationCategory === 'Individual' && validateAadhaar()) {
                setShowModal(true)
            }
            else {
                const removedFields = removeBeforeSubmit(formValues)
                console.log(removedFields)
                setPageLoading(true)
                updateApplicationsDetails({ applicationStatus: "Submitted", documentId: params.id, applicationDetails: removedFields })
                    .then(() => {
                        // createOrderForSubmitted()
                        setSnackBarData({ show: true, message: "Application Saved!" })
                        setActiveStep(0)
                        setPaymentStepper(true)
                        setPageLoading(false)
                        console.log("Success!")
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    });

    useEffect(() => {
        setApplicationsDetails(formik.values)
    }, [formik.values])

    var steps = [];
    if (["freshForeignAbroad", "freshForeignIndia"].includes(params.formType)) {
        //initialize stepper steps here
        steps = paymentStepper ? ['Payment', 'TransactionStatus'] : ['Basic Details', 'Address Details', 'Income Details', 'KYC Details'];
    }
    else {
        //initialize stepper steps here
        steps = paymentStepper ? ['Payment', 'TransactionStatus'] : ['Basic Details', 'Address Details', 'Income Details'];
    }

    const handleRouteChange = (pathname) => {
        if (!paymentStepper && (Object.keys(routeChange).length === 0 || routeChange.showModal === false)) {
            setRouteChange({
                showModal: true,
                pathName: pathname
            })
            return false
        }
        else {
            return true
        }
    }


    if (pageLoading)
        return <Loading />
    return (
        <React.Fragment>
            {/* prompt message to user before leave this page */}
            < Prompt
                when={true}
                message={(location) => handleRouteChange(location.pathname)}
            />
            <CustomModal routeChange={routeChange} setRouteChange={setRouteChange} saveDraft={saveDraft} deleteApplication={() => { deleteApplication() }} />
            <AadhaarModal showModal={showModal} setShowModal={setShowModal} />
            <CssBaseline />
            <Snackbar snackBarData={snackBarData} setSnackBarData={setSnackBarData} />

            <main className={classes.layout}>

                <div>
                    <Grid container spacing={2} className={classes.mainContainer}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6">Online PAN Application</Typography>

                            <Paper className={classes.paper}>
                                <Grid container className={classes.formHeading}>
                                    <Grid item>
                                        <Typography variant="h6" className={classes.formHeadingItems}>{applicationTitle + applicationDetails.applicationCategory + " Application"}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" className={classes.formHeadingItems}>{"Your Application ID " + params.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Stepper activeStep={activeStep} className={classes.stepper}>
                                    {steps.map((label, index) => (
                                        <Step key={label} onClick={() => setActiveStep(index)} >
                                            <StepLabel style={{ cursor: 'pointer' }}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <React.Fragment className={classes.fillingForm}>

                                    {/* Final step of payment stepper is to be appeared */}
                                    {activeStep === steps.length && orderId ?
                                        (
                                            <React.Fragment>
                                                <TransactionStatus orderId={orderId} />
                                            </React.Fragment>
                                        ) :
                                        (
                                            <React.Fragment>

                                                {/* get stepper components */}

                                                {getStepContent(activeStep)}

                                                {/* show buttons if paymentStepper is true */}
                                                {
                                                    !paymentStepper &&
                                                    (
                                                        <div className={classes.buttonsDiv}>

                                                            {activeStep < steps.length - 1 && (
                                                                <div className={classes.draftButtons}>
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        id="draftBtn"
                                                                        onClick={() => { saveDraft() }}
                                                                        className={classes.button}
                                                                    > Save Draft </Button>
                                                                </div>)}

                                                            <div className={classes.buttons}>

                                                                {activeStep !== 0 && (
                                                                    <Button id="backBtn" onClick={handleBack} className={classes.button}>
                                                                        Back
                                                                    </Button>
                                                                )}

                                                                {activeStep === steps.length - 1 ?
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        id="submitBtn"
                                                                        onClick={(e) => formik.handleSubmit(e)}
                                                                        className={classes.button}
                                                                    > Apply and Make Payment </Button> :
                                                                    activeStep !== steps.length - 1 && <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        id="nextBtn"
                                                                        onClick={handleNext}
                                                                        className={classes.button}
                                                                    > Next </Button>
                                                                }

                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </React.Fragment>
                                        )}
                                </React.Fragment>
                            </Paper>
                        </Grid>
                        {/* pan preview card sync with formik.values */}
                        <Grid container
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            md={4}
                        >
                            <Grid item className={classes.panPreview}>
                                <Typography variant="h6" className={classes.panTitle}>Your PAN Card looks like</Typography>
                                <PanPreview formik={formik} formValues={applicationDetails}></PanPreview>
                            </Grid>
                            <Grid item className={classes.panPreview}>
                                <img src="/assets/img/home/fillingforms.svg" alt="applications" width="350" height="400" />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

            </main>
        </React.Fragment>
    );
};

const condition = authUser => !!authUser;

const FormFillingLink = () => <Link to={ROUTES.FORM_FILLING}>Form Filling</Link>;

const FormFillingBase = compose(withFirebase)(FormFilling)
const FormFillingPage = compose(withAuthorization(condition))(FormFillingLayout);

export default FormFillingPage;

export { FormFillingPage, FormFillingBase, FormFillingLink };