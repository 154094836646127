import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../../Session";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import { useFormik } from 'formik';

import TextBox from '../../../ui-elements/textbox';
import Dropdown from '../../../ui-elements/dropdown';
import Radiobutton from '../../../ui-elements/radiobutton';
import SwitchComponent from '../../../ui-elements/switch';
import DateComponent from '../../../ui-elements/datecomponent';
import Checkbox from '../../../ui-elements/checkbox';
import { StepTitle, SubHeading } from '../../../ui-elements/headers';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '../Stepper';
import Schema from '../../../lib/Validation/freshincomeschema';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextMask from '../../../ui-elements/textmask';
import ErrorIcon from '@material-ui/icons/Error';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    incomeForm: {
        paddingLeft: 20
    },
    formikErrors: {
        color: '#B80000 ',
        border: '1px solid red',
        marginRight: 10,
        marginBottom: 5,
        opacity: 0.9
    },
    errorIcon: {
        color: '#A00000',
        opacity: 0.8
    },
    sectionHeading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        backgroundColor: "#d3d3d3",
        paddingLeft: theme.spacing(1),
        borderRadius: 5
    },
}));

const IncomeDetails = props => {
    const classes = useStyles();
    const formik = props.formik;
    const [showBackDrop, setShowBackDrop] = useState(false);

    useEffect(() => {
        var postalCode = formik.values['officeAddressPincode'] && formik.values['officeAddressPincode']

        if (postalCode && /^[[0-9]{3}\s[0-9]{3}]*$/.test(postalCode)) {
            setShowBackDrop(true)
            postalCode = postalCode.replace(/ /g, '')
            fetch(`https://api.postalpincode.in/pincode/${postalCode}`)
                .then((res) => res.json())
                .then((res) => {
                    formik.values.officeAddressCity = res[0].PostOffice[0].District
                    formik.values.officeAddressState = res[0].PostOffice[0].State
                    formik.onChange({})
                    setShowBackDrop(false)
                })
                .catch(err => {
                    setShowBackDrop(false)
                    console.log(err)
                })
        }

    }, [formik.values['officeAddressPincode']])


    const orderedFieldsForOfficeAddress = ["officeAddressBuilding", "officeAddressFlat", "officeAddressVillage", "officeAddressRoad", "officeAddressArea", "officeAddressPincode", "officeAddressCity", "officeAddressState", "officeAddressCountry"]

    const getElementToBeRendered = (fieldItemName, colSize) => {
        if (props.applicantIncomeDetails[fieldItemName]) {
            if (props.applicantIncomeDetails[fieldItemName].type == 'text')
                return <Grid item xs={12} md={colSize || 4}> <TextBox details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantIncomeDetails[fieldItemName].type == 'number')
                return <Grid item xs={12} md={colSize || 4}> <TextMask details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantIncomeDetails[fieldItemName].type == 'radio')
                return <Grid item xs={12} md={colSize || 4}> <Radiobutton details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantIncomeDetails[fieldItemName].type == 'switch')
                return <Grid item xs={12} md={colSize || 4}> <SwitchComponent details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantIncomeDetails[fieldItemName].type == 'dropdown')
                return <Grid item xs={12} md={colSize || 4}> <Dropdown details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else
                return <Grid item xs={12} md={colSize || 4}> empty </Grid>
        }
    }

    const printCurrentFormikErrors = () => {
        let arrayOfFormikErrors = []
        let currentStep;
        for (var key in formik.errors) {
            if (formik.errors.hasOwnProperty(key)) {
                if (props.applicationFieldDetails.basicDetails.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => { 
                                props.setActiveStep(0) 
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.addressDetails.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(1) 
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.incomeProof.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(2) 
                            }}
                        />
                    )
                }
                else { }
            }
        }
        return arrayOfFormikErrors
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={showBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={3} className={classes.incomeForm}>
                {getElementToBeRendered("incomeSource", 6)}

                {
                    ["incomeFromBusinessProfession"].includes(formik.values["incomeSource"]) && (
                        getElementToBeRendered("businesstype", 6)
                    )
                }

                <Grid container spacing={1}>
                    {getElementToBeRendered("isOfficeAddressDelivery", 12)}
                </Grid>

                {
                    ((formik.values["isOfficeAddressDelivery"] === true || ["salary"].includes(formik.values["incomeSource"])) && props.applicantIncomeDetails['officeAddressFlat']) && (
                        <>
                            <Grid container spacing={1} className={classes.sectionHeading}>
                                <Typography variant="h6">Office Address (as per given proof)</Typography>
                            </Grid>

                            {orderedFieldsForOfficeAddress.map(field => {
                                return getElementToBeRendered(field, 4)
                            })}
                        </>
                    )
                }

                <Grid container spacing={1} className={classes.sectionHeading}>
                    <Typography variant="h6">Proofs to be submitted</Typography>
                </Grid>
                {getElementToBeRendered("individualProofIdentity", 6)}
                {getElementToBeRendered("individualProofDob", 6)}
                {getElementToBeRendered("individualProofResidence", 6)}
                {(formik.values["isOfficeAddressDelivery"] === true || formik.values["incomeSource"] === "salary") && getElementToBeRendered("individualProofOffice", 6)}
                {
                    !(
                        formik.values.individualProofIdentity === "Aadhaar Card" ||
                        formik.values.individualProofDob === "Aadhaar Card" ||
                        formik.values.individualProofResidence === "Aadhaar Card"
                    ) &&
                    getElementToBeRendered("mandatoryProof", 6)
                }
                {
                    (!["freshForeignIndia", "freshForeignAbroad"].includes(formik.values.formType) && (formik.errors && Object.keys(formik.errors).length > 0)) && (
                        <>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6" className={classes.sectionHeading}>Please fill the below required fields</Typography>
                                </Grid>
                            </Grid>
                            {printCurrentFormikErrors()}
                        </>
                    )
                }
            </Grid>
        </React.Fragment>
    )
};

export default IncomeDetails;