import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../../Session";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import { useFormik } from 'formik';

import TextBox from '../../../ui-elements/textbox';
import Dropdown from '../../../ui-elements/dropdown';
import Radiobutton from '../../../ui-elements/radiobutton';
import TextMask from '../../../ui-elements/textmask';
import Checkbox from '../../../ui-elements/checkbox';
import { StepTitle, SubHeading } from '../../../ui-elements/headers';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NameComponent from '../../../ui-elements/namebox';
import SwitchComponent from '../../../ui-elements/switch';
import Stepper from '../Stepper';
import Schema from '../../../lib/Validation/freshaddressschema';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../../constants/colors';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    addressForm: {
        paddingLeft: 20
    },
    inputGrids: {
        padding: "2px"
    },
    sectionHeading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        backgroundColor: "#d3d3d3",
        paddingLeft: theme.spacing(1),
        borderRadius: 5
    },
    separateSection: {
        marginTop: 5
    },
    representativeSection: {
        marginTop: 10,
        backgroundColor: "#d3d3d3",
        paddingLeft: theme.spacing(1),
        borderRadius: 5
    },
    representativeDescription: {
        fontSize: 13
    },
    representativeInfo: {
        position: "relative"
    },
    inforIconDivOne: {
        position: "absolute",
        right: -20,
        top: 12
    },
    inforIconDivTwo: {
        position: "absolute",
        right: -10,
        top: 15
    },
    inforIconLabelDiv: {
        position: "absolute",
        right: 10,
        top: 0
    },
    inforIcon: {
        fontSize: 18,
        cursor: "pointer"
    }
}));


const CustomToolTip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12,
    },
}))(Tooltip);


const AddressDetails = props => {
    const classes = useStyles();
    const formik = props.formik;

    const [showBackDrop, setShowBackDrop] = useState(false);

    // setApplicationsDetails
    useEffect(() => {
        var postalCode = formik.values['residenceAddressPincode'] && formik.values['residenceAddressPincode']

        if (postalCode && /^[[0-9]{3}\s[0-9]{3}]*$/.test(postalCode)) {
            setShowBackDrop(true)
            postalCode = postalCode.replace(/ /g, '')
            fetch(`https://api.postalpincode.in/pincode/${postalCode}`)
                .then((res) => res.json())
                .then((res) => {
                    formik.values.residenceAddressCity = res[0].PostOffice[0].District
                    formik.values.residenceAddressState = res[0].PostOffice[0].State
                    formik.onChange({})
                    setShowBackDrop(false)
                })
                .catch(err => {
                    console.log(err);
                    setShowBackDrop(false);
                })
        }

    }, [formik.values['residenceAddressPincode']])

    useEffect(() => {
        var postalCode = formik.values['officeAddressPincode'] && formik.values['officeAddressPincode']

        if (postalCode && /^[[0-9]{3}\s[0-9]{3}]*$/.test(postalCode)) {
            setShowBackDrop(true)
            postalCode = postalCode.replace(/ /g, '')
            fetch(`https://api.postalpincode.in/pincode/${postalCode}`)
                .then((res) => res.json())
                .then((res) => {
                    formik.values.officeAddressCity = res[0].PostOffice[0].District
                    formik.values.officeAddressState = res[0].PostOffice[0].State
                    formik.onChange({})
                    setShowBackDrop(false)

                })
                .catch(err => {
                    console.log(err);
                    setShowBackDrop(false);
                })
        }

    }, [formik.values['officeAddressPincode']])

    useEffect(() => {
        var postalCode = formik.values['representativeAddressPincode'] && formik.values['representativeAddressPincode']

        if (postalCode && /^[[0-9]{3}\s[0-9]{3}]*$/.test(postalCode)) {
            setShowBackDrop(true)
            postalCode = postalCode.replace(/ /g, '')
            fetch(`https://api.postalpincode.in/pincode/${postalCode}`)
                .then((res) => res.json())
                .then((res) => {
                    formik.values.representativeAddressCity = res[0].PostOffice[0].District
                    formik.values.representativeAddressState = res[0].PostOffice[0].State
                    formik.values.representativeAddressCountry = res[0].PostOffice[0].Country
                    formik.onChange({})
                    setShowBackDrop(false)
                })
                .catch(err => {
                    console.log(err);
                    setShowBackDrop(false);
                })
        }

    }, [formik.values['representativeAddressPincode']])

    const orderedFieldsForResidenceAddress = ["residenceAddressFlat", "residenceAddressBuilding", "residenceAddressRoad", "residenceAddressArea", "residenceAddressPincode", "residenceAddressCity", "residenceAddressState", "residenceAddressCountry"]
    const orderedFieldsForRepresentativeName = ["representativeLastName", "representativeFirstName", "representativeMiddleName"]
    const orderedFieldsForRepresentativeAddress = ["representativeAddressFlat", "representativeAddressVillage", "representativeAddressRoad", "representativeAddressArea", "representativeAddressPincode", "representativeAddressCity", "representativeAddressState", "representativeAddressCountry"]
    const orderedFieldsForOfficeAddress = ["officeAddressBuilding", "officeAddressFlat", "officeAddressVillage", "officeAddressRoad", "officeAddressArea", "officeAddressPincode", "officeAddressCity", "officeAddressState", "officeAddressCountry"]

    const getElementToBeRendered = (fieldItemName, colSize) => {
        if (props.applicantAddressDetails[fieldItemName]) {
            if (props.applicantAddressDetails[fieldItemName].type == 'text' || props.applicantAddressDetails[fieldItemName].type == 'email')
                return <Grid item xs={12} md={colSize || 4}> <TextBox details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'name')
                return <Grid item xs={12} md={colSize || 4} className={classes.inputGrids}> <NameComponent details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'number')
                return <Grid item xs={12} md={colSize || 4}> <TextMask details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'switch')
                return <Grid item xs={12} md={colSize || 4}> <SwitchComponent details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'radio')
                return <Grid item xs={12} md={colSize || 4}> <Radiobutton details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'dropdown')
                return <Grid item xs={12} md={colSize || 4}> <Dropdown details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else
                return <Grid item xs={12} md={colSize || 4}></Grid>
        }
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={showBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={3} className={classes.addressForm}>

                <Grid container className={classes.sectionHeading}>
                    {
                        ['Individual', 'HUF', 'AJP', 'AOP', 'BOI'].includes(formik.values.applicationCategory) ?
                            (
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Residence Address (as per given proof)</Typography>
                                </Grid>
                            ) :
                            (
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Office Address (as per given proof)</Typography>
                                </Grid>
                            )
                    }

                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {orderedFieldsForResidenceAddress.map(field => {
                        return getElementToBeRendered(field, 4)
                    })}
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {orderedFieldsForOfficeAddress.map(field => {
                        return getElementToBeRendered(field, 4)
                    })}
                </Grid>

                <Grid container spacing={3} className={classes.separateSection}>
                    {getElementToBeRendered("emailAddress", 4)}
                    {getElementToBeRendered("mobileNumber", 4)}
                    {getElementToBeRendered("countryCode", 4)}
                    {getElementToBeRendered("mobileNumberForeign", 4)}
                </Grid>

                <Grid container spacing={1} className={classes.representativeSection}>
                    <Grid item className={classes.representativeInfo}>
                        {
                            props.applicationMetaData.formType === "freshMinor" ?
                                (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h6">
                                                Representative Assessee (Mandatory for a Minor)
                                            </Typography>
                                        </Grid>
                                        <CustomToolTip className={classes.inforIconDivOne} title={"Representative Assessee details to be filled only in special cases like Minor, lunatic, idiot, etc., as provided u/s 160 of Income-tax Act, 1961"} placement="right">
                                            <InfoIcon className={classes.inforIcon} />
                                        </CustomToolTip>
                                    </>
                                ) :
                                (
                                    <>
                                        {getElementToBeRendered("isRepresentativeAssessee", 12)}
                                        <CustomToolTip className={classes.inforIconDivTwo} title={"Representative Assessee details to be filled only in special cases like Minor, lunatic, idiot, etc., as provided u/s 160 of Income-tax Act, 1961"} placement="right">
                                            <InfoIcon className={classes.inforIcon} />
                                        </CustomToolTip>
                                    </>
                                )
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("whichRepresentative", 12)}
                </Grid>

                {
                    formik.values["isRepresentativeAssessee"] === true ? (
                        <>
                            <Grid container spacing={1} className={classes.separateSection}>
                                {getElementToBeRendered("representativeTitleOnName", 12)}
                            </Grid>
                            {
                                formik.values.representativeTitleOnName !== null && (
                                    <>
                                        <Grid container spacing={3} className={classes.separateSection}>
                                            {orderedFieldsForRepresentativeName.map(field => {
                                                return getElementToBeRendered(field, 4)
                                            })}
                                        </Grid>

                                        <Grid container spacing={3} className={classes.sectionHeading}>
                                            <Typography variant="h6">Representative Address (as per given proof)</Typography>
                                        </Grid>

                                        <Grid container spacing={3} className={classes.separateSection}>
                                            {orderedFieldsForRepresentativeAddress.map(field => {
                                                return getElementToBeRendered(field, 4)
                                            })}
                                        </Grid>
                                    </>
                                )
                            }
                        </>
                    ) : ""
                }

            </Grid>
        </React.Fragment>
    )
};


export default AddressDetails;
