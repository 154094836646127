import React from 'react'
import './loading.css';

export default function index(props) {
    return (
        <div className={'loading-container'}>
            <div className={'loading-hexagon'}>
                <div className={'loading-photo'}></div>
            </div>
            <h6 style={{ margin: "auto", fontFamily: "sans-serif", color: "gray" }}>{props.loadingMsg}</h6>
        </div>
    )
}