import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../../Session";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import { useFormik } from 'formik';

import TextBox from '../../../ui-elements/textbox';
import Dropdown from '../../../ui-elements/dropdown';
import Radiobutton from '../../../ui-elements/radiobutton';
import SwitchComponent from '../../../ui-elements/switch';
import DateComponent from '../../../ui-elements/datecomponent';
import Checkbox from '../../../ui-elements/checkbox';
import { StepTitle, SubHeading } from '../../../ui-elements/headers';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '../Stepper';
import Schema from '../../../lib/Validation/freshincomeschema';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
    formikErrors: {
        color: '#B80000 ',
        border: '1px solid red',
        marginRight: 10,
        marginBottom: 5,
        opacity: 0.9
    },
    sectionHeading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        backgroundColor: "#d3d3d3",
        paddingLeft: theme.spacing(1),
        borderRadius: 5
    },
    errorIcon: {
        color: '#A00000',
        opacity: 0.8
    },
    incomeForm: {
        paddingLeft: 20
    },
    separateSection: {
        marginTop: 1
    }
}));

const IncomeDetails = props => {
    const classes = useStyles();

    const formik = props.formik;

    const orderedFieldsForOfficeAddress = ["officeAddressFlat", "officeAddressBuilding", "officeAddressArea", "officeAddressRoad", "officeAddressPincode", "officeAddressCity", "officeAddressCountry", "officeAddressState"]

    const getElementToBeRendered = (fieldItemName, colSize) => {
        if (props.applicantIncomeDetails[fieldItemName]) {
            if (props.applicantIncomeDetails[fieldItemName].type == 'text' || props.applicantIncomeDetails[fieldItemName].type == 'number' || props.applicantIncomeDetails[fieldItemName].type == 'email' || props.applicantIncomeDetails[fieldItemName].type == 'date')
                return <Grid item xs={12} md={4}> <TextBox details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantIncomeDetails[fieldItemName].type == 'radio')
                return <Grid item xs={12} md={4}> <Radiobutton details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantIncomeDetails[fieldItemName].type == 'switch')
                return <Grid item xs={12} md={4}> <SwitchComponent details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantIncomeDetails[fieldItemName].type == 'dropdown')
                return <Grid item xs={12} md={4}> <Dropdown details={props.applicantIncomeDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else
                return <Grid item xs={12} md={4}> empty </Grid>
        }
    }

    const printCurrentFormikErrors = () => {
        let arrayOfFormikErrors = []
        let currentStep;
        for (var key in formik.errors) {
            if (formik.errors.hasOwnProperty(key)) {
                if (props.applicationFieldDetails.basicDetails.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(0)
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.addressDetails.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(1)
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.proofDetails && props.applicationFieldDetails.proofDetails.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(2)
                            }}
                        />
                    )
                }
                else if (props.applicationFieldDetails.incomeProof && props.applicationFieldDetails.incomeProof.hasOwnProperty(key)) {
                    arrayOfFormikErrors.push(
                        <Chip
                            icon={<ErrorIcon className={classes.errorIcon} />}
                            label={formik.errors[key]}
                            variant="outlined"
                            className={classes.formikErrors}
                            onClick={() => {
                                props.setActiveStep(2)
                            }}
                        />
                    )
                }
                else { }
            }
        }
        return arrayOfFormikErrors
    }


    return (
        <React.Fragment>

            <Grid container spacing={3} className={classes.incomeForm}>

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("isPhotoCorrection", 12)}
                </Grid>
                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("isSignatureCorrection", 12)}
                </Grid>

                <Grid container spacing={1} className={classes.sectionHeading}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6">Proofs to be submitted</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("panProof")}
                </Grid>
                <Grid container spacing={2} className={classes.separateSection}>
                    {getElementToBeRendered("proofOfDob", 6)}
                    {getElementToBeRendered("proofOfIdentity", 6)}
                    {formik.values["isOfficeAddressDelivery"] === true && getElementToBeRendered("proofOfOffice", 6)}
                    {formik.values["isOfficeAddressDelivery"] === false && getElementToBeRendered("proofOfResidence", 6)}
                </Grid>
                {
                    (formik.errors && Object.keys(formik.errors).length > 0) && (
                        <>
                            <Grid container className={classes.sectionHeading}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">Please fill the below required fields</Typography>
                                </Grid>
                            </Grid>
                            {printCurrentFormikErrors()}
                        </>
                    )
                }
            </Grid>
        </React.Fragment>
    )
};

export default IncomeDetails;