import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    CheckBoxCompon: {
        width: "100%"
    },
    radioLabel: {
        fontSize: 11,
    },
    radioTitle: {
        fontSize: 14
    }
}));

const CheckBoxCompon = props => {
    const classes = useStyles();

    const printCheckBoxes = () => {
        let arrayOfCheckBoxes = []

        for (let fields in props.details.options) {
            const field = props.details.options[fields];
            if (props.formik.values.gender === 'male' && field.value === "Husband's Name")
                continue
            arrayOfCheckBoxes.push(
                <FormControlLabel
                    checked={props.value && props.value.includes(field.value)}
                    value={field.value}
                    id={props.id}
                    name={props.id}
                    control={<Checkbox color="primary" />}
                    label={field.label}
                    classes={{
                        label: classes.radioLabel
                    }}
                    disabled={props.details.disabled}
                />
            )
        }
        return arrayOfCheckBoxes
    }

    return (
        <FormControl component="fieldset">
            <div>
                <FormLabel component="legend" className={classes.radioTitle}>{props.details.label}</FormLabel>
                <FormGroup row error={(props.formik.errors[props.id] && props.formik.touched[props.id])} aria-label={props.details.label} name={props.id} value={props.value || props.details.default || ""} onChange={(event) => { props.onChange(event); }} onBlur={(event) => props.onBlur(event)}>
                    {printCheckBoxes()}
                </FormGroup>
            </div>
        </FormControl>
    )
};

export default CheckBoxCompon;