import * as Yup from 'yup';

const initialNameArray = [
    "AB", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AR", "AS", "AU", "AW", "BE", "BI", "BO", "BP", "CH",
    "CO", "CY", "DA", "DE", "DI", "DN", "DO", "DU", "EE", "EK", "EM", "EP", "ER", "ES", "EX", "FA", "FE", "FK", "FA",
    "FE", "FK", "FU", "GH", "GI", "GO", "GU", "HA", "HE", "HO", "HU", "IB", "ID", "II", "IK", "IL", "IM", "IN", "IV",
    "JA", "JE", "JI", "JO", "JR", "JU", "KA", "KC", "KE", "KH", "KI", "KJ", "KM", "KO", "KS", "KU", "LA", "LE", "LI",
    "LO", "LU", "MA", "MC", "MD", "MG", "MH", "MI", "MO", "MU", "NA", "NG", "NI", "NO", "OD", "OE", "OH", "OI", "OJ",
    "OK", "OM", "ON", "OR", "OS", "OU", "OZ", "PA", "PI", "PO", "PT", "PU", "QI", "RA", "AI", "RU", "SA", "SE", "SI",
    "SK", "SM", "SO", "SR", "SU", "TA", "TE", "TI", "TO", "TU", "UG", "UL", "UM", "UN", "UR", "UZ", "VK", "WO", "WU",
    "XI", "XU", "YE", "YH", "YI", "YJ", "YO", "YU", "ZA", "ZI", "ZU"
]

const Schema = Yup.object().shape({

    gender: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Gender is required").nullable(),
            }
        ),

    maritalStatus: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["freshMinor"].includes(val),
                            then: Yup.string().required("Marital Status is required").nullable(),
                        }
                    )
            }
        ),

    titleOnName: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Salutation on Name is required").nullable(),
            }
        ),

    lastName: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('lastName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less").required('Last Name/ Surname is mandatory'),
                        }
                    )
            }
        ).nullable(),

    firstName: Yup.string()
        .when('lastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'firstName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string(),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less")
                    }
                ),
                otherwise: Yup.string().max(0, "Last Name/ Surname must be filled")
            }
        ).nullable(),

    middleName: Yup.string()
        .when('firstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'middleName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string(),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less")
                    }
                ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ).nullable(),

    nameOnPanCard: Yup.string().required('Select a valid name to be printed on PAN Card').nullable(),

    nonIndividualName: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["Individual"].includes(val),
                then: Yup.string().required('Organisation name is required').nullable(),
            }
        ).nullable(),

    dateOfBirth: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Date of Birth is required"),
                otherwise: Yup.string().required("Date of Formation is required")
            }
        ).nullable(),

    parentsName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().required("Parents Name is required")
        }
    ).nullable(),

    singleParent: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('parentsName',
                {
                    is: (val) => val === "mothersName",
                    then: Yup.string().required("Single Parent is required")
                }
            )
        }
    ).nullable(),

    fathersLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('parentsName',
                {
                    is: (val) => val === "fathersName",
                    then: Yup.string()
                        .when(
                            'fathersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Father Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Father Last Name/ Surname is mandatory'),
                            }
                        )
                }
            )
        }
    ).when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('singleParent',
                {
                    is: (val) => val === "no",
                    then: Yup.string()
                        .when(
                            'fathersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Father Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Father Last Name or Surname is mandatory'),
                            }
                        )
                }
            )
        }
    )
        .nullable(),

    mothersLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('singleParent',
                {
                    is: (val) => val === "yes",
                    then: Yup.string()
                        .when(
                            'mothersLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Mother Last Name is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Mother Last Name/ Surname is mandatory'),
                            }
                        )
                }
            )
                .when('parentsName',
                    {
                        is: (val) => val === "mothersName",
                        then: Yup.string()
                            .when(
                                'mothersLastName',
                                {
                                    is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                    then: Yup.string().required("Last Name is required"),
                                    otherwise: Yup.string()
                                        .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                        .max(40, "Must be 40 characters or less").required('Mother Last Name/ Surname is mandatory'),
                                }
                            )
                    }
                )
        }
    ).nullable(),


    fathersFirstName: Yup.string()
        .when('fathersLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'fathersFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ),

    mothersFirstName: Yup.string()
        .when('mothersLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'mothersFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ),

    fathersMiddleName: Yup.string()
        .when('fathersFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'fathersMiddleName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ),

    mothersMiddleName: Yup.string()
        .when('mothersFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'mothersMiddleName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ),

    nickLastName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('isNickName',
                {
                    is: (val) => val === true,
                    then: Yup.string()
                        .when(
                            'nickLastName',
                            {
                                is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                                then: Yup.string().required("Other Last Name/ Surname is required"),
                                otherwise: Yup.string()
                                    .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                    .max(40, "Must be 40 characters or less").required('Other Last Name/ Surname is mandatory'),
                            }
                        )
                }
            )
        }
    ).nullable(),


    nickFirstName: Yup.string()
        .when('nickLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'nickFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ).nullable(),

    nickMiddleName: Yup.string()
        .when('nickFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string().when(
                    'nickMiddleName',
                    {
                        is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                        then: Yup.string().required("Last Name/ Surname is required"),
                        otherwise: Yup.string()
                            .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                            .max(40, "Must be 40 characters or less"),
                    }
                ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ).nullable(),


    aadhaarCard: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Do you have aadhaar card?").nullable(),
            }
        ),

    aadhaarNumber: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('aadhaarCard',
                {
                    is: (val) => val === "yes",
                    then: Yup.string().matches(/^\d{4}\s\d{4}\s\d{4}$/,
                        "Aadhaar is always twelve digit number").required("Aadhaar Number is mandatory"),
                }
            )
        }
    ).nullable(),

    aadhaarName: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('aadhaarCard',
                {
                    is: (val) => val === "yes",
                    then: Yup.string().required("Aadhaar Name is mandatory")
                        .matches(/^[a-zA-Z'.,-\s]*$/, 'Numbers are not permitted')
                        .max(26, 'Must have atmost 26 characters')
                }
            )
        }
    ).nullable(),

    aadhaarEnroll: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('aadhaarCard',
                {
                    is: (val) => val === "no",
                    then: Yup.string().min(3, 'Too Short!')
                        .max(26, 'Must have atmost 26 characters')
                        .required('Enrolment Name is Required')
                }
            )
        }
    ).nullable(),

    aadhaarEnrollNumber: Yup.string().when('applicationCategory',
        {
            is: (val) => ["Individual"].includes(val),
            then: Yup.string().when('aadhaarCard',
                {
                    is: (val) => val === "no",
                    then: Yup.string()
                        .required('Enroll Number is mandatory')
                        .matches(/^\d{4}["/"]\d{4}["/"]\d{4}$/,
                            "Enroll NUmber must contain 14 numbers")
                }
            )
        }
    ).nullable(),

    //Address Details

    residenceAddressFlat: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Residence Flat is required"),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["freshNriAbroad", "freshForeignAbroad"].includes(val),
                            then: Yup.string()
                                .max(25,
                                    "Must be 25 characters or less").nullable().required("Residence Flat is required"),
                        }
                    )
            }
        ),

    residenceAddressRoad: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Residence Street is required"),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["freshNriAbroad", "freshForeignAbroad"].includes(val),
                            then: Yup.string()
                                .max(25,
                                    "Must be 25 characters or less").nullable().required("Residence Street is required"),
                        }
                    )
            }
        ),

    residenceAddressArea: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Residence Area is required"),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["freshNriAbroad", "freshForeignAbroad"].includes(val),
                            then: Yup.string()
                                .max(25,
                                    "Must be 25 characters or less").nullable().required("Residence Area is required"),
                        }
                    )
            }
        ),

    residenceAddressPincode: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().nullable()
                    .required("Pincode is required")
                    .min(7, "Invalid Pincode")
                    .max(7, "Invalid Pincode"),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().nullable()
                    .required("Pincode is required")
                    .min(7, "Invalid Pincode")
                    .max(7, "Invalid Pincode"),
            }
        ),

    residenceAddressCity: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Residence City is required"),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["freshNriAbroad", "freshForeignAbroad"].includes(val),
                            then: Yup.string()
                                .max(25,
                                    "Must be 25 characters or less").nullable().required("Residence City is required"),
                        }
                    )
            }
        ),

    residenceAddressState: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Residence State is required"),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .when('formType',
                        {
                            is: (val) => !["freshNriAbroad", "freshForeignAbroad"].includes(val),
                            then: Yup.string()
                                .max(25,
                                    "Must be 25 characters or less").nullable().required("Residence State is required"),
                        }
                    )
            }
        ),

    residenceAddressCountry: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("Residence Country is required"),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string()
                    .max(25, "Must be 25 characters or less")
                    .required("Residence Country is required")
                    .nullable(),
            }
        ),

    // Representative Section

    representativeLastName: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().matches(/^[a-zA-Z0-9_]{2,}([ ]*[a-zA-Z0-9_]{2,})*$/, 'Initials are not permitted, Expand all initials').max(40,
                    "Must be 40 characters or less").required('Last Name or Surname is mandatory').nullable(),
            }
        ),

    representativeFirstName: Yup.string()
        .when('representativeLastName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'representativeFirstName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("Last Name/ Surname is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "Last Name must be filled")
            }
        ),

    representativeMiddleName: Yup.string()
        .when('representativeFirstName',
            {
                is: (val) => val && val.length >= 2,
                then: Yup.string()
                    .when(
                        'representativeMiddleName',
                        {
                            is: (val) => val && initialNameArray.includes(val.split(' ').pop().toUpperCase()),
                            then: Yup.string().required("First Name is required"),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z_]{3,}([ ]*[a-zA-Z_]{3,})*$/, 'Initials and Numbers are not permitted')
                                .max(40, "Must be 40 characters or less"),
                        }
                    ),
                otherwise: Yup.string().max(0, "First Name must be filled")
            }
        ),

    representativeTitleOnName: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().required("Salutation on Name is required").nullable(),
            }
        ),

    representativeAddressArea: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("representative Area is required"),
            }
        ),
    representativeAddressCity: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("representative City is required"),
            }
        ),
    representativeAddressCountry: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("representative Country is required"),
            }
        ),
    representativeAddressFlat: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("representative Flat is required"),
            }
        ),
    representativeAddressPincode: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().nullable()
                    .required("Pincode is required")
                    .min(7, "Invalid Pincode")
                    .max(7, "Invalid Pincode"),
            }
        ),
    representativeAddressRoad: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").nullable().required("representative Road is required"),
            }
        ),
    representativeAddressState: Yup.string()
        .when('isRepresentativeAssessee',
            {
                is: (val) => val === true,
                then: Yup.string().min(3, "representative State is atleast 3 characters").max(25,
                    "Must be 25 characters or less").nullable().required("representative State is required"),
            }
        ),

    // Office Address

    isOfficeAddressDelivery: Yup.boolean().required('Choose Address PAN to be delivered'),

    officeAddressArea: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Area is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Area is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Area is required as income source is selcted as salary"),
            }
        ).nullable(),

    officeAddressBuilding: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Building is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Building is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Building is required as income source is selcted as salary"),
            }
        ).nullable(),

    officeAddressCity: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office City is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office City is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office City is required as income source is selcted as salary"),
            }
        ).nullable(),

    officeAddressCountry: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Country is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Country is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Country is required as income source is selcted as salary"),
            }
        ).nullable(),

    officeAddressFlat: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Flat is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Flat is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Flat is required as income source is selcted as salary"),
            }
        ).nullable(),

    officeAddressRoad: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Road is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Road is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Road is required as income source is selcted as salary"),
            }
        ).nullable(),

    officeAddressState: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office location is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office location is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office location state is required as income source is selcted as salary"),
            }
        ).nullable(),

    officeAddressPincode: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => !["HUF", "AJP", "AOP", "BOI"].includes(val) && !["Individual"].includes(val),
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Postal code is required as delivery is selcted as office"),
            }
        )
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().max(25,
                    "Must be 25 characters or less").required("Office Postal code is required as delivery is selcted as office"),
            }
        )
        .when('incomeSource',
            {
                is: (val) => val === ('salary'),
                then: Yup.string().required("Office Postal code is required as income source is selcted as salary")
                    .min(7, "Invalid Pincode")
                    .max(7, "Invalid Pincode"),
            }
        ).nullable(),

    // income details

    emailAddress: Yup.string().nullable().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email Id must be valid").required('Email ID is required').email('Email Id must be valid'),

    mobileNumber: Yup.string().nullable()
        .when('formType',
            {
                is: (val) => !["freshNriAbroad", "freshForeignAbroad"].includes(val),
                then: Yup.string().nullable()
                    .required("Mobile Number is required")
            }
        ),

    mobileNumberForeign: Yup.string().nullable()
        .when('formType',
            {
                is: (val) => ["freshNriAbroad", "freshForeignAbroad"].includes(val),
                then: Yup.string().nullable()
                    .required("Mobile Number is required").max(13, "Mobile Number must have atmost 13 numbers"),
            }
        ),

    countryCode: Yup.string()
        .when('formType',
            {
                is: (val) => ["freshNriAbroad", "freshForeignAbroad"].includes(val),
                then: Yup.string().nullable()
                    .required("Country Code is required")
            }
        ),

    countryCitizenship: Yup.string()
        .when('formType',
            {
                is: (val) => ["freshNriAbroad", "freshForeignAbroad"].includes(val),
                then: Yup.string().nullable()
                    .required("Country Citizenship is required")
            }
        ),

    isdCitizenship: Yup.string()
        .when('formType',
            {
                is: (val) => ["freshNriAbroad", "freshForeignAbroad"].includes(val),
                then: Yup.string().nullable()
                    .required("ISD Code is required")
            }
        ),

    incomeSource: Yup.string().nullable().required("Share your source of income"),

    businesstype: Yup.string().nullable().when('incomeSource',
        {
            is: (val) => val === 'incomeFromBusinessProfession',
            then: Yup.string().required("Select a valid business or profession")
        }
    ),

    individualProofIdentity: Yup.string().required("Select ID Proof document type").nullable(),

    individualProofResidence: Yup.string()
        .when('applicationCategory',
            {
                is: (val) => ["Individual"].includes(val),
                then: Yup.string().required("Select address proof document type").nullable(),
            }
        )
        .when('applicationCategory',
            {
                is: (val) => ["HUF", "AJP", "AOP", "BOI"].includes(val),
                then: Yup.string().required("Select address proof document type").nullable(),
            }
        ),
    individualProofOffice: Yup.string().nullable()
        .when('isOfficeAddressDelivery',
            {
                is: (val) => val === true,
                then: Yup.string().required("Select Office address proof document type").nullable(),
            }
        ),

    individualProofDob: Yup.string().required("Select Date of Birth proof").nullable(),

    //KYC Details

    overseasCategory: Yup.string()
        .when("formType",
            {
                is: val => ["freshForeignAbroad", "freshForeignIndia"].includes(val),
                then: Yup.string().required("Overseas Category is required")
            }).nullable(),

    kycMaritalStatus: Yup.string()
        .when("overseasCategory",
            {
                is: val => val == "Individual",
                then: Yup.string().required("KYC marital status is required")
            }).nullable(),

    citizenshipStatus: Yup.string()
        .when("overseasCategory",
            {
                is: val => val == "Individual",
                then: Yup.string().required("KYC citizenship status is required")
            }).nullable(),

    citizenshipCountry: Yup.string()
        .when("citizenshipStatus",
            {
                is: val => val == "foreigner",
                then: Yup.string().required("KYC citizenship country is required")
            }).nullable(),

    occupationDetails: Yup.string()
        .when("overseasCategory",
            {
                is: val => val == "Individual",
                then: Yup.string().required("KYC occupation details is required")
            }).nullable(),

    typeOfIncome: Yup.string()
        .when("overseasCategory",
            {
                is: val => val == "Non Individual",
                then: Yup.string().required("KYC type of income is required")
            }).nullable(),

    isStockExchange: Yup.boolean()
        .when("typeOfIncome",
            {
                is: val => val == "public",
                then: Yup.boolean().required("Do you have stock exchange is required")
            }).nullable(),

    nameStockExchange: Yup.string()
        .when("isStockExchange",
            {
                is: val => val == true,
                then: Yup.string().required("KYC stock exchange name is required")
            }).nullable(),

    isBeneficialOwnership: Yup.boolean()
        .when("overseasCategory",
            {
                is: val => val == "Non Individual",
                then: Yup.boolean().required("KYC beneficial ownership is required")
            }).nullable(),

    taxpayerIdentification: Yup.string()
        .when("formType",
            {
                is: val => ["freshForeignAbroad", "freshForeignIndia"].includes(val),
                then: Yup.string().required("KYC Tax payer identification is required")
            }).nullable(),

    kycGrossAnnual: Yup.string()
        .when("formType",
            {
                is: val => ["freshForeignAbroad", "freshForeignIndia"].includes(val),
                then: Yup.string().required("KYC Gross annual is required")
            }).nullable(),

    kycNetworth: Yup.string()
        .when("formType",
            {
                is: val => ["freshForeignAbroad", "freshForeignIndia"].includes(val),
                then: Yup.string().required("KYC Net worth is required")
            }).nullable()
});

export default Schema;