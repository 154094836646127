import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import * as ROUTES from "../../constants/routes";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: 'none',
        marginTop: theme.spacing(10)
    },
    previousPath: {
        textDecoration: 'none',
        marginLeft: 5
    },
    previousPathArrow: {
        marginLeft: 15
    }
}))

const BreadCrumbs = (props) => {
    const classes = useStyles();
    const [prevPathName, setPrevPathName] = useState();
    const [currentPathName, setCurrentPathName] = useState();

    useEffect(() => {
        setPrevPathName(currentPathName)
        setCurrentPathName(props.history.location.pathname)
    }, [props])

    const pageRequiredBreadCrumbs = [ROUTES.APPLY_FORM, ROUTES.APPLIED_FORMS]
    const routes = {
        [ROUTES.APPLY_FORM]: 'Select Forms',
        [ROUTES.APPLIED_FORMS]: 'Applications'
    }
    return (
        <>
            <Paper className={classes.paper}>
                {
                    (pageRequiredBreadCrumbs.includes(prevPathName) && prevPathName !== currentPathName) && (

                        <Grid container>
                            <Grid item className={classes.previousPathArrow}>
                                <Typography color="primary" component={Link} to={prevPathName}>
                                    <ArrowBackIcon />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography color="primary" component={Link} to={prevPathName} className={classes.previousPath}>
                                    {routes[prevPathName]}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }
            </Paper>
        </>
    )
}

export default withRouter(BreadCrumbs)