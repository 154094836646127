import React, { useEffect, useState } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import { Link, withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const FormPage = () => (
  <section id="features" className="feature-section feature-style-6">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-8">
          <div className="section-title text-center mb-30">
            <h6 className="mb-15">We have 50 possible application Forms</h6>
            <p>
              We understand, each one of us has a unique PAN requirements. Let's
              get started by picking an exact form variant.
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", margin: "25px" }}>
        <div style={{ display: "flex", height: "25px", width: "50%" }}>
          <div
            style={{
              width: "24px",
              background: "black",
              borderRadius: "50%"
            }}
          />
          <div
            style={{
              width: "100%",
              height: "8px",
              background: "black",
              marginLeft: "-2%",
              marginTop: "8px"
            }}
          />
        </div>
        <div style={{ display: "flex", height: "25px", width: "50%" }}>
          <div
            style={{
              width: "24px",
              background: "black",
              borderRadius: "50%"
            }}
          />
          <div
            style={{
              width: "100%",
              height: "8px",
              background: "black",
              marginLeft: "-2%",
              marginTop: "8px"
            }}
          />
        </div>
      </div>
      <Form />
    </div>
  </section>
);

const Form = () => {
  const [showFormTypes, setShowFormTypes] = useState(true);
  const [showFormCategories, setShowFormCategories] = useState(false);
  const [showFormVariants, setShowFormVariants] = useState(false);

  const onShowFormCategoriesClick = () => {
    setShowFormCategories(!showFormCategories);
  };
  const onShowFormVariantsClick = () => {
    setShowFormVariants(!showFormVariants);
  };

  return (
    <div className="single-feature">
      <div className="row">
        {showFormTypes && (
          <FormTypes setShowFormCategories={onShowFormCategoriesClick} />
        )}
        {showFormCategories && (
          <FormCategories setShowFormVariants={onShowFormVariantsClick} />
        )}
        {showFormVariants && <FormVariants />}
      </div>
    </div>
  );
};

const FormTypes = props => {
  return (
    <div className="col-4">
      <p style={{ fontWeight: "500" }}>1.Choose your application type</p>
      <hr />
      <FormItem
        name={"Fresh Application"}
        description={
          "This is the form for applying fresh PAN card for any indian Citizen, who already doesn't have one."
        }
        onClick={props.setShowFormCategories}
      />
      <FormItem
        name={"Correction Form"}
        description={
          "Choose this form to apply for correction is existing PAN Card"
        }
        onClick={props.setShowFormCategories}
      />
    </div>
  );
};
const FormCategories = props => {
  return (
    <div className="col-4">
      <p style={{ fontWeight: "500" }}>2. Pick a Variant</p>
      <hr />
      <FormItem
        name={"NRI"}
        description={
          "This type of form for applying NRI's who staying in Foreign countiresThis is the form for applying fresh PAN card for any indian Citizen, who already doesn't have one."
        }
        onClick={props.setShowFormVariants}
      />
    </div>
  );
};
const FormVariants = () => {
  return (
    <div className="col-4">
      <p style={{ fontWeight: "500" }}>3. Pick a Sub Variant</p>
      <hr />
      <button>Variant</button>
      <Link to={{ pathname: ROUTES.APPLY_FORM, formType: "freshMinor" }}>
        Apply
      </Link>
    </div>
  );
};

const FormItem = props => {
  const [showDescription, setShowDescription] = useState(false);

  const onSingleItemHover = () => {
    setShowDescription(true);
  };

  const onSingleItemLeave = () => {
    setShowDescription(false);
  };

  return (
    <div className="row justify-content-center form-item">
      <div className="col">
        <div
          className="single-item"
          onMouseEnter={() => {
            onSingleItemHover();
          }}
          onMouseLeave={() => {
            onSingleItemLeave();
          }}
          onClick={() => {
            props.onClick();
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="feature-top">
            {!showDescription ? (
              <>
                <div style={{ margin: "auto" }} className="icon">
                  <i class="lni lni-postcard" />
                </div>
                <div style={{ margin: "auto" }} className="content">
                  <h6>{props.name} </h6>
                </div>
              </>
            ) : (
              <div>
                <h6>{props.name} </h6>
                <p
                  style={{
                    marginTop: "10px",
                    marginLeft: "25px",
                    textAlign: "left"
                  }}
                >
                  {props.description}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(FormPage);
