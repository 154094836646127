import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../../Session";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import { useFormik } from 'formik';

import TextBox from '../../../ui-elements/textbox';
import Dropdown from '../../../ui-elements/dropdown';
import Radiobutton from '../../../ui-elements/radiobutton';
import SwitchComponent from '../../../ui-elements/switch';
import TextMask from '../../../ui-elements/textmask';
import NameComponent from '../../../ui-elements/namebox';
import Checkbox from '../../../ui-elements/checkbox';
import { StepTitle, SubHeading } from '../../../ui-elements/headers';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '../Stepper';
import Schema from '../../../lib/Validation/freshaddressschema';
import { makeStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    addressForm:{
        paddingLeft: 20
    },
    separateSection: {
        marginTop: 1
    },
    sectionHeading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        backgroundColor: "#d3d3d3",
        paddingLeft: theme.spacing(1),
        borderRadius: 5
    },
}));
const AddressDetails = props => {

    const classes = useStyles();
    const formik = props.formik;
    const [showBackDrop, setShowBackDrop] = useState(false);

    // setApplicationsDetails
    useEffect(() => {
        var residencePinCode = formik.values['addressPincode'] && formik.values['addressPincode']

        if (residencePinCode && /^[[0-9]{3}\s[0-9]{3}]*$/.test(residencePinCode)) {
            setShowBackDrop(true)
            residencePinCode = residencePinCode.replace(/ /g, '')
            fetch(`https://api.postalpincode.in/pincode/${residencePinCode}`)
                .then((res) => res.json())
                .then((res) => {
                    formik.values.addressCity = res[0].PostOffice[0].District
                    formik.values.addressState = res[0].PostOffice[0].State
                    formik.onChange({})
                    setShowBackDrop(false)
                })
                .catch(err => {
                    console.log(err)
                    setShowBackDrop(false)
                })
        }

    }, [formik.values['addressPincode']])

    const orderedFieldsForAddress = ["addressRoad", "addressArea", "addressPincode", "addressCity", "addressState", "addressCountry"]
    const orderedFieldsForRepresentativeName = ["representativeLastName", "representativeFirstName", "representativeMiddleName"]
    const orderedFieldsForRepresentativeAddress = ["representativeAddressFlat", "representativeAddressArea", "representativeAddressRoad", "representativeAddressPincode", "representativeAddressCity", "representativeAddressState", "representativeAddressCountry"]
    const orderedFieldsForOfficeAddress = ["officeAddressBuilding", "officeAddressFlat", "officeAddressRoad", "officeAddressArea", "officeAddressPincode", "officeAddressCity", "officeAddressCountry", "officeAddressState"]

    const getElementToBeRendered = (fieldItemName, colSize) => {
        if (props.applicantAddressDetails[fieldItemName]) {
            if (props.applicantAddressDetails[fieldItemName].type == 'text' || props.applicantAddressDetails[fieldItemName].type == 'email')
                return <Grid item xs={12} md={4}> <TextBox details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'name')
                return <Grid item xs={12} md={colSize || 4} className={classes.inputGrids}> <NameComponent details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'number')
                return <Grid item xs={12} md={4}> <TextMask details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'switch')
                return <Grid item xs={12} md={4}> <SwitchComponent details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'radio')
                return <Grid item xs={12} md={4}> <Radiobutton details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else if (props.applicantAddressDetails[fieldItemName].type == 'dropdown')
                return <Grid item xs={12} md={4}> <Dropdown details={props.applicantAddressDetails[fieldItemName]} id={fieldItemName} value={formik.values[fieldItemName]} formik={formik} onChange={e => { formik.handleChange(e) }} onBlur={e => { formik.handleBlur(e) }} /> </Grid>
            else
                return <Grid item xs={12} md={4}></Grid>
        }
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={showBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={3} className={classes.addressForm}>

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("isAddressCorrection", 12)}
                </Grid>

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("isOfficeAddressDelivery", 12)}
                </Grid>


                {
                    formik.values["isAddressCorrection"] != null && (
                        <>
                            <Grid container spacing={3} className={classes.separateSection}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6" className={classes.sectionHeading}>Address (as per given proof)</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className={classes.separateSection}>
                                {formik.values["isOfficeAddressDelivery"] === true && getElementToBeRendered("addressBuilding", 4)}
                                {getElementToBeRendered("addressFlat", 4)}
                                {getElementToBeRendered("addressVillage", 4)}
                                {orderedFieldsForAddress.map(field => {
                                    return getElementToBeRendered(field, 4)
                                })}
                            </Grid>
                        </>
                    )
                }

                <Grid container spacing={1} className={classes.separateSection}>
                    {getElementToBeRendered("isEmailMobileCorrection", 12)}
                </Grid>
                {
                    formik.values["isEmailMobileCorrection"] != null && (
                        <Grid container spacing={3} className={classes.separateSection}>
                            {getElementToBeRendered("emailAddress")}
                            {getElementToBeRendered("mobileNumber")}
                            {getElementToBeRendered("countryCode")}
                            {getElementToBeRendered("mobileNumberForeign", 4)}
                        </Grid>
                    )
                }

            </Grid>
        </React.Fragment>
    )
};


export default AddressDetails;