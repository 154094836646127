import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import * as COLORS from '../constants/colors';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    textMask: {
        width: "100%",
        marginTop: '2%'
    },
    labelRoot: {
        fontSize: 11
    },
    maskLabel: {
        fontSize: 12
    },
    inforIconDiv: {
        position: "absolute"
    },
    inforIconLabelDiv: {
        position: "absolute",
        right: 128,
        top: -1
    },
    inforIcon: {
        fontSize: 18,
        cursor: "pointer",
        color: 'gray'
    }
}));

const CustomToolTip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12,
        backgroundColor: COLORS.FRESH_APPLICATION,
        color: "white"
    },
    title: {
        width: "100%"
    }
}))(Tooltip);

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    const chooseCurrentInputMask = () => {
        switch (props.id) {
            case 'mobileNumber':
                return "+91 ##### #####"
            case 'mobileNumberForeign':
                return "#############"
            case 'aadhaarNumber':
                return "#### #### ####"
            case 'aadhaarEnrollNumber':
                return "####/#####/#####"
            case 'residenceAddressPincode':
                return "### ###"
            case 'officeAddressPincode':
                return "### ###"
            case 'representativeAddressPincode':
                return "### ###"
            case 'addressPincode':
                return "### ###"
            case 'countryCode':
                return "###"
            case 'isdCitizenship':
                return "###"
            case 'registerNumber':
                return "##############################"
            default:
                return "##############################"
        }
    }
    return (
        <NumberFormat
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            format={chooseCurrentInputMask()}
        />
    );
}
TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
const TextMask = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <InputLabel
                error={(props.formik.errors[props.id] && props.formik.touched[props.id])}
                htmlFor="formatted-text-mask-input" className={classes.maskLabel}>{props.details.label}
            </InputLabel>
            <Input
                className={classes.textMask}
                value={props.value || ""}
                onChange={(event) => { props.onChange(event); }}
                name={props.id}
                id={props.id}
                disabled={props.details.disabled}
                onBlur={(event) => props.onBlur(event)}
                inputComponent={TextMaskCustom}
                style={{ padding: "2px" }}
                error={(props.formik.errors[props.id] && props.formik.touched[props.id])}
            />
            {
                props.details.info &&
                <div className={classes.inforIconLabelDiv}>
                    <CustomToolTip title={props.details.info} placement="right">
                        <InfoIcon className={classes.inforIcon} />
                    </CustomToolTip>
                </div>
            }
        </div>
    );
}

export default TextMask;