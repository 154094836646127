import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Tooltip from '@material-ui/core/Tooltip';
import CurrentOrders from '../CurrentOrders';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StatusStepper from './statusStepper';
import Chip from '@material-ui/core/Chip';
import * as COLORS from '../../constants/colors';

//styles for applications card
export const useCardStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto'
    },
    imgDiv: {
        width: 100
    },
    img: {
        // margin: 'auto',
        display: 'block',
        width: 40,
        height: 40,
        maxWidth: '100%',
        maxHeight: '100%',
        marginRight: theme.spacing(2)
    },
    boxApplication: {
        margin: "1%",
        borderRadius: "10px 10px 0px 0px",
        padding: "15px",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    applicationsDiv: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between'
    },
    iconButton: {
        cursor: "pointer",
    },
    cuDate: {
        fontSize: "12px"
    },
    secondCardItem: {
        fontWeight: 500,
        fontSize: 14,
        width: 200
    },
    thirdCardItem: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    orderAccordian: {
        margin: "0px 1% 1% 1%",
    },
    activeStatusNew: {
        color: "#FF9933",
        borderColor: "#FF9933",
    },
    activeStatusDraft: {
        color: "#CC9900",
        borderColor: "#CC9900",
    },
    activeStatusSubmitted: {
        color: "#00CC33",
        borderColor: "#00CC33"
    },
    activeStatusCreated: {
        color: COLORS.FRESH_APPLICATION_BACKGROUND,
        borderColor: COLORS.FRESH_APPLICATION_BACKGROUND
    },
    activeIcon: {
        fontSize: 20,
        color: "#3f51b5",
        width: 40
    },
    nonActiveIcon: {
        fontSize: 20,
        color: "gray",
        width: 35
    },
    mobileSubtitles: {
        color: 'gray',
        fontWeight: 400,
        fontSize: 18,
        display: 'none'
    },
    '@media only screen and (max-width: 600px)': {
        mobileSubtitles: {
            display: 'inline'
        },
        sixthCardItem: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(5)
        }
    },
    viewDetails: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    actionGroup: {
        height: 20,
        marginTop: 5
    },
    applicationCategory: {
        fontSize: 11,
        fontWeight: 500
    },
    serialNoDiv: {
        marginRight: theme.spacing(2)
    },
    fifthCardItem: {
        width: 130,
    },
    dateTime: {
        fontSize: 10,
        fontWeight: 600
    },
    firstCardItem: {
        width: 180
    },
    applicationId: {
        fontSize: 12,
        fontWeight: 600
    }
}));

const steps = ['New', 'Submitted', 'Ordered']

const imgSrcs = {
    freshIndividual: "assets/img/applywizard/individual.svg",
    freshMinor: "assets/img/applywizard/minor.svg",
    freshHuf: "assets/img/applywizard/freshNonIndividual/Non Individual Huf.svg",
    freshFirm: "assets/img/applywizard/freshNonIndividual/Non Individual Firm.svg",
    freshTrust: "assets/img/applywizard/freshNonIndividual/Non Individual Trust.svg",
    freshAop: "assets/img/applywizard/freshNonIndividual/Non Individual Aop.svg",
    freshLlp: "assets/img/applywizard/freshNonIndividual/Non Individual Llp.svg",
    freshBoi: "assets/img/applywizard/freshNonIndividual/Non Individual Boi.svg",
    freshLocalAuthority: "assets/img/applywizard/freshNonIndividual/Non Individual Local Authority.svg",
    freshAjp: "assets/img/applywizard/freshNonIndividual/Non Individual Ajp.svg",
    freshGovernment: "assets/img/applywizard/freshNonIndividual/Non Individual govt.svg",
    freshNriIndia: "assets/img/applywizard/nri.svg",
    freshNriAbroad: "assets/img/applywizard/nri.svg",
    freshForeignAbroad: "assets/img/applywizard/foreign.svg",
    freshForeignIndia: "assets/img/applywizard/foreign.svg",
    correctionIndividual: "assets/img/applywizard/correction/correction-individual.svg",
    correctionMinor: "assets/img/applywizard/correction/correction-minor.svg",
    correctionHuf: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Huf.svg",
    correctionFirm: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Firm.svg",
    correctionTrust: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Trust.svg",
    correctionAop: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Aop.svg",
    correctionLlp: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Llp.svg",
    correctionBoi: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Boi.svg",
    correctionLocalAuthority: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Local Authority.svg",
    correctionAjp: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Ajp.svg",
    correctionGovernment: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Govt.svg",
    correctionNriIndia: "assets/img/applywizard/correction/correction-nri.svg",
    correctionNriAbroad: "assets/img/applywizard/correction/correction-nri.svg",
    correctionForeignAbroad: "assets/img/applywizard/correction/correction-foreign.svg",
    correctionForeignIndia: "assets/img/applywizard/correction/correction-foreign.svg",
    correctionReprintIndividual: "assets/img/applywizard/correction/correction-individual.svg",
    correctionReprintMinor: "assets/img/applywizard/correction/correction-minor.svg",
    correctionReprintHuf: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Huf.svg",
    correctionReprintFirm: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Firm.svg",
    correctionReprintTrust: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Trust.svg",
    correctionReprintAop: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Aop.svg",
    correctionReprintLlp: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Llp.svg",
    correctionReprintBoi: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Boi.svg",
    correctionReprintLocalAuthority: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Local Authority.svg",
    correctionReprintAjp: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Ajp.svg",
    correctionReprintGovernment: "assets/img/applywizard/correction/nonIndividual/Correction Non Individual Govt.svg",
    correctionReprintNriIndia: "assets/img/applywizard/correction/correction-nri.svg",
    correctionReprintNriAbroad: "assets/img/applywizard/correction/correction-nri.svg",
    correctionReprintForeignAbroad: "assets/img/applywizard/correction/correction-foreign.svg",
    correctionReprintForeignIndia: "assets/img/applywizard/correction/correction-foreign.svg"
}

const Card = (props) => {
    const classes = useCardStyles();

    const [isAccordianExpand, setAccordianExpand] = useState(false);
    return (
        <Grid container xs={12} className={classes.individualApplication}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <div className={classes.boxApplication}>
                        <Grid container className={classes.applicationsDiv}>
                            <Grid item className={classes.serialNoDiv}>
                                <Typography gutterBottom variant="h6" className={classes.mobileSubtitles}>
                                    Sl. No
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" className={classes.serialNo}>
                                    {props.actionDetails.index} .
                                </Typography>
                            </Grid>
                            <Grid item className={classes.imgDiv}>
                                <img className={classes.img} alt="Form Image" src={imgSrcs[props.actionDetails.secondCardItem]} />
                            </Grid>
                            <Grid item className={classes.fifthCardItem}>
                                <Typography gutterBottom variant="h6" className={classes.mobileSubtitles}>
                                    Date/Time
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" className={classes.dateTime}>
                                    {props.actionDetails.fifthCardItem}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.firstCardItem}>
                                <Typography gutterBottom variant="h6" className={classes.mobileSubtitles}>
                                    Application Id
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" className={classes.applicationId}>
                                    {props.actionDetails.firstCardItem}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.secondCardItem}>
                                <Typography gutterBottom variant="h6" className={classes.mobileSubtitles}>
                                    Application Category
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom className={classes.secondCardItem + " " + classes.applicationCategory}>
                                    {props.actionDetails.secondCardItem.toUpperCase()}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.thirdCardItem}>
                                <Grid container spacing={1}>
                                    <Typography gutterBottom variant="h6" className={classes.mobileSubtitles}>
                                        Application Status
                                    </Typography>
                                </Grid>
                                <Chip label="New" className={props.actionDetails.thirdCardItem == "New" ? classes.activeStatusNew : ""} variant="outlined" style={{ width: 50, height: 25, fontSize: 9, fontWeight: 600 }} />
                                <Chip label="Draft" className={props.actionDetails.thirdCardItem == "Draft" ? classes.activeStatusDraft : ""} variant="outlined" style={{ width: 50, height: 25, fontSize: 9, fontWeight: 600 }} />
                                <Chip label="Submitted" className={props.actionDetails.thirdCardItem == "Submitted" ? classes.activeStatusSubmitted : ""} variant="outlined" style={{ width: 70, height: 25, fontSize: 9, fontWeight: 600 }} />
                                <Chip label="Order Created" className={props.actionDetails.thirdCardItem == "Order Created" ? classes.activeStatusCreated : ""} variant="outlined" style={{ width: 90, height: 25, fontSize: 9, fontWeight: 600 }} />

                                {/* <Typography variant="body6" color="textSecondary"> */}
                                {/* <StatusStepper currentStep={props.actionDetails.thirdCardItem} steps={steps}/> */}
                                {/* </Typography> */}
                            </Grid>
                            {props.actionDetails.iconName1 ?
                                <Grid item className={classes.sixthCardItem}>

                                    <ButtonGroup variant="text" className={classes.actionGroup} color="primary" aria-label="text primary button group">
                                        <Button>
                                            <Tooltip title="edit application">
                                                <Icon
                                                    className={
                                                        props.actionDetails.iconName1 === "fas fa-edit" ?
                                                            "fas fa-edit" + " " + classes.iconButton + " " + classes.activeIcon
                                                            :
                                                            "fas fa-edit" + " " + classes.iconButton + " " + classes.nonActiveIcon}
                                                    onClick={props.actionDetails.iconName1 === "fas fa-edit" ?
                                                        () => props.onClick() : () => { }}
                                                />
                                            </Tooltip>
                                        </Button>

                                        <Button>
                                            <Tooltip title="create order">
                                                <Icon
                                                    className={
                                                        props.actionDetails.iconName1 === "fas fa-cart-plus" ?
                                                            "fas fa-cart-plus" + " " + classes.iconButton + " " + classes.activeIcon
                                                            :
                                                            "fas fa-cart-plus" + " " + classes.iconButton + " " + classes.nonActiveIcon}
                                                    onClick={props.actionDetails.iconName1 === "fas fa-cart-plus" ?
                                                        () => props.onClick() : () => { }}
                                                />
                                            </Tooltip>
                                        </Button>

                                        <Button>
                                            <Tooltip title="Make Payment">
                                                <Icon
                                                    className={
                                                        props.actionDetails.iconName1 === "fas fa-credit-card" ?
                                                            "fas fa-credit-card" + " " + classes.iconButton + " " + classes.activeIcon
                                                            :
                                                            "fas fa-credit-card" + " " + classes.iconButton + " " + classes.nonActiveIcon}
                                                    onClick={props.actionDetails.iconName1 === "fas fa-credit-card" ?
                                                        () => props.onClick() : () => { }}
                                                />
                                            </Tooltip>
                                        </Button>

                                        <Button>
                                            <Link href={props.actionDetails.href} target={props.actionDetails.target} color="inherit">
                                                <Tooltip title="Download Application">
                                                    <Icon
                                                        className={
                                                            props.actionDetails.iconName1 === "fas fa-file-download" ?
                                                                "fas fa-file-download" + " " + classes.iconButton + " " + classes.activeIcon
                                                                :
                                                                "fas fa-file-download" + " " + classes.iconButton + " " + classes.nonActiveIcon}
                                                    />
                                                </Tooltip>
                                            </Link>
                                        </Button>

                                    </ButtonGroup>
                                </Grid> : <Grid item className={classes.sixthCardItem}></Grid>}
                            <Grid item
                                onClick={() => {
                                    props.setApplicationId()
                                    setAccordianExpand(!isAccordianExpand)
                                }}
                                className={classes.viewDetails}
                            ><Tooltip title="View Details"><MenuIcon color="primary" /></Tooltip></Grid>
                        </Grid>
                    </div>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Accordion style={{ display: isAccordianExpand ? 'inline' : 'none' }} expanded={isAccordianExpand} className={classes.orderAccordian}>
                                <AccordionDetails>
                                    {props.applicationId === props.actionDetails.firstCardItem ? <CurrentOrders applicationId={props.applicationId} /> : ""}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}
export default Card;