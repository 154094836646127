import React, { useState, useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { AuthUserContext, withAuthorization } from "../Session";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import DescriptionIcon from '@material-ui/icons/Description';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RepeatIcon from '@material-ui/icons/Repeat';
import MoreIcon from '@material-ui/icons/MoreVert';
import LockOpen from '@material-ui/icons/LockOpen';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import SignOutButton from "../SignOut";
import * as COLORS from "../../constants/colors";
import TreeView from './styledTreeView';
import { compose } from "recompose";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withFirebase } from "../Firebase";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        backgroundColor: "#F8F8F8"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    navItem: {
        marginRight: theme.spacing(1),
        height: "30px",
        color: "white"
    },
    logoImg: {
        cursor: "pointer"
    },
    customNavBar: {
        backgroundColor: "white",
        padding: theme.spacing(1, 0, 0.5, 0)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    treeviewBtn: {
        marginLeft: theme.spacing(2)
    },
    usernameAuth: {
        fontSize: 18,
        color: "gray"
    },
    customBadge: {
        backgroundColor: 'red',
        color: 'white'
      }
}));


const NavBarPage = () => (
    <AuthUserContext.Consumer>
      {authUser => <NavBar authUser={authUser} />}
    </AuthUserContext.Consumer>
  );
  

const NavBarBase = (props) => {

    // UI LOGIC
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [greetings, setGreetings] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const history = useHistory();

    // DATA LOGIC

    const [applicationsCount, setApplicationsCount] = React.useState(0);
    const [username, setUsername] = useState();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        props.firebase.db.collection("applications").where("uid", "==", props.authUser.uid)
            .onSnapshot(function (querySnapshot) {
                setApplicationsCount(querySnapshot.size)
            })
    }, [])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const redirectToProfile = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
        history.push({
            pathname: ROUTES.PROFILE_PAGE
        })
    }

    const redirectToChangePwd = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
        history.push({
            pathname: ROUTES.PASSWORD_CHANGE
        })
    }

    const handleMenuSignOUT = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
        props.firebase.doSignOut();
    }
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleSingInClick = () => {
        history.push({
            pathname: ROUTES.USER_ENTRY
        });
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => redirectToProfile()}>Profile</MenuItem>
            <MenuItem onClick={() => redirectToChangePwd()}>Change Password</MenuItem>
            <MenuItem onClick={() => handleMenuSignOUT()}>Sign Out</MenuItem>
        </Menu>

    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <Button component={Link} to={ROUTES.APPLY_FORM} variant="outlined" >
                    Apply Now
                </Button>
            </MenuItem>
            <MenuItem>
                <Badge classes={{ badge: classes.customBadge }}  badgeContent={applicationsCount}>
                    <Button component={Link} to={ROUTES.APPLIED_FORMS} >
                        Dashboard
                    </Button>
                </Badge>
            </MenuItem>

            <MenuItem onClick={handleProfileMenuOpen}>
                <AccountCircle />
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="fixed" className={classes.customNavBar}>
                <Toolbar variant="dense">
                    {props.authUser ?
                        (
                            <img src="/assets/img/logo/applypan.svg" alt="logo" width="100" height="40"
                                onClick={() =>
                                    history.push({
                                        pathname: ROUTES.LANDING
                                    })}
                                className={classes.logoImg}
                            />
                        ) :
                        (
                            <a href="https://www.applypan.com"><img src="/assets/img/logo/applypan.svg" alt="logo" width="100" height="40" className={classes.logoImg} /></a>
                        )
                    }

                    {
                        props.authUser && (
                            <>
                                {
                                    showMenu && (
                                        <div className={classes.treeviewDiv} onMouseLeave={() => setShowMenu(false)}>
                                            <TreeView {...props} setShowMenu={(value) => { setShowMenu(value) }} setShowBackdrop={(value) => { setShowBackdrop(value) }} />
                                        </div>
                                    )
                                }
                                <Button onClick={() => setShowMenu(!showMenu)} color="primary" variant="contained" className={classes.treeviewBtn} >
                                    Forms Tree View
                                </Button>
                            </>
                        )
                    }
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {props.authUser ?
                            <>
                                <Button component={Link} to={ROUTES.FEES_CALCULATOR} color="primary" variant="contained" className={classes.navItem + " " + classes.applynowBtn} >
                                    Fees Calculator
                                </Button>
                                <Button component={Link} to={ROUTES.FAQ_SECTION} color="primary" variant="contained" className={classes.navItem + " " + classes.applynowBtn} >
                                    FAQ's
                                </Button>
                                <Button component={Link} to={ROUTES.APPLY_FORM} color="primary" variant="contained" className={classes.navItem + " " + classes.applynowBtn} >
                                    Apply Now
                                </Button>
                                <Badge classes={{ badge: classes.customBadge }} badgeContent={applicationsCount}>
                                    <Button component={Link} to={ROUTES.APPLIED_FORMS} color="primary" variant="contained" className={classes.navItem} >
                                        Dashboard
                                    </Button>
                                </Badge>
                                <Typography variant="h6" className={classes.usernameAuth}>{props.authUser.username}</Typography>
                                <Button variant="outline" onClick={handleProfileMenuOpen}>
                                    <AccountCircle />
                                </Button>
                            </>
                            :
                            <>
                                <IconButton color="primary" onClick={handleSingInClick}>
                                    <LockOpen />
                                </IconButton>
                            </>
                        }
                    </div>
                    <div className={classes.sectionMobile}>
                        {
                            props.authUser ?
                                <>
                                    <IconButton
                                        aria-label="show more"
                                        aria-controls={mobileMenuId}
                                        aria-haspopup="true"
                                        onClick={handleMobileMenuOpen}
                                    >
                                        <MoreIcon />
                                    </IconButton>
                                </>
                                :
                                <>
                                    <IconButton color="primary" onClick={handleSingInClick}>
                                        <LockOpen />
                                    </IconButton>
                                </>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}


const NavBar = compose(withFirebase)(NavBarBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(NavBarPage);