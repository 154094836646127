import React from 'react';
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { useHistory } from 'react-router-dom';
import { AuthUserContext } from "../Session";
import * as ROUTES from '../../constants/routes';
import * as COLORS from '../../constants/colors';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        position: 'absolute',
        top: 60,
        left: 140,
        fontSize: 8,
        backgroundColor: "white",
        padding: 10,
        minWidth: 150,
        maxHeight: 500,
        overflow: "auto"
    },
    nodes: {
        color: "gray",
        fontSize: 28
    }
});


const MenuBarPage = (props) => (
    <AuthUserContext.Consumer>
        {authUser => <MenuBar authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);


const MenuBarBase = (props) => {
    const classes = useStyles();

    const history = useHistory();

    const continueToFillingForm = (displayName, formType) => {
        console.log(typeof formType, formType)

        // return if object is came as param
        if (typeof formType != 'string') return

        if (!confirm("Do you want to apply " + displayName + "?")) return

        props.setShowBackdrop(true)

        const createApplicantFormDetails = props.firebase.functions.httpsCallable(
            "createApplicantFormDetails"
        );

        createApplicantFormDetails({
            uid: props.authUser.uid,
            formType: formType
        })
            .then(result => {
                props.setShowBackdrop(false)
                history.push({
                    pathname: ROUTES.FORM_FILLING,
                    search: "?id=" + result.data.documentId + "&formType=" + formType
                });
            })
            .catch((error) => {
                props.setShowBackdrop(false)
                console.log(error)
            })
    }

    const menuItemsJson = {
        Fresh: {
            Individual: 'freshIndividual',
            Minor: 'freshMinor',
            'Non Individual': {
                'HUF': 'freshHuf',
                'Firm': 'freshFirm',
                'Trust': 'freshTrust',
                'AJP': 'freshAjp',
                'AOP': 'freshAop',
                'BOI': 'freshBoi',
                'LLP': 'freshLlp',
                'Local Authority': 'freshLocalAuthority',
                'Government': 'freshGovernment'
            },
            NRI: {
                'Card delivery to India': 'freshNriIndia',
                'Card delivery to Abroad': 'freshNriAbroad'
            },
            Foreign: {
                'Card delivery to India': 'freshForeignIndia',
                'Card delivery to Abroad': 'freshForeignAbroad'
            }
        },
        'Minor To Major': {
            Updation: 'correctionIndividaul',
            Correction: 'correctionReprintIndividual'
        },
        'Make Corrections': {
            'Correction Forms': {
                Individual: 'correctionIndividual',
                Minor: 'correctionMinor',
                'Non Individual': {
                    'HUF': 'correctionHuf',
                    'Firm': 'correctionFirm',
                    'Trust': 'correctionTrust',
                    'AJP': 'correctionAjp',
                    'AOP': 'correctionAop',
                    'BOI': 'correctionBoi',
                    'LLP': 'correctionLlp',
                    'Local Authority': 'correctionLocalAuthority',
                    'Government': 'correctionGovernment'
                },
                NRI: {
                    'Card delivery to India': 'correctionNriIndia',
                    'Card delivery to Abroad': 'correctionNriAbroad'
                },
                Foreign: {
                    'Card delivery to India': 'correctionForeignIndia',
                    'Card delivery to Abroad': 'correctionForeignAbroad'
                }
            },
            'Reprint Forms': {
                Individual: 'correctionReprintIndividual',
                Minor: 'correctionReprintMinor',
                'Non Individual': {
                    'HUF': 'correctionReprintHuf',
                    'Firm': 'correctionReprintFirm',
                    'Trust': 'correctionReprintTrust',
                    'AJP': 'correctionReprintAjp',
                    'AOP': 'correctionReprintAop',
                    'BOI': 'correctionReprintBoi',
                    'LLP': 'correctionReprintLlp',
                    'Local Authority': 'correctionReprintLocalAuthority',
                    'Government': 'correctionReprintGovernment'
                },
                NRI: {
                    'Card delivery to India': 'correctionReprintNriIndia',
                    'Card delivery to Abroad': 'correctionReprintNriAbroad'
                },
                Foreign: {
                    'Card delivery to India': 'correctionReprintForeignIndia',
                    'Card delivery to Abroad': 'correctionReprintForeignAbroad'
                }
            }
        }
    }
    const menuOneList = () => {
        let array = []
        Object.keys(menuItemsJson).forEach((menuOneList) => {
            array.push(
                <StyledTreeItem className={classes.nodes} nodeId={menuOneList} label={menuOneList}>
                    {
                        Object.keys(menuItemsJson[menuOneList]).map((menuTwoList) => {
                            return (
                                <StyledTreeItem nodeId={menuTwoList} label={menuTwoList} onClick={() => continueToFillingForm(menuTwoList, menuItemsJson[menuOneList][menuTwoList])} >
                                    {
                                        typeof menuItemsJson[menuOneList][menuTwoList] != 'string' &&
                                        Object.keys(menuItemsJson[menuOneList][menuTwoList]).map((menuThreeList) => {
                                            return (
                                                <StyledTreeItem nodeId={menuThreeList} label={menuThreeList} onClick={() => continueToFillingForm(menuThreeList, menuItemsJson[menuOneList][menuTwoList][menuThreeList])}>
                                                    {
                                                        typeof menuItemsJson[menuOneList][menuTwoList][menuThreeList] != 'string' &&
                                                        Object.keys(menuItemsJson[menuOneList][menuTwoList][menuThreeList]).map((menuFourList) => {
                                                            return <StyledTreeItem nodeId={menuFourList} label={menuFourList} onClick={() => continueToFillingForm(menuFourList, menuItemsJson[menuOneList][menuTwoList][menuThreeList][menuFourList])} />
                                                        })
                                                    }
                                                </StyledTreeItem>
                                            )
                                        })
                                    }
                                </StyledTreeItem>
                            )
                        })
                    }
                </StyledTreeItem>
            )
        })
        return array
    }

    return (
        <TreeView
            className={classes.root}
            defaultExpanded={['1']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
        >
            {menuOneList()}
        </TreeView>
    );
}


const MenuBar = compose(withFirebase)(MenuBarBase);

export default MenuBarPage;