import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { SignInLink } from "../SignIn";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Schema from '../../lib/Validation/signUp';
import { useFormik } from 'formik';

const SignUpPage = () => (
    <div>
      <SignUpForm />
    </div>
  );

  
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.applypan.com/">
                Applypan.com
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const INITIAL_STATE = {
    username: "",
    email: "",
    passwordOne: "",
    passwordTwo: "",
    error: null
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        elevation: 3
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        'z-index': 1
    },
    pwdContainer:{
        position: 'relative'
    },
    pwdMessage: {
        position: 'absolute',
        right:-250,
        top: 5,
        backgroundColor: 'white',
        'z-index': 2,
        borderRadius: 10,
        padding: 5,
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    correctPwd:{
        color: 'green',
        fontSize: 14,
    },
    wrongPwd:{
        color: 'red',
        fontSize: 14,
    },
    pwdIcon:{
        fontSize: 38
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    eyeIcon: {
        cursor: "pointer"
    },
    firebaseErrorMsg:{
        marginTop: theme.spacing(1),
        border: '2px solid #f4b2b2',
        borderRadius: 5,
        backgroundColor: '#ffdddd',
        minHeight: 50,
        padding: 12
    },
    firebaseTxt:{
        color: '#ff8484',
        fontSize: 14
    }
}));

const SignUpFormBase = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ ...INITIAL_STATE });
    const [togglePwd, setTogglePwd] = useState(false);
    const [pwdWarning, setPwdWarning] = useState(false);
    const [firebaseError, setFirebaseError] = useState();
    const [showFields, setShowFields] = useState({mobile: true});
    const [otpResponse, setOtpResponse] = useState();
    const userSignUp = props.firebase.functions.httpsCallable(
        "userSignUp"
      );

    const onSubmit = formValues => {
        const { username, email, mobileNumber, passwordOne } = formValues;
        console.log(username)
        var usercount = 0;
        props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                props.firebase.db.collection("numberSeries")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().usercount){
                            usercount = doc.data().usercount
                        }
                    })
                })
                .then(()=>{
                    // Create a user in your Firebase realtime database
                    let uid = authUser.user.uid
                    userSignUp({
                        uid,
                        username,
                        mobileNumber,
                        email,
                        usercount
                    })
                    .then(() => {
                        setState({ ...INITIAL_STATE });
                        props.history.push(ROUTES.HOME);
                    })
                })    
            })
            .catch(error => {
                setState({ error });
                setFirebaseError(error.message)
            });
    };

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validationSchema: Schema,
        onSubmit: formValues => { 
            onSubmit(formValues)
        }
    })

    const sendOtp = () => {
        props.firebase.doVerifyRecaptcha()
        window.recaptchaVerifier.render()
        var appVerifier = window.recaptchaVerifier
        let mobileNo = '+91'+formik.values.mobileNumber
        props.firebase.doCreateUserWithMobileNumber(mobileNo, appVerifier)
        .then((response)=>{
            console.log(response)
            setOtpResponse(response)
            setShowFields({verifyOtp: true});
        })
        .catch((error)=>{console.log(error)})
    }

    const verifyUserOtp = () => {
        var code = formik.values.userOtp
        otpResponse.confirm(code).then((result)=>{
            setShowFields({email: true})
        })
        .catch((error)=>{setFirebaseError("Invalid OTP!"); console.log(error);})
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src="/assets/img/logo/applypan.svg" alt="logo" width="150" height="50" />
                <Typography component="h1" variant="h5">
                    Create User/ Sign Up
                </Typography>
                <form className={classes.form}>

                {
                        showFields.mobile && (
                            <>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    autoFocus
                                    type={"number"}
                                    onBlur={(e)=>{formik.handleBlur(e)}}
                                    onChange={(e)=>{
                                        formik.handleChange(e)
                                    }}
                                    error={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                    helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onBlur={(e)=>{formik.handleBlur(e)}}
                                    onChange={(e)=>{formik.handleChange(e)}}
                                    error={formik.touched.email && formik.errors.email}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                <Grid container>
                                    <Grid item xs={12} md={12} id="recaptcha-container" style={{paddingLeft: 45, paddingTop: 20}}></Grid>
                                </Grid>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={!formik.touched.mobileNumber || (formik.errors.mobileNumber && Object.keys(formik.errors.mobileNumber).length !== 0)}
                                    onClick={(e)=>{ sendOtp() }}
                                >Send OTP</Button>
                            </>
                        )
                    }

                    {
                        showFields.verifyOtp && (
                            <>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="userOtp"
                                    label="Verify OTP"
                                    name="userOtp"
                                    onBlur={(e)=>{formik.handleBlur(e)}}
                                    onChange={(e)=>{formik.handleChange(e)}}
                                    error={formik.touched.userOtp && formik.errors.userOtp}
                                    helperText={formik.touched.userOtp && formik.errors.userOtp}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>{ verifyUserOtp() }}
                                >Verify OTP and Sign Up</Button>
                            </>
                        )
                    }

                    {
                        showFields.email && (
                        <>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onBlur={(e)=>{formik.handleBlur(e)}}
                                onChange={(e)=>{formik.handleChange(e)}}
                                error={formik.touched.username && formik.errors.username}
                                helperText={
                                    formik.touched.username && formik.errors.username
                                }
                            />
                            
                            <Grid container className={classes.pwdContainer}>
                                <Grid item xs={12} md={12} className={classes.pwdMessage}
                                style={{
                                    display: pwdWarning? 'inline-block': 'none'
                                }}
                                >                              
                                    <Typography variant="h6"
                                    className={/\d/.test(formik.values.passwordOne)?
                                    classes.correctPwd : classes.wrongPwd}>
                                    {
                                        /\d/.test(formik.values.passwordOne)?     
                                        <i class="fa fa-check" aria-hidden="true"></i> :
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    }
                                    password must contains digit
                                    </Typography>

                                    <Typography variant="h6"
                                    className={/(?=.*[a-z])(?=.*[A-Z])/.test(formik.values.passwordOne)?
                                    classes.correctPwd : classes.wrongPwd}>
                                    {
                                        /(?=.*[a-z])(?=.*[A-Z])/.test(formik.values.passwordOne)?     
                                        <i class="fa fa-check" aria-hidden="true"></i> :
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    }
                                    password must contains uppercase and lowercase
                                    </Typography>

                                    <Typography variant="h6"
                                    className={formik.values.passwordOne && formik.values.passwordOne.length >= 8?
                                    classes.correctPwd : classes.wrongPwd}>
                                    {
                                        formik.values.passwordOne && formik.values.passwordOne.length >= 8?     
                                        <i class="fa fa-check" aria-hidden="true"></i> :
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    }
                                    password must contains atleast 8 characters
                                    </Typography>

                                    <Typography variant="h6"
                                    className={/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(formik.values.passwordOne)?
                                    classes.correctPwd : classes.wrongPwd}>
                                    {
                                        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(formik.values.passwordOne)?     
                                        <i class="fa fa-check" aria-hidden="true"></i> :
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    }
                                    password must contains one special characters
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="passwordOne"
                                        label="Password"
                                        type={togglePwd? "text" : "password"}
                                        id="passwordOne"
                                        autoComplete="Password"
                                        onFocus={(e)=>{
                                            setPwdWarning(true)
                                        }}
                                        onBlur={(e)=>{
                                            formik.handleBlur(e);
                                            setPwdWarning(false)
                                        }}
                                        InputProps={{
                                            endAdornment: togglePwd? <Visibility onClick={()=>setTogglePwd(!togglePwd)} className={classes.eyeIcon} /> : <VisibilityOff onClick={()=>setTogglePwd(!togglePwd)} className={classes.eyeIcon} />
                                        }}                                
                                        onChange={(e)=>{formik.handleChange(e)}}
                                        error={formik.touched.passwordOne && formik.errors.passwordOne}
                                        helperText={formik.touched.passwordOne && formik.errors.passwordOne}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="passwordTwo"
                                label="Re-Type Password"
                                type={togglePwd? "text" : "password"}
                                id="passwordTwo"
                                autoComplete="Re-Type password"
                                onBlur={(e)=>{formik.handleBlur(e)}}
                                onChange={(e)=>{formik.handleChange(e)}}
                                InputProps={{
                                    endAdornment: togglePwd? <Visibility onClick={()=>setTogglePwd(!togglePwd)} className={classes.eyeIcon} /> : <VisibilityOff onClick={()=>setTogglePwd(!togglePwd)} className={classes.eyeIcon} />
                                }}
                                error={formik.touched.passwordTwo && formik.errors.passwordTwo}
                                helperText={formik.touched.passwordTwo && formik.errors.passwordTwo}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={(e)=>{formik.handleSubmit(e)}}
                            >
                                Sign Up
                            </Button>
                        </>
                        )
                    }
                    
                    {
                        firebaseError ?
                        (
                            <Grid container>
                                <Grid item xs={12} md={12} className={classes.firebaseErrorMsg}>
                                    <Typography variant="h6" className={classes.firebaseTxt}>
                                        {firebaseError}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ):
                        ""
                    }
                    
                    {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                    <Grid container>
                        <Grid item xs>
                            <SignInLink />
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
const SignUpLink = () => (
    <p>
      Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </p>
  );
  
  const SignUpForm = compose(
    withRouter,
    withFirebase
  )(SignUpFormBase);
  
  export default SignUpPage;
  
  export { SignUpForm, SignUpLink };