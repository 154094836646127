import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { useHistory } from "react-router-dom";
import TransactionCard, { useCardStyles } from '../CardComponent/transactionCard';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { getTransactions } from '../TransactionContext';

const CurrentTransactionsLayout = props => (
    <AuthUserContext.Consumer>
        {authUser => <CurrentTransactionsBase authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const CurrentTransactions = props => {
    const classes = useCardStyles();
    const [existingTransactions, setExistingTransactions] = useState([]);

    useEffect(() => {
        setExistingTransactions(props.existingTransactions)
    }, []);

    const getTime = (utc) => {
        var formatedString = new Date(utc).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        return formatedString;
    }

    const displayTransactions_material = existingTransactions.map((transaction, indx) => {

        let created_At = getTime(new Date(transaction.transactionData.createdDate["seconds"] * 1000));

        let actionDetails = {
            firstCardItem: transaction.transactionId,
            secondCardItem: props.formType,
            thirdCardItem: transaction.transactionData.transactionDetails.event.substring(transaction.transactionData.transactionDetails.event.indexOf('.') + 1),
            fourthCardItem: '\u20B9' + transaction.amount / 100,
            fifthCardItem: "Created At (IST) " + created_At,
        }

        return <TransactionCard actionDetails={actionDetails} />

    })

    return (
        <div className={classes.root}>
            <Grid container xs={12} spacing={2}>
                {displayTransactions_material}
            </Grid>
        </div>
    )
};

const condition = authUser => !!authUser;

const CurrentTransactionsLink = () => <Link to={ROUTES.CURRENT_TRANSACTIONS}>Form Filling</Link>;

const CurrentTransactionsBase = compose(withFirebase, getTransactions)(CurrentTransactions)
const CurrentTransactionsPage = compose(withAuthorization(condition))(CurrentTransactionsLayout);

export default CurrentTransactionsPage;

export { CurrentTransactionsPage, CurrentTransactionsBase, CurrentTransactionsLink };