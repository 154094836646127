import React, { useState, useEffect } from "react";

import ApplicationContext from "./context";
import { withFirebase } from "../Firebase";

const getApplications = Component => {

    const GetApplications = (props) => {

        const [userApplications, setUserApplications] = useState();

        //useeffect sync with user applications and orders page changes
        useEffect(() => {
            let arrayOfApplications = []
            if (props.authUser) {
                //  Bring all applications for current user
                props.firebase.db.collection("applications").where("uid", "==", props.authUser.uid).orderBy("updatedDate")
                    .onSnapshot(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            arrayOfApplications.push({ applicationData: doc.data(), applicationId: doc.id, applicationCount: doc.size })
                        });
                        arrayOfApplications.reverse()
                        setUserApplications(arrayOfApplications)
                    })
            }
        }, []);

        return (
            <ApplicationContext.Provider value={userApplications}>
                {
                    userApplications &&
                    <Component {...props} userApplications={userApplications} />
                }
            </ApplicationContext.Provider>
        );
    }
    return withFirebase(GetApplications)
}

export default getApplications;