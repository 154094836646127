import React, { useEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { AuthUserContext, withAuthorization } from "../Session";

const ApplicationPage = props =>
  props.location.formType ? (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="pt-100">
          <h3>Application Page</h3>
          <Application authUser={authUser} formType={props.location.formType} />
        </div>
      )}
    </AuthUserContext.Consumer>
  ) : (
    <RedirectToFormsPage />
  );

const RedirectToSelectFormBase = props => {
  props.history.push(ROUTES.APPLY_FORM);

  return <p>Redirecting to select form type</p>;
};

const ApplicationBase = props => {
  const [uid, setUid] = useState(props.authUser.uid);
  const [form, setForm] = useState({});
  const [formType, setFormType] = useState(props.formType);

  const loadFormBasedOnType = () => {
    props.firebase.db
      .doc(`forms/${formType}`)
      .get()
      .then(doc => {
        if (doc && doc.exists) setForm(doc.data());
      });
  };

  useEffect(() => {
    loadFormBasedOnType();
  }, []);

  return (
    <div>
      <h6>Selected form type is {formType}</h6>
      <p>{JSON.stringify(form)}</p>
    </div>
  );
};

const Application = compose(withFirebase)(ApplicationBase);

const condition = authUser => !!authUser;

const RedirectToFormsPage = withRouter(RedirectToSelectFormBase);

export default withAuthorization(condition)(ApplicationPage);
