import * as Yup from 'yup';

const Schema = Yup.object().shape({
    username: Yup.string()
        .min(4, 'Username must contains atleast 4 characters')
        .max(50, 'Username must contains atmost 50 characters')
        .required('Username is required!'),

    email: Yup.string()
        .email('Invalid email-id')
        .required('Email is required!'),
    mobileNumber: Yup.string()
        .min(10, 'Mobile number must contains 10 characters')
        .max(10, 'Mobile number must contains 10 characters')
        .required('Mobile number is required!')
});

export default Schema;