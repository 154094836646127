import React, { useEffect, useState, useRef } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import './orderpage.css';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import Grow from '@material-ui/core/Grow';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const OrderPageLayout = props => (
    <AuthUserContext.Consumer>
        {authUser => <OrderPageBase authUser={authUser} {...props} />}
    </AuthUserContext.Consumer>
);

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    paymentImg: {
        width: 350,
        height: 400
    },
    paymentField: {
        fontSize: 20
    },
    paymentLabel: {
        color: 'gray',
        fontSize: 12,
        fontWeight: 100,
        marginBottom: theme.spacing(2)
    },
    gstinBox: {
        width: 200
    },
    couponBtn: {
        fontSize: 12,
        marginTop: 16,
        marginLeft: 10,
        width: 110,
        height: 55
    },
    createOrderBtn: {
        width: 200,
        marginTop: theme.spacing(4)
    },
    firebaseErrorMsg: {
        marginTop: theme.spacing(1),
        border: '2px solid #f4b2b2',
        borderRadius: 5,
        backgroundColor: '#ffdddd',
        minHeight: 50,
        padding: 12
    },
    firebaseTxt: {
        color: '#ff8484',
        fontSize: 14
    },
}));

const OrderPage = (props) => {

    const classes = useStyles();

    let params = queryString.parse(props.location.search);
    let history = useHistory();

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: formValues => {
            console.log(formValues)
        }
    })

    const [applicationId, setApplicationId] = useState();
    const [currentOrderDetails, setCurrentOrderDetails] = useState({});
    const [existingTransactions, setExistingTransactions] = useState([]);
    const [applicationMetaData, setApplicationsMetaData] = useState();
    const [enableDiscount, setEnableDiscount] = useState(false);
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [firebaseError, setFirebaseError] = useState();

    useEffect(async () => {

        //get current existing orders
        let applicationId = params.applicationId || props.applicationId

        setApplicationId(applicationId)

        props.firebase.db.collection("applications").doc(applicationId)
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    setApplicationsMetaData(doc.data())
                    setShowBackDrop(false);
                    console.log(doc.data())
                }
            })

        let arrayOfOrders = [];
        props.firebase.db.collection("orders").where('applicationId', '==', applicationId)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    arrayOfOrders.push(doc.data())
                });
                console.log(arrayOfOrders)
                setCurrentOrderDetails(arrayOfOrders[0])
                setShowBackDrop(false);
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });

        props.firebase.db.collection("orders").where('applicationId', '==', applicationId)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    arrayOfOrders.push(doc.data())
                });
                setCurrentOrderDetails(arrayOfOrders[0])
                setShowBackDrop(false);
            });

    }, []);

    const createOrderForUser = props.firebase.functions.httpsCallable("createOrderForUser");
    const getDiscountDetailsApplyCoupon = props.firebase.functions.httpsCallable("getDiscountDetailsApplyCoupon ");

    const getDiscount = (couponCode) => {
        setEnableDiscount(true)
        setShowBackDrop(true);
        getDiscountDetailsApplyCoupon({
            applicationId: applicationId,
            couponCode: couponCode
        })
            .then((response) => { 
                if(!response.data) setFirebaseError("Invalid Coupon Code");
                setShowBackDrop(false);  
            })
            .catch((error) => { setShowBackDrop(false);  setFirebaseError("Network Error!");})
    }

    const initiateTransaction = () => {
        console.log("initiate Transaction Function")
        var razorPayTxnIniateObj = {
            // rzp_test_JJWW12zeNIk8il
            // rzp_live_PBo2XgypzAWyEX
            key: "rzp_live_PBo2XgypzAWyEX", // TO BE HARDCODED IN ENV FILE
            amount: currentOrderDetails.createdOrderRazorpay.amount_due,
            currency: currentOrderDetails.createdOrderRazorpay.currency,
            name: "Apply Pan",
            description: "Test Transaction",
            order_id: currentOrderDetails.orderId,
            image: "/static/images/header-2.png",
            handler: (response) => {
                if (props.applicationId) {
                    props.setOrderId(response.razorpay_order_id)
                    props.handleNext()
                }

                if (params.applicationId)
                    history.push({
                        pathname: ROUTES.TRANSACTION_STATUS,
                        search: "?orderId=" + response.razorpay_order_id + "&id=" + applicationId
                    });
            },
            prefill: {
            },
            notes: {
            }
        }
        var razorPayModalWithOrderDetails = new Razorpay(razorPayTxnIniateObj);
        razorPayModalWithOrderDetails.open();
    }

    //create order or if order exists go to orders page for payment
    const createOrder = () => {
        var orderCreationData = {
            amountToBePaid: applicationMetaData.amountToBePaid.amount * 100,
            uid: props.authUser.uid,
            formType: applicationMetaData.formType,
            applicationId: params.applicationId || props.applicationId,
            userEmail: props.authUser.email,
            formTypeNormal: props.formTypeNormal,
            gstin: formik.values.gstin
        }
        setShowBackDrop(true)
        createOrderForUser(orderCreationData)
            .then((result) => { console.log({ result }); })
            .catch((err) => console.log(err))
    }

    const getTime = (utc) => {
        var formatedString = new Date(utc).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        return formatedString;
    }

    var isInvalid = formik.values.couponCode == "" || formik.values.couponCode == null

    return (
        <Container maxWidth="md" component="main">
            <Backdrop className={classes.backdrop} open={showBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container alignItems="flex-end">
                <Grid item key="tier.title" xs={12} sm={12} md={12} >

                    <Card>

                        <CardHeader
                            title="Apply PAN"
                            subheader="Payment Page"
                            titleTypographyProps={{ align: 'center' }}
                            subheaderTypographyProps={{ align: 'center' }}
                            className={classes.cardHeader}
                        />
                        <Grid container className={classes.paymentContainer} >
                            <Grid item xs={6} md={6}>
                                <img src="/assets/img/home/payment.svg" alt="payment img" className={classes.paymentImg} />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                {(currentOrderDetails && currentOrderDetails.createdOrderRazorpay) ? (
                                    <>
                                        <CardContent>
                                            <div className={classes.cardPricing}>
                                                <Typography component="h2" variant="h3" color="textPrimary">
                                                    <span>&#x20b9;</span> {currentOrderDetails.createdOrderRazorpay.amount_due / 100}
                                                </Typography>
                                            </div>

                                            <Typography className={classes.paymentField} variant="h4" align="left" key="line">
                                                {currentOrderDetails.applicationId}
                                            </Typography>
                                            <Typography className={classes.paymentLabel} variant="h6" align="left" key="line">
                                                Application Id
                                                </Typography>

                                            <Typography className={classes.paymentField} variant="h4" align="left" key="line">
                                                {currentOrderDetails.orderId}
                                            </Typography>
                                            <Typography className={classes.paymentLabel} variant="h6" align="left" key="line">
                                                Order Id
                                                </Typography>

                                            <Typography className={classes.paymentField} variant="h4" align="left" key="line">
                                                {currentOrderDetails.orderStatus}
                                            </Typography>
                                            <Typography className={classes.paymentLabel} variant="h6" align="left" key="line">
                                                Order Status
                                                </Typography>

                                            <Typography className={classes.paymentField} variant="h4" align="left" key="line">
                                                {getTime(new Date(currentOrderDetails.createdOrderRazorpay.created_at * 1000))}
                                            </Typography>
                                            <Typography className={classes.paymentLabel} variant="h6" align="left" key="line">
                                                Order Created Date
                                                </Typography>
                                        </CardContent>

                                        <CardActions>
                                            <Button fullWidth variant="contained" onClick={() => initiateTransaction()} color="primary">
                                                pay now
                                                </Button>
                                        </CardActions>
                                    </>
                                ) :
                                    (
                                        applicationMetaData ? (
                                            <div>
                                                <div className={classes.cardPricing}>
                                                    <Typography component="h2" variant="h3" color="textPrimary">
                                                        <span>&#x20b9;</span>
                                                        {
                                                            applicationMetaData.amountToBePaid.amount
                                                        }
                                                    </Typography>
                                                </div>

                                                <Typography className={classes.paymentField} variant="h4" align="left" key="line">
                                                    {props.applicationId || params.applicationId}
                                                </Typography>
                                                <Typography className={classes.paymentLabel} variant="h6" align="left" key="line">
                                                    Application Id
                                                    </Typography>

                                                <Typography className={classes.paymentField} variant="h4" align="left" key="line">
                                                    {applicationMetaData.applicationStatus}
                                                </Typography>
                                                <Typography className={classes.paymentLabel} variant="h6" align="left" key="line">
                                                    Application Status
                                                    </Typography>
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <Button fullWidth variant="contained" className={classes.createOrderBtn} onClick={() => createOrder()} color="primary">
                                                            Create Order
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            margin="normal"
                                                            id="gstin"
                                                            label="GSTIN Code"
                                                            name="gstin"
                                                            autoFocus
                                                            className={classes.gstinBox}
                                                            inputProps={{
                                                                classes: {
                                                                    root: classes.couponBox,
                                                                    focused: classes.labelFocused
                                                                }
                                                            }}
                                                            onBlur={(e) => { formik.handleBlur(e) }}
                                                            onChange={(e) => { formik.handleChange(e) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={8}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="couponCode"
                                                            label="Coupon Code"
                                                            name="couponCode"
                                                            autoFocus
                                                            inputProps={{
                                                                classes: {
                                                                    root: classes.couponBox,
                                                                    focused: classes.labelFocused
                                                                }
                                                            }}
                                                            onBlur={(e) => { formik.handleBlur(e) }}
                                                            onChange={(e) => { formik.handleChange(e) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={4}>
                                                        <Button fullWidth id="getDiscount" variant="outlined" className={classes.couponBtn} disabled={isInvalid} onClick={() => getDiscount(formik.values.couponCode)} color="primary">
                                                            Get Discount
                                                        </Button>
                                                    </Grid>
                                                    {
                                                        firebaseError ?
                                                            (
                                                                <Grid container>
                                                                    <Grid item xs={12} md={12} className={classes.firebaseErrorMsg}>
                                                                        <Typography variant="h6" className={classes.firebaseTxt}>
                                                                            {firebaseError}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ) :
                                                            ""
                                                    }
                                                </Grid>
                                            </div>
                                        ) : (
                                            <Grid container alignItems="flex-end">
                                                <Grid item key="tier.title" xs={12} sm={6} md={5} style={{ margin: "auto" }} >
                                                    <Grow
                                                        in={true}
                                                        style={{ transformOrigin: '0 0 0', margin: "5%" }}
                                                        {...(true ? { timeout: 1000 } : {})}
                                                    >
                                                        <div className={classes.root}>
                                                            <Skeleton variant="text" width={210} height={40} />
                                                            <Skeleton variant="rect" width={210} height={150} />
                                                            <Skeleton variant="text" width={210} height={60} />
                                                        </div>
                                                    </Grow>
                                                </Grid>
                                            </Grid>
                                        )
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Container >
    )
};

const condition = authUser => !!authUser;

const OrderPageLink = () => <Link to={ROUTES.FORM_FILLING}>Form Filling</Link>;

const OrderPageBase = compose(withFirebase)(OrderPage)
const OrderPageComponent = compose(withAuthorization(condition))(OrderPageLayout);

export default OrderPageComponent;

export { OrderPageComponent, OrderPageBase, OrderPageLink };