import React, { useEffect, useRef, useState } from "react";
import {
  SubVariantItem,
  ItemSeperator,
  ShrinkedItem
} from "./SharedComponents";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import { compose } from "recompose";
import { useHistory } from "react-router-dom";
import * as COLORS from "../../constants/colors";
import * as ROUTES from "../../constants/routes";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ChooseApplication from './chooseApplication';

const FreshApplicationPage = (props) => (
  <AuthUserContext.Consumer>
    {authUser => <FreshApplication authUser={authUser} {...props} />}
  </AuthUserContext.Consumer>
);

const FreshApplicationBase = props => {
  const viewRef = useRef(null);
  let history = useHistory();
  const [applicationId, setApplicationId] = useState(null);
  const [displayName, setDisplayName] = useState();
  var currentApplicationTypes = ["freshIndividual", "freshMinor", "freshSelfHelp"]

  const BASE_STYLE = {
    borderLeft: `5px solid ${COLORS.DEFAULT_BORDER_COLOR}`,
    cursor: "pointer",
    padding: "20px 35px",
    height: "90%",
    marginBottom: "25px",
    fontSize: "10px"
  };

  useEffect(() => {
    console.log(props)
    viewRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const continueToFillingForm = () => {
    const createApplicantFormDetails = props.firebase.functions.httpsCallable(
      "createApplicantFormDetails"
    );
    props.setPageLoading(true)

    createApplicantFormDetails({
      uid: props.authUser.uid,
      formType: props.choosenApplication
    }).then(result => {
      setApplicationId(result.data.documentId);
      props.setPageLoading(false)
      history.push({
        pathname: ROUTES.FORM_FILLING,
        search: "?id=" + result.data.documentId + "&formType=" + props.choosenApplication
      });
    });
  }

  const onFormVariantSelect = (formVariant, displayName) => {
    props.onSelect(formVariant)
    setDisplayName(displayName)
    props.setapplicationVariant(null)
    props.setChoosenApplication(formVariant)
  };

  const onNonIndividualVariantSelect = formVariant => {
    props.onSelect(formVariant)
    props.setChoosenApplication(null)
    props.setapplicationVariant("nonIndividual")
  };

  const data = [
    {
      name: "freshIndividual",
      caption: "Individual",
      displayName: "Fresh Individual",
      details: "If you are an individual and your age is 18+",
      imgsrc: "assets/img/applywizard/individual.svg",
      onSelect: onFormVariantSelect
    },
    {
      name: "freshSelfHelp",
      caption: "Self Help",
      displayName: "Self Help",
      details: "If you are an individual and your age is 18+",
      imgsrc: "assets/img/applywizard/individual.svg",
      onSelect: onFormVariantSelect
    },
    {
      name: "freshMinor",
      caption: "Minor",
      displayName: "Fresh Minor",
      details:
        "If you are applying PAN card your child whoose age is less than 18",
      imgsrc: "assets/img/applywizard/minor.svg",
      onSelect: onFormVariantSelect
    },
    {
      name: "nonIndividual",
      caption: "Non individual",
      details: "If you are applying PAN card as a non individual",
      imgsrc: "assets/img/applywizard/non-individual.svg",
      onSelect: onNonIndividualVariantSelect
    },
    {
      name: "nri",
      caption: "NRI",
      details: "If you are applying PAN card and residing outside India",
      imgsrc: "assets/img/applywizard/nri.svg",
      onSelect: onNonIndividualVariantSelect
    },
    {
      name: "foreign",
      caption: "Foreign National",
      details:
        "If you are applying PAN card in India but a citizen of a national other than India",
      imgsrc: "assets/img/applywizard/foreign.svg",
      onSelect: onNonIndividualVariantSelect
    }
  ];

  const FreshApplicationItems = data.map(i => {
    return (
      <ShrinkedItem
        name={i.name}
        active={i.name == props.applicationCategory}
        caption={i.caption}
        displayName={i.displayName}
        details={i.details}
        // onSelect={props.choosenApplication ? () => { return } : i.onSelect}
        onSelect={i.onSelect}
        styles={BASE_STYLE}
        imgsrc={i.imgsrc}
        colSize={2}
        bgColor={COLORS.FRESH_APPLICATION}
        activeColor={props.activeColor}
      />
    );
  });


  return (
    <div>
      <div className="section-title mb-10 mt-10" ref={viewRef}>
        <Typography variant="h6" component="h6">
          How do you want to apply?
        </Typography>
      </div>
      <Grid container style={{ display: "flex", justifyContent: "row" }}>
        {FreshApplicationItems}
      </Grid>
      {
        currentApplicationTypes.includes(props.choosenApplication) && (
          <>
            <ChooseApplication
              choosenApplication={props.choosenApplication}
              displayName={displayName}
              continueToFillingForm={continueToFillingForm}
              setChoosenApplication={props.setChoosenApplication}
              activeColor={props.activeColor}
            />
          </>
        )
      }
      <div>{applicationId} </div>
    </div>
  );
};

const FreshApplication = compose(withFirebase)(FreshApplicationBase);

export default FreshApplicationPage;
