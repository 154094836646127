import React from 'react';
import './styles.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

const Dropdown = props => {

    const useStyles = makeStyles((theme) => ({
        selectControl: {
            margin: theme.spacing(1),
            width: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre"
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        dropdownTitle: {
            fontSize: 14
        }
    }));

    const classes = useStyles();

    const generateNameOnPan = (values) => {
        const { lastName, firstName, middleName, organisationName } = values;
        var lastname;
        var firstname;
        var middlename;
        var organisationname;

        lastName == undefined ? lastname = '' : lastname = lastName.toUpperCase()
        firstName == undefined ? firstname = '' : firstname = firstName.toUpperCase()
        middleName == undefined ? middlename = '' : middlename = middleName.toUpperCase()
        organisationName == undefined ? organisationname = '' : organisationname = organisationName.toUpperCase()

        let middleinitial = firstname.split(" ")[1] != undefined ? firstname.split(" ")[1].charAt(0) + " " : "";
        let options = []
        if (lastname && lastname !== '') {
            options.push({ value: lastname, label: lastname })
        }
        if (firstname && firstname !== '') {
            options = []
            options.push({ value: lastname + ' ' + firstname, label: lastname + ' ' + firstname })
            options.push({ value: firstname + ' ' + lastname, label: firstname + ' ' + lastname })
            options.push({ value: firstname.split('')[0] + ' ' + middleinitial + lastname, label: firstname.split('')[0] + ' ' + middleinitial + lastname })
            options.push({ value: lastname + ' ' + firstname.split('')[0] + middleinitial, label: lastname + ' ' + firstname.split('')[0] + middleinitial })

        }
        if (middlename && middlename !== '') {
            options = []
            options.push({ value: lastname + ' ' + firstname + ' ' + middlename, label: lastname + ' ' + firstname + ' ' + middlename })
            options.push({ value: firstname + ' ' + middlename + ' ' + lastname, label: firstname + ' ' + middlename + ' ' + lastname })
            options.push({ value: lastname + ' ' + firstname.split('')[0] + ' ' + middleinitial + middlename.split('')[0], label: lastname + ' ' + firstname.split('')[0] + ' ' + middleinitial + middlename.split('')[0] })
            options.push({ value: firstname.split('')[0] + ' ' + middleinitial + middlename.split('')[0] + ' ' + lastname, label: firstname.split('')[0] + ' ' + middleinitial + middlename.split('')[0] + ' ' + lastname })
        }
        if (organisationname && organisationname !== '') {
            options.push({ value: organisationname, label: organisationname })
        }

        return options
    }
    const printOptions = () => {
        let arrayOfOptions = []
        if (props.id == "nameOnPanCard") {
            var namejson = {
                firstName: props.formik.values["firstName"],
                lastName: props.formik.values["lastName"],
                middleName: props.formik.values["middleName"],
                organisationName: props.formik.values['nonIndividualName']
            }
            var options = generateNameOnPan(namejson);
            arrayOfOptions.push(
                <MenuItem value="">Fill the Last Name</MenuItem>
            )
            for (let field of options) {
                arrayOfOptions.push(
                    <MenuItem value={field.value}>{field.label}</MenuItem>
                )
            }
            return arrayOfOptions
        }

        //sort options by position
        props.details.options = Object.values(props.details.options).sort((a, b) => a.position - b.position);

        arrayOfOptions.push(
            <MenuItem value="">Select</MenuItem>
        )
        for (let fields in props.details.options) {
            const field = props.details.options[fields];
            arrayOfOptions.push(
                <MenuItem value={field.value}>{field.label}</MenuItem>
            )
        }
        return arrayOfOptions
    }
    return (
        <div class="dropdown">
            <InputLabel className={classes.dropdownTitle}>{props.details.label}</InputLabel>
            <Select
                labelId={props.details.label}
                displayEmpty
                id={props.id}
                name={props.id}
                value={props.value || ""}
                onChange={(event) => { props.onChange(event); }}
                onBlur={(event) => props.onBlur(event)}
                className={classes.selectControl}
                error={(props.formik.errors[props.id] && props.formik.touched[props.id])}
            >
                {printOptions()}
            </Select>
        </div>
    )
};
export default Dropdown;