const fathersArray = ['fathersLastName', 'fathersFirstName', 'fathersMiddleName']
const mothersArray = ['mothersLastName', 'mothersFirstName', 'mothersMiddleName']
const nickNameArray = ['nickLastName', 'nickFirstName', 'nickMiddleName']
const representativeNameArray = ["representativeTitleOnName", "representativeLastName", "representativeFirstName", "representativeMiddleName"]
const representativeAddressArray = ["representativeAddressFlat", "representativeAddressRoad", "representativeAddressArea", "representativeAddressVillage", "representativeAddressPincode", "representativeAddressCity", "representativeAddressState", "representativeAddressCountry"]
const officeAddressArray = ["officeAddressBuilding", "officeAddressFlat", "officeAddressVillage", "officeAddressRoad", "officeAddressArea", "officeAddressPincode", "officeAddressCity", "officeAddressState", "officeAddressCountry"]

export const removeReprintNotRequired = (formValues) => {

    if (formValues.parentsName === "fathersName") {
        mothersArray.map((motherName) => {
            formValues[motherName] = ""
        })
    }

    if (formValues.parentsName === "mothersName" && formValues.singleParent === "yes") {
        fathersArray.map((fatherName) => {
            formValues[fatherName] = ""
        })
    }

    if (formValues.isNickName === false) {
        nickNameArray.map((nickName) => {
            formValues[nickName] = ""
        })
    }

    if (formValues.isRepresentativeAssessee === false) {
        representativeNameArray.map((repName) => {
            formValues[repName] = ""
        })

        representativeAddressArray.map((repAddress) => {
            formValues[repAddress] = ""
        })
    }

    if(formValues.aadhaarCard == "no"){
        formValues.aadhaarNumber = ""
    }

    return formValues;
}