import * as Yup from 'yup';

const Schema = Yup.object().shape({
    incomeSource: Yup.string().nullable().required("Please share your source of income"),
    businesstype: Yup.string().nullable().when('incomeSource',
        {
            is: (val) => val === 'business/profession',
            then: Yup.string().required("Select a valid business or profession")
        }
    ),
    individualProofIdentity: Yup.string().required("Please select ID Proof document type").nullable(),
    individualProofResidence: Yup.string().required("Please select address proof document type").nullable(),
    individualProofOffice: Yup.string().nullable().when('chooseAddress',
        {
            is: (val) => val === 'office',
            then: Yup.string().required("Please select Office address proof document type").nullable(),
        }
    ),
    individualProofDob: Yup.string().required("Please select Date of Birth proof").nullable(),
});

export default Schema;